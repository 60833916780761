import React, {Fragment, PureComponent} from 'react';
import {Modal, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import OtherAmountEntryPopup from "./OtherAmountEntryPopup";

const FormItem = Form.Item;
const {Option} = Select;

class ReportDataUpdate extends PureComponent {
  state = {
    loading: false,
    loadingReset: false,
    loadingRefresh: false,
    head: {},
  };

  componentDidMount() {
    this.initSearch();
  };

  hasFunctionInvoice(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN)){
      return true;
    }
    return false;
  }

  hasFunctionReset(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  refreshReportDataStatus = e => {
    this.initSearch();
  }

  resetReportDataStatus = e => {
    e.preventDefault();
    var head = this.state.head;
    const params = {
      loading: true,
      apiUrl: "/api/reportDataUpdate/reset",
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        x1.showMessage('报表状态复位成功');
        this.initSearch();
      },
      complete: () => {
        this.setState({loadingReset: false});
      }
    };
    this.setState({loadingReset: true});
    x1.request(params);
  };

  updateReportData = e => {
    e.preventDefault();
    var head = this.state.head;
    const params = {
      loading: true,
      apiUrl: "/api/reportDataUpdate/update",
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        x1.showMessage('报表数据刷新成功');
        this.initSearch();
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  initSearch() {
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const params = {
      apiUrl: "/api/reportDataUpdate/list",
      success: (data) => {
        if(data.length > 0){
        this.setState({
          head: data[0],
        });
        }
      },
      complete: ()=>{
        this.setState({loadingRefresh: false});
      }
    };
    this.setState({loadingRefresh: true});
    x1.request(params);
  };

  getStatus(){
    if(this.state.head.rpt_data_beg_ts_txt > '' && x1.isNull(this.state.head.rpt_data_upd_ts_txt)){
      return '刷新中';
    }
    return '完成';
  }

  render() {

    var styleItem = {
      width:'300px',
      height: '40px',
      display: 'flex',
    };
    var styleItemTitle = {
      width:'110px',
    };
    var styleItemValue = {
      flex: 1,
    };

    var head = this.state.head;

    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'flex-start', marginBottom: '16px'}}>
            <div style={{width:'350px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'更新状态：'}</div>
                <div style={styleItemValue}>{this.getStatus()}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'更新开始时间：'}</div>
                <div style={styleItemValue}>{head.rpt_data_beg_ts_txt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'更新结束时间：'}</div>
                <div style={styleItemValue}>{head.rpt_data_upd_ts_txt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'更新人：'}</div>
                <div style={styleItemValue}>{head.rpt_data_upd_usr_nm}</div>
              </div>
            </div>
            {this.hasFunctionInvoice() &&
              <div><Button type="primary" onClick={this.updateReportData} loading={this.state.loading} disabled={this.getStatus()=='刷新中'}>刷新报表数据</Button></div>
            }
            {this.hasFunctionInvoice() &&
              <div style={{marginLeft:'8px'}}><Button type="primary" onClick={this.refreshReportDataStatus} loading={this.state.loadingRefresh}>获取最新状态</Button></div>
            }
            {this.hasFunctionReset() &&
            <div style={{marginLeft:'8px'}}><Button type="primary" onClick={this.resetReportDataStatus} loading={this.state.loadingReset}>状态复位</Button></div>
            }
          </Form>
        </Card>
      </BasicLayout>

    );
  };
}

ReportDataUpdate = Form.create({})(ReportDataUpdate);
export default ReportDataUpdate;
