import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class XxCarDetail extends PureComponent {
  state = {
    loading: false,
    listUsrRole: [],
      xx_car_pk: '',
  };

  componentDidMount() {
    const {form} = this.props;

    var xx_car_pk = x1.router.getParamIn().xx_car_pk;
    if (xx_car_pk > '') {
      this.setState({xx_car_pk: xx_car_pk});
      form.setFieldsValue({xx_car_pk: xx_car_pk});
      this.search(xx_car_pk);
    } else {
    }
  };

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      const params = {
        apiUrl: this.state.xx_car_pk > '' ? "/api/xxCar/update" : "/api/xxCar/insert" ,
        ...fieldsValue,
          xx_car_pk: this.state.xx_car_pk,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(this.state.xx_car_pk == ''){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
      };
      x1.request(params);
    });
  };

  search(xx_car_pk) {
    const {form} = this.props;
    const params = {
      apiUrl: "/api/xxCar/list",
      curPage: 0,
      pageSize: 1,
      xx_car_pk: xx_car_pk,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };


  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 3},
      wrapperCol: {span: 8},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 3},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>
                <FormItem {...formItemLayout} label="车辆类型">
                    {form.getFieldDecorator('car_tp_nm', {
                        rules: [{
                            required: true,
                            message: '请输入车辆类型',
                        }]
                    })(
                        <Input style={{width: '150px'}}/>
                    )}
                </FormItem>
              <FormItem {...formItemLayout} label="车牌号码">
                {form.getFieldDecorator('car_plate_num', {
                  rules: [{
                    required: true,
                    message: '请输入车牌号码',
                  }]
                })(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
                <FormItem {...formItemLayout} label="载重">
                    {form.getFieldDecorator('car_tnng_amt', {
                        rules: [{
                            required: true,
                            message: '请输入载重',
                        }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                </FormItem>


              <FormItem {...submitItemLayout}>
                <Button type="primary" onClick={this.handleSubmit}>提交</Button>
              </FormItem>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

XxCarDetail = Form.create({})(XxCarDetail);
export default XxCarDetail;
