import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class WarehouseList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {wh_nm} = state;
        form.setFieldsValue({wh_nm});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/warehouse/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      get_prc_list_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/WarehouseDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/WarehouseDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/warehouse/delete",
      wh_cd: record.wh_cd,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  openPriceRange(record) {
    x1.router.push('/WarehousePriceRangeDetail', record);
  };

  openWarehousePrice(record) {
    x1.router.push('/WarehousePriceList', record, x1.getStateSnapshort(this));
  };

  openWarehouseMarketEntryPrice(record) {
    x1.router.push('/WarehouseMarketEntryPriceList', record, x1.getStateSnapshort(this));
  };

  columns = [
    {title: '仓库编号', dataIndex: 'wh_cd'},
    {title: '仓库名称', dataIndex: 'wh_nm'},
    {title: '联系人', dataIndex: 'ctac_psn_nm'},
    {title: '电话', dataIndex: 'ctac_tel_num'},
    {title: '地址', dataIndex: 'wh_addr'},
    {title: '预冷费', dataIndex: 'pre_cool_unit_prc_amt'},
    {title: '冷藏费', dataIndex: 'store_unit_prc_amt'},
    {title: '装卸费', dataIndex: 'load_unit_prc_amt'},
    {title: '工本费', dataIndex: 'card_unit_prc_amt'},
    {title: '最新单价',
      render: (text, record) => (
        <div>
          {record.unitPrc.wh_prc_pk > '' &&
          <div>
            <div>{x1.date2Text(record.unitPrc.from_dt) + ' ~ ' + x1.date2Text(record.unitPrc.to_dt)}</div>
            <div>{'预冷费：' + record.unitPrc.pre_cool_unit_prc_amt}</div>
            <div>{'冷藏费：' + record.unitPrc.store_unit_prc_amt}</div>
            <div>{'装卸费：' + record.unitPrc.load_unit_prc_amt}</div>
            <div>{'工本费：' + record.unitPrc.card_unit_prc_amt}</div>
            <div>
              <div>{'时间带搬运费：'}</div>
              {record.unitPrc.listPrc.map((prc)=>
                <div>{x1.time2Text(prc.from_tm) + " - " + x1.time2Text(prc.to_tm) + '：' + prc.load_unit_prc_amt + '元'}</div>
              )}
            </div>
          </div>
          }
          <div>
            <a onClick={() => this.openWarehousePrice(record)} style={{marginRight: '10px'}}>更新价格</a>
          </div>
        </div>
      ),
    },
    {title: '最新进场费',
      render: (text, record) => (
        <div>
          {record.mktEntryPrc.wh_mkt_entry_prc_pk > '' &&
          <div>
            <div>{x1.date2Text(record.mktEntryPrc.from_dt) + ' ~ ' + x1.date2Text(record.mktEntryPrc.to_dt)}</div>
            <div>{'进场费/吨：' + record.mktEntryPrc.entry_mkt_amt}</div>
          </div>
          }
          <div>
            <a onClick={() => this.openWarehouseMarketEntryPrice(record)} style={{marginRight: '10px'}}>更新进场费</a>
          </div>
        </div>
      ),
    },
    {title: '时间带价格(准备废弃)', render: (text, record) => (
        <div>
          {record.listPrc.map((prc)=>
            <div>{x1.time2Text(prc.from_tm) + " - " + x1.time2Text(prc.to_tm) + '：' + prc.load_unit_prc_amt + '元'}</div>
          )}
          <div>
            <a onClick={() => this.openPriceRange(record)} style={{marginRight: '10px'}}>时间区间运费</a>
          </div>
        </div>
      ),
    },
    {title: '面积', dataIndex: 'wh_area_amt'},
    {title: '总投入', dataIndex: 'invst_amt'},
    {title: '仓库状态', dataIndex: 'wh_sts_nm'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <div>
            <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
            <Divider type={'vertical'} />
            {this.hasFunctionDelete() &&
            <Popconfirm title={"确定要删除仓库【" + record.wh_nm + "】"} onConfirm={() => this.deleteLine(record)}>
              <Divider type={'vertical'} />
              <a style={{marginRight: '10px'}}>删除</a>
            </Popconfirm>
            }
          </div>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="仓库名称" allowClear={true}>
              {form.getFieldDecorator('wh_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
          </Form>
          <Table
            rowKey={'wh_cd'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

WarehouseList = Form.create({})(WarehouseList);
export default WarehouseList;
