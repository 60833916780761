import React, {Fragment, PureComponent} from 'react';
import {Modal, Checkbox, ConfigProvider, Table, Popconfirm, DatePicker, Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class TemporaryEmployeeAssignment extends PureComponent {
  state = {
    loading: false,
    loadingSubmit: false,
    loadingConfirm: false,
    loadingReject: false,
    loadingPrint: false,
    loadingTime: false,

    pagination: {},
    listAsg: [],
    listAsgLeft: [],
    listAsgRight: [],
    listWh: [],

    checkedItem: false,
    checkedPmt: false,

    isShowPopupPrice: false,
  };

  componentDidMount() {
    const {form} = this.props;
    const temp_emp_asg_dt = x1.getSystemDate();
    form.setFieldsValue({temp_emp_asg_dt: temp_emp_asg_dt});
    this.initSearch();
    this.initWarehouseList();
  };

  hasFunctionUpdateBaseHead(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.MGR) || x1.user.hasRole(x1.ROLE.ORD)){
      return true;
    }
    return false;
  }

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSave = e => {
    this.submit();
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  initSearch(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    var that = this;
    const {form} = this.props;
    form.setFieldsValue({
      listAsgLeft: [],
      listAsgRight: [],
    });
    this.setState({
      listAsg: [],
      listAsgLeft: [],
      listAsgRight: [],
    })
    var values = form.getFieldsValue();
    const params = {
      apiUrl: "/api/tempEmpAsg/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      temp_emp_sts_cd: 'AC',  // 登录画面只显示可用的员工
      success: (data) => {
        console.log(data)
        this.isAllNull(data);
        this.listFlag2boolean(data);
        this.setDefaultWarehouse(data);
        this.splitListAsg(data);
        this.setState({
          listAsg: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  setDefaultWarehouse(list){
    for(var i=0; i<list.length; i++){
      var item = list[i];
      if(x1.isNull(item.wh_cd)){
        item.wh_cd = 'W1';
        item.wh_nm = '河西冷库';
      }
    }
  }

  isAllNull(list){
    var flag = true;//全部为null则勾选早中
    for(var i=0; i<list.length; i++){
      if(list[i].dt_am_flg > '' || list[i].dt_pm_flg > '' || list[i].dt_ng_flg > ''){
        flag = false;
      }
    }
    if(flag){
      for(var i=0; i<list.length; i++){
        list[i].dt_am_flg = 'Y';
        list[i].dt_pm_flg = 'Y';
      }
    }
  }

  splitListAsg(data){
    var length = data.length;
    if(length >= 20){
      this.setState({
        listAsgLeft: data.slice(0, 10),
        listAsgRight: data.slice(11, length)
      });
    }else{
      var halfLength = parseInt(length/2);
      this.setState({
        listAsgLeft: data.slice(0,halfLength),
        listAsgRight: data.slice(halfLength,length)
      })
    }
  }

  listFlag2boolean(list){
    for(var i=0 ;i<list.length; i++){
      list[i].dt_am_bln = x1.flag2boolean(list[i].dt_am_flg);
      list[i].dt_pm_bln = x1.flag2boolean(list[i].dt_pm_flg);
      list[i].dt_ng_bln = x1.flag2boolean(list[i].dt_ng_flg);
    }
  }

  listBoolean2flag(list){
    for(var i=0 ;i<list.length; i++){
      var item = list[i];
      item.dt_am_flg = x1.boolean2flag(item.dt_am_bln);
      item.dt_pm_flg = x1.boolean2flag(item.dt_pm_bln);
      item.dt_ng_flg = x1.boolean2flag(item.dt_ng_bln);
    }
  }

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  handleRefresh = e => {

  };

  onChangedCheckDateTimeLeft(record, index, nmItem){
    record[nmItem]= !record[nmItem];
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    var item = fieldsValue.listAsgLeft[index];
    item[nmItem] = !item[nmItem];
    form.setFieldsValue(fieldsValue);
  }

  onChangedCheckDateTimeRight(record, index, nmItem){
    record[nmItem]= !record[nmItem];
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    var item = fieldsValue.listAsgRight[index];
    item[nmItem] = !item[nmItem];
    form.setFieldsValue(fieldsValue);
  }

  submit() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      if(!this.checkSubmit()){
        return;
      }
      console.log(fieldsValue)
      fieldsValue.listAsg = fieldsValue.listAsgLeft.concat(fieldsValue.listAsgRight);
      this.listBoolean2flag(fieldsValue.listAsg);
      const params = {
        apiUrl: "/api/tempEmpAsg/update",
        listAsg: x1.mergeDeep(this.state.listAsg, fieldsValue.listAsg),
        upd_usr_id: x1.user.getCurrentUserId(),
        usr_id: x1.user.getCurrentUserId(),
        success: (data) => {
          x1.showMessage('提交成功');
          this.refreshPage();
        },
        complete:()=>{
          this.setState({
            loadingSubmit:false,
          });
        }
      };
      this.setState({
        loadingSubmit:true,
      });
      x1.request(params);
    });
  };

  render() {
    const {form} = this.props;

    const rowInputStyle = {
      marginBottom: '0'
    };

    var customizeRenderEmpty = () => {
          return <div>没有数据</div>
        };

    // ------------------------
    // 员工登记左表Left
    // ------------------------
    const columnsLeft = [
      {title: '日期', dataIndex: 'temp_emp_asg_dt',
        render: (text, record, index) => (
            <div>{x1.date2Text(record.temp_emp_asg_dt)}</div>
        )
      },
      {title: '姓名', dataIndex: 'temp_emp_nm',
      },
      {title: '工作仓库', dataIndex: 'wh_cd',
        render: (text, record, index) => (
            <FormItem style={rowInputStyle}>
              {
                form.getFieldDecorator(`listAsgLeft[${index}].wh_cd`, {initialValue: text,
                  rules: [{
                    required: true,
                    message: '请选择仓库',
                  }]
                })
                (
                    <Select style={{width: '100px'}} allowClear={true}>
                      {this.state.listWh.map((item) => (
                          <Option key={item.wh_cd}>{item.wh_nm}</Option>
                      ))}
                    </Select>
                )
              }
            </FormItem>
        ),
      },
      {title: '工作时间', dataIndex: '',
        render: (text, record, index) => {
          return (
              <div style={{display: 'flex', flexWrap: 'nowrap'}}>
                <FormItem style={rowInputStyle}>
                  {
                    form.getFieldDecorator(`listAsgLeft[${index}].dt_am_bln`, {initialValue: record.dt_am_bln})(
                        <Checkbox checked={record.dt_am_bln} onChange={(e)=>this.onChangedCheckDateTimeLeft(record, index, 'dt_am_bln')}>上午</Checkbox>
                    )
                  }
                </FormItem>
                <FormItem style={rowInputStyle}>
                  {
                    form.getFieldDecorator(`listAsgLeft[${index}].dt_pm_bln`, {initialValue: record.dt_pm_bln})(
                        <Checkbox checked={record.dt_pm_bln} onChange={(e)=>this.onChangedCheckDateTimeLeft(record, index, 'dt_pm_bln')}>下午</Checkbox>
                    )
                  }
                </FormItem>
                <FormItem style={rowInputStyle}>
                  {
                    form.getFieldDecorator(`listAsgLeft[${index}].dt_ng_bln`, {initialValue: record.dt_ng_bln})(
                        <Checkbox checked={record.dt_ng_bln} onChange={(e)=>this.onChangedCheckDateTimeLeft(record, index, 'dt_ng_bln')}>晚上</Checkbox>
                    )
                  }
                </FormItem>
              </div>
          );
        },
      },
    ];

    // ------------------------
    // 员工登记右表
    // ------------------------
    const columnsRight = [
      {title: '日期', dataIndex: 'temp_emp_asg_dt',
        render: (text, record, index) => (
            <div>{x1.date2Text(record.temp_emp_asg_dt)}</div>
        )
      },
      {title: '姓名', dataIndex: 'temp_emp_nm',
      },
      {title: '工作仓库', dataIndex: 'wh_cd',
        render: (text, record, index) => (
            <FormItem style={rowInputStyle}>
              {
                form.getFieldDecorator(`listAsgRight[${index}].wh_cd`, {initialValue: text,
                  rules: [{
                    required: true,
                    message: '请选择仓库',
                  }]
                })
                (
                    <Select style={{width: '100px'}} allowClear={true}>
                      {this.state.listWh.map((item) => (
                          <Option key={item.wh_cd}>{item.wh_nm}</Option>
                      ))}
                    </Select>
                )
              }
            </FormItem>
        ),
      },
      {title: '工作时间', dataIndex: '',
        render: (text, record, index) => {
          return (
              <div style={{display: 'flex', flexWrap: 'nowrap'}}>
                <FormItem style={rowInputStyle}>
                  {
                    form.getFieldDecorator(`listAsgRight[${index}].dt_am_bln`, {initialValue: record.dt_am_bln})(
                        <Checkbox checked={record.dt_am_bln} onChange={(e)=>this.onChangedCheckDateTimeRight(record, index, 'dt_am_bln')}>上午</Checkbox>
                    )
                  }
                </FormItem>
                <FormItem style={rowInputStyle}>
                  {
                    form.getFieldDecorator(`listAsgRight[${index}].dt_pm_bln`, {initialValue: record.dt_pm_bln})(
                        <Checkbox checked={record.dt_pm_bln} onChange={(e)=>this.onChangedCheckDateTimeRight(record, index, 'dt_pm_bln')}>下午</Checkbox>
                    )
                  }
                </FormItem>
                <FormItem style={rowInputStyle}>
                  {
                    form.getFieldDecorator(`listAsgRight[${index}].dt_ng_bln`, {initialValue: record.dt_ng_bln})(
                        <Checkbox checked={record.dt_ng_bln} onChange={(e)=>this.onChangedCheckDateTimeRight(record, index, 'dt_ng_bln')}>晚上</Checkbox>
                    )
                  }
                </FormItem>
              </div>
          );
        },
      },
    ];

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
        <BasicLayout>
          <Card bordered={false}>
            <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="日期">
                {form.getFieldDecorator('temp_emp_asg_dt', {
                })(
                    <DatePicker style={{width: '130px'}} allowClear={true}/>
                )}
              </FormItem>
              {/*<FormItem {...formItemLayout} label="姓名">*/}
                {/*{form.getFieldDecorator('temp_emp_nm')(*/}
                    {/*<Input style={{width: '150px'}}/>*/}
                {/*)}*/}
              {/*</FormItem>*/}
              {/*<FormItem {...formItemLayout} label="仓库">*/}
                {/*{form.getFieldDecorator('wh_nm')(*/}
                    {/*<Select style={{width: '100px'}} allowClear={true}>*/}
                      {/*{this.state.listWh.map((item) => (*/}
                          {/*<Option key={item.wh_cd}>{item.wh_nm}</Option>*/}
                      {/*))}*/}
                    {/*</Select>*/}
                {/*)}*/}
              {/*</FormItem>*/}
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              {/*<Button type="primary" onClick={this.handleRefresh} style={{marginLeft:'16px'}}>刷新员工</Button>*/}
            </Form>

            <Spin spinning={this.state.loading}>
              <Form hideRequiredMark={false}>

                <div>
                  <ConfigProvider renderEmpty={customizeRenderEmpty}>
                    <Row span='24'>
                      <Col span='12'>
                        <Table
                            columns={columnsLeft}
                            dataSource={this.state.listAsgLeft}
                            pagination={this.state.pagination}
                            size={'middle'}
                            onChange={this.handleStandardTableChange}
                        />
                      </Col>
                      <Col span='12'>
                        <Table
                            columns={columnsRight}
                            dataSource={this.state.listAsgRight}
                            pagination={this.state.pagination}
                            size={'middle'}
                            onChange={this.handleStandardTableChange}
                        />
                      </Col>
                    </Row>
                  </ConfigProvider>
                </div>
                <div>
                  <div style={{marginTop:'32px'}}>
                    <Fragment>
                      <Button type="primary" onClick={this.handleSave} loading={this.state.loadingSubmit}>保存</Button>
                    </Fragment>
                  </div>
                </div>
              </Form>
            </Spin>
          </Card>
        </BasicLayout>
    );
  };

}

TemporaryEmployeeAssignment = Form.create({})(TemporaryEmployeeAssignment);
export default TemporaryEmployeeAssignment;
