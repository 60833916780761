import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class EntryMarketFee extends PureComponent {
  state = {
    loading: false,
  };

  componentDidMount() {
    const {form} = this.props;

    var wh_cd = x1.router.getParamIn().wh_cd;
    form.setFieldsValue({wh_cd: wh_cd});
    this.search(wh_cd);
  };

  hasFunctionUpdate(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH) || x1.user.hasRole(x1.ROLE.GEN_MGR)){
      return true;
    }
    return false;
  }

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/warehouse/updateEntryMarketAmount",
        ...fieldsValue,
        success: (data) => {
          x1.showMessage('提交成功');
          x1.router.back();
        },
      };
      x1.request(params);
    });
  };

  search(wh_cd) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/warehouse/list",
      wh_cd: wh_cd,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="仓库编码">
                    {form.getFieldDecorator('wh_cd')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="仓库名称">
                    {form.getFieldDecorator('wh_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="进场费/吨">
                    {form.getFieldDecorator('entry_mkt_amt', {
                      rules: [{
                        required: true,
                        message: '请输入进场费',
                      }]
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  {this.hasFunctionUpdate() &&
                  <FormItem {...submitItemLayout}>
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                  </FormItem>
                  }
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

EntryMarketFee = Form.create({})(EntryMarketFee);
export default EntryMarketFee;
