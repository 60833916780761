import React, {Fragment, PureComponent} from 'react';
import {Modal, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import OtherAmountEntryPopup from "./OtherAmountEntryPopup";

const FormItem = Form.Item;
const {Option} = Select;

class StockInPrePaymentDetail extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    head: {},
    listItem: [],
  };

  componentDidMount() {
    var head = x1.router.getParamIn();
    this.state.head = head;
    this.setState({head: head});
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    var head = this.state.head;

    const params = {
      apiUrl: "/api/prePayment/listLine",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      ownr_id: head.ownr_id,
      stock_in_num: head.stock_in_num,
      stock_in_line_num: head.stock_in_line_num,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  columns = [
    {title: '结算日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.inv_to_dt)}</div>
      ),
    },
    {title: '结算金额', dataIndex: 'inv_amt'},
    {title: '收款日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.paid_dt)}</div>
      ),
    },
    {title: '收款金额', dataIndex: 'paid_amt'},
    {title: '更新人', dataIndex: 'crat_usr_nm'},
    {title: '更新时间', dataIndex: 'crat_ts'},
  ];

  render() {

    var styleItem = {
      width:'200px',
      height: '40px',
      display: 'flex',
    };
    var styleItemTitle = {
      width:'80px',
    };
    var styleItemValue = {
      flex: 1,
    };

    var head = this.state.head;

    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'flex-start', marginBottom: '16px'}}>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'仓库：'}</div>
                <div style={styleItemValue}>{head.wh_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'货主：'}</div>
                <div style={styleItemValue}>{head.ownr_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'商品：'}</div>
                <div style={styleItemValue}>{head.item_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库日期：'}</div>
                <div style={styleItemValue}>{x1.date2Text(head.stock_in_dt)}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'单位重量(kg)：'}</div>
                <div style={styleItemValue}>{head.unit_kg_amt}</div>
              </div>
            </div>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库数量：'}</div>
                <div style={styleItemValue}>{head.stock_in_qty}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库吨位：'}</div>
                <div style={styleItemValue}>{head.tot_in_tnng_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'出库数量：'}</div>
                <div style={styleItemValue}>{head.tot_out_qty}</div>
              </div>
            </div>
          </Form>
          <Table
            // rowKey={'stock_out_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>

        {this.state.isShowPopup &&
        <Modal visible={true} footer={null} onCancel={()=>this.handleCancelPopup()}>
          <OtherAmountEntryPopup inv={this.state.head} onSubmit={(input)=>this.onSubmitEditOtherAmount(input)}/>
        </Modal>
        }

      </BasicLayout>

    );
  };
}

StockInPrePaymentDetail = Form.create({})(StockInPrePaymentDetail);
export default StockInPrePaymentDetail;
