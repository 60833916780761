import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class ItemDetail extends PureComponent {
  state = {
    loading: false,
    listItemGrp: [],
    listProd: [],
    listItemPkg: [],
    listItemSpec: [],
    listItemUnit: [],
  };

  componentDidMount() {
    const {form} = this.props;

    var item_cd = x1.router.getParamIn().item_cd;
    if (item_cd > '') {
      // 更新
      form.setFieldsValue({item_cd: item_cd});
      this.search(item_cd);
    } else {
      // 新增
    }
    this.initItemGroupList();
    this.initProductList();
    this.initItemSpecificationList();
    this.initItemPackageList();
    this.initItemUnitList();
  };

  initItemGroupList() {
    const params = {
      apiUrl: "/api/itemGroup/list",
      success: (data) => {
        this.setState({
          listItemGrp: data,
        });
      },
    };
    x1.request(params);
  };

  initProductList() {
    const params = {
      apiUrl: "/api/product/list",
      item_grp_cd: this.state.item_grp_cd,
      success: (data) => {
        this.setState({
          listProd: data,
        });
      },
    };
    x1.request(params);
  };

  initItemSpecificationList() {
    const params = {
      apiUrl: "/api/itemSpecification/list",
      success: (data) => {
        this.setState({
          listItemSpec: data,
        });
      },
    };
    x1.request(params);
  };

  initItemPackageList() {
    const params = {
      apiUrl: "/api/itemPackage/list",
      success: (data) => {
        this.setState({
          listItemPkg: data,
        });
      },
    };
    x1.request(params);
  };

  initItemUnitList() {
    const {form} = this.props;
    const params = {
      apiUrl: "/api/itemUnit/list",
      success: (data) => {
        this.setState({
          listItemUnit: data,
        });
        // 新增时默认一个单位
        if(data.length > 0 && x1.isNull(this.state.item_cd)){
          form.setFieldsValue({
            item_unit_cd: data[0].item_unit_cd
          });
        }
      },
    };
    x1.request(params);
  };

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    
    // 商品名称没有输入的话，自动补上
    if(x1.isNull(fieldsValue.item_nm)){
      var prod_nm = this.getSelectedProductName(fieldsValue.prod_cd);
      var item_unit_nm = this.getSelectedItemUnitName(fieldsValue.item_unit_cd);
      var item_spec_nm = this.getSelectedItemUnitName(fieldsValue.item_spec_cd);
      var item_pkg_nm = this.getSelectedItemUnitName(fieldsValue.item_pkg_cd);

      var item_nm = x1.nvl(prod_nm,'') + x1.nvl(item_unit_nm,'') + x1.nvl(item_spec_nm,'') + x1.nvl(item_pkg_nm,'');
      form.setFieldsValue({
        item_nm: item_nm
      });
    }
    
    return true;
  }
  
  getSelectedProductName(prod_cd){
    for(var i=0; i<this.state.listProd.length; i++){
      var item = this.state.listProd[i];
      if(item.prod_cd == prod_cd){
        return item.prod_nm;
      }
    }  
    return null;
  }

  getSelectedItemPkgName(item_pkg_cd){
    for(var i=0; i<this.state.listItemPkg.length; i++){
      var item = this.state.listItemPkg[i];
      if(item.item_pkg_cd == item_pkg_cd){
        return item.item_pkg_nm;
      }
    }
    return null;
  }

  getSelectedItemSpecName(item_spec_cd){
    for(var i=0; i<this.state.listItemSpec.length; i++){
      var item = this.state.listItemSpec[i];
      if(item.item_spec_cd == item_spec_cd){
        return item.item_spec_nm;
      }
    }
    return null;
  }

  getSelectedItemUnitName(item_unit_cd){
    for(var i=0; i<this.state.listItemUnit.length; i++){
      var item = this.state.listItemUnit[i];
      if(item.item_unit_cd == item_unit_cd){
        return item.item_unit_nm;
      }
    }
    return null;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }
      fieldsValue = form.getFieldsValue();

      const params = {
        apiUrl: "/api/item/update",
        ...fieldsValue,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.item_cd)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
      };
      x1.request(params);
    });
  };

  search(item_cd) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/item/list",
      item_cd: item_cd,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  onChangeItemGroup(item_grp_cd){
    this.state.item_grp_cd = item_grp_cd;
    this.initProductList();
  }

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="商品编码">
                    {form.getFieldDecorator('item_cd')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="商品名称">
                    {form.getFieldDecorator('item_nm', {
                    })(
                      <Input style={{width: '200px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="商品分类">
                    {form.getFieldDecorator('item_grp_cd', {
                      rules: [{
                        required: true,
                        message: '请输入商品分类',
                      }]
                    })(
                      <Select style={{width: '200px'}} allowClear={true} onChange={(value)=>this.onChangeItemGroup(value)}>
                        {this.state.listItemGrp.map((item) => (
                          <Option key={item.item_grp_cd}>{item.item_grp_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="品类">
                    {form.getFieldDecorator('prod_cd', {
                      rules: [{
                        required: true,
                        message: '请输入品类',
                      }]
                    })(
                      <Select style={{width: '200px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {this.state.listProd.map((item) => (
                          <Option key={item.prod_cd}>{item.prod_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="详情">
                    {form.getFieldDecorator('item_pkg_cd')(
                      <Select style={{width: '200px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {this.state.listItemPkg.map((item) => (
                          <Option key={item.item_pkg_cd}>{item.item_pkg_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="型号">
                    {form.getFieldDecorator('item_spec_cd')(
                      <Select style={{width: '200px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {this.state.listItemSpec.map((item) => (
                          <Option key={item.item_spec_cd}>{item.item_spec_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="单位">
                    {form.getFieldDecorator('item_unit_cd', {
                      rules: [{
                        required: true,
                        message: '请输入单位',
                      }]
                    })(
                      <Select style={{width: '200px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {this.state.listItemUnit.map((item) => (
                          <Option key={item.item_unit_cd}>{item.item_unit_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="商品状态">
                    {form.getFieldDecorator('item_sts_cd', {
                    })(
                      <Radio.Group buttonStyle="solid" disabled={true}>
                        <Radio.Button value="AC">可用</Radio.Button>
                        <Radio.Button value="IN">不可用</Radio.Button>
                      </Radio.Group>
                    )}
                  </FormItem>

                  <FormItem {...submitItemLayout}>
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                  </FormItem>
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

ItemDetail = Form.create({})(ItemDetail);
export default ItemDetail;
