import React, {Component} from 'react';
import './App.css';
import {x1} from "./x1"
import UserList from "./pages/UserList";
import UserDetail from "./pages/UserDetail";
import Login from "./pages/Login";
import UserProfile from "./pages/UserProfile";
import Blank from "./pages/Blank";
import ItemList from "./pages/ItemList";
import ItemDetail from "./pages/ItemDetail";
import ItemUnitList from "./pages/ItemUnitList";
import ItemUnitDetail from "./pages/ItemUnitDetail";
import ItemGroupList from "./pages/ItemGroupList";
import ItemGroupDetail from "./pages/ItemGroupDetail";
import ProductList from "./pages/ProductList";
import ProductDetail from "./pages/ProductDetail";
import ItemPackageList from "./pages/ItemPackageList";
import ItemPackageDetail from "./pages/ItemPackageDetail";
import ItemSpecificationList from "./pages/ItemSpecificationList";
import ItemSpecificationDetail from "./pages/ItemSpecificationDetail";
import WarehouseList from "./pages/WarehouseList";
import WarehouseDetail from "./pages/WarehouseDetail";
import WarehouseLocationList from "./pages/WarehouseLocationList";
import WarehouseLocationDetail from "./pages/WarehouseLocationDetail";
import OrganizationDetail from "./pages/OrganizationDetail";
import OwnerList from "./pages/OwnerList";
import OwnerDetail from "./pages/OwnerDetail";
import ShipFeeConfigList from "./pages/ShipFeeConfigList";
import ShipFeeConfigDetail from "./pages/ShipFeeConfigDetail";
import StockInDetail from "./pages/StockInDetail";
import StockInList from "./pages/StockInList";
import InventoryList from "./pages/InventoryList";
import StockOutList from "./pages/StockOutList";
import StockOutDetail from "./pages/StockOutDetail";
import InventoryListByStockIn from "./pages/InventoryListByStockIn";
import InventoryListByStockInDetail from "./pages/InventoryListByStockInDetail";
import InvoiceWaitList from "./pages/InvoiceWaitList";
import InvoiceWaitDetail from "./pages/InvoiceWaitDetail";
import InvoiceDetail from "./pages/InvoiceDetail";
import InvoiceList from "./pages/InvoiceList";
import TransferList from "./pages/TransferList";
import TransferDetail from "./pages/TransferDetail";
import InitDataUpload from "./pages/InitDataUpload";
import RentOrderList from "./pages/RentOrderList";
import RentOrderDetail from "./pages/RentOrderDetail";
import ReportByOwnerList from "./pages/ReportByOwnerList";
import ReportByOwnerStockInList from "./pages/ReportByOwnerStockInList";
import MonthExpenseList from "./pages/MonthExpenseList";
import MonthExpenseDetail from "./pages/MonthExpenseDetail";
import ReportInventoryByProduct from "./pages/ReportInventoryByProduct";
import ReportDataUpdate from "./pages/ReportDataUpdate";
import StockInOtherExpenseList from "./pages/StockInOtherExpenseList";
import RentMonthChargeList from "./pages/RentMonthChargeList";
import RentMonthChargeDetail from "./pages/RentMonthChargeDetail";
import ReportRentOrderByLocation from "./pages/ReportRentOrderByLocation";
import ReportInventoryByOwner from "./pages/ReportInventoryByOwner";
import ReportInventoryByLocation from "./pages/ReportInventoryByLocation";
import ReportFinance from "./pages/ReportFinance";
import RentOrderInvoiceList from "./pages/RentOrderInvoiceList";
import WarehouseRoomList from "./pages/WarehouseRoomList";
import WarehouseRoomDetail from "./pages/WarehouseRoomDetail";
import ProductPriceList from "./pages/ProductPriceList";
import OwnerPriceList from "./pages/OwnerPriceList";
import ProductPriceDetail from "./pages/ProductPriceDetail";
import ProductPriceRangeDetail from "./pages/ProductPriceRangeDetail";
import OwnerPriceDetail from "./pages/OwnerPriceDetail";
import OwnerPriceRangeDetail from "./pages/OwnerPriceRangeDetail";
import OwnerProductList from "./pages/OwnerProductList";
import UserDataSecurityList from "./pages/UserDataSecurityList";
import UserDataSecurityDetail from "./pages/UserDataSecurityDetail";
import OwnerDepositList from "./pages/OwnerDepositList";
import OwnerDepositDetail from "./pages/OwnerDepositDetail";
import WarehousePriceRangeDetail from "./pages/WarehousePriceRangeDetail";
import WarehousePriceList from "./pages/WarehousePriceList";
import WarehousePriceDetail from "./pages/WarehousePriceDetail";
import WarehousePriceRangeXDetail from "./pages/WarehousePriceRangeXDetail";
import ReportInventoryByDate from "./pages/ReportInventoryByDate";
import StockInPrePaymentDetail from "./pages/StockInPrePaymentDetail";
import OwnerPrePaymentList from "./pages/OwnerPrePaymentList";
import OwnerPrePaymentDetail from "./pages/OwnerPrePaymentDetail";
import EntryMarketFee from "./pages/EntryMarketFee";
import WarehouseMarketEntryPriceList from "./pages/WarehouseMarketEntryPriceList";
import WarehouseMarketEntryPriceDetail from "./pages/WarehouseMarketEntryPriceDetail";
import TemporaryJobList from "./pages/TemporaryJobList";
import TemporaryJobDetail from "./pages/TemporaryJobDetail";
import TemporaryEmployeeAssignment from "./pages/TemporaryEmployeeAssignment";
import ReportWorkStevedore from "./pages/ReportWorkStevedore";
import ReportWorkDetail from "./pages/ReportWorkDetail";
import TempEmployee from "./pages/TemporaryEmployee";
import TempEmployeeDetail from "./pages/TemporaryEmployeeDetail";
import SummaryStockOutInvoiceByOwner from "./pages/SummaryStockOutInvoiceByOwner";
import SummaryMonthInvoiceByOwner from "./pages/SummaryMonthInvoiceByOwner";
import SummaryAllInvoiceByOwner from "./pages/SummaryAllInvoiceByOwner";
import ManagerOvertimeList from "./pages/ManagerOvertimeList";
import ManagerOvertimeDetail from "./pages/ManagerOvertimeDetail";
import TemporaryEmployeeAssignListByDate from "./pages/TemporaryEmployeeAssignListByDate";
import TemporaryEmployeeAssignByDate from "./pages/TemporaryEmployeeAssignByDate";
import ReportManagerOvertime from "./pages/ReportManagerOvertime";
import ReportManagerOvertimeDetail from "./pages/ReportManagerOvertimeDetail";
import RentOwnerList from "./pages/RentOwnerList";
import RentOwnerDetail from "./pages/RentOwnerDetail";
import RentWarehouseLocationList from "./pages/RentWarehouseLocationList";
import RentWarehouseLocationDetail from "./pages/RentWarehouseLocationDetail";
import TemporaryEmployeeAssignListByEmployee from "./pages/TemporaryEmployeeAssignListByEmployee";
import TemporaryEmployeeAssignByEmployee from "./pages/TemporaryEmployeeAssignByEmployee";
import XxTransactionList from "./pages/XxTransactionList";

import XxStockInfoList from "./pages/XxStockInfoList";
import XxStockInfoDetail from "./pages/XxStockInfoDetail";
import XxPalletUsageList from "./pages/XxPalletUsageList";
import XxPalletUsageDetail from "./pages/XxPalletUsageDetail";
import XxCarList from './pages/XxCarList';
import XxCarDetail from './pages/XxCarDetail';
import XxInventoryTurnRateList from './pages/XxInventoryTurnRateList';
import XxInventoryTurnRateDetail from './pages/XxInventoryTurnRateDetail';
import StockInOutOvertimeList from "./pages/StockInOutOvertimeList";


class App extends Component {

  // 'menuId'的格式为'菜单组名,菜单项名'
  INIT_PAGES = ["/InventoryListByStockIn"];
  pages = [
    {path: "/InitDataUpload",  component:InitDataUpload, name: "账单数据上传", menuId:'初始化.账单数据上传', roles:[x1.ROLE.ADMIN]},

    {path: "/UserList",  component:UserList, name: "用户", menuId:'组织.用户', roles:[x1.ROLE.ADMIN]},
    {path: "/UserDetail",  component:UserDetail, name: "用户详情"},
    {path: "/UserProfile",  component:UserProfile, name: "用户信息"},
    {path: "/UserDataSecurityList",  component:UserDataSecurityList, name: "仓库数据权限"},
    {path: "/UserDataSecurityDetail",  component:UserDataSecurityDetail, name: "仓库数据权限详情"},
    {path: "/TemporaryEmployee",  component:TempEmployee, name: "员工", menuId:'组织.员工', roles:[x1.ROLE.ADMIN, x1.ROLE.ORD]},
    {path: "/TemporaryEmployeeDetail",  component:TempEmployeeDetail, name: "员工详情"},
    {path: "/OrganizationDetail",  component:OrganizationDetail, name: "组织", menuId:'组织.组织', roles:[x1.ROLE.ADMIN]},
    {path: "/TemporaryEmployeeAssignment",  component:TemporaryEmployeeAssignment, name: "上班登记", menuId:'组织.上班登记', roles:[x1.ROLE.ADMIN, x1.ROLE.MGR, x1.ROLE.ORD]},
    {path: "/TemporaryEmployeeAssignListByDate",  component:TemporaryEmployeeAssignListByDate, name: "上班历史-按天", menuId:'组织.上班历史-按天', roles:[x1.ROLE.ADMIN, x1.ROLE.MGR, x1.ROLE.ORD, x1.ROLE.FIN]},
    {path: "/TemporaryEmployeeAssignByDate",  component:TemporaryEmployeeAssignByDate, name: "上班登记历史详情"},
    {path: "/TemporaryEmployeeAssignListByEmployee",  component:TemporaryEmployeeAssignListByEmployee, name: "上班历史-按人", menuId:'组织.上班历史-按人', roles:[x1.ROLE.ADMIN, x1.ROLE.MGR, x1.ROLE.ORD, x1.ROLE.FIN]},
    {path: "/TemporaryEmployeeAssignByEmployee",  component:TemporaryEmployeeAssignByEmployee, name: "上班登记历史详情"},

    {path: "/ItemList",  component:ItemList, name: "商品", menuId:'商品.商品', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.CASH, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/ItemDetail",  component:ItemDetail, name: "商品详情"},
    {path: "/ItemGroupList",  component:ItemGroupList, name: "商品分组", menuId:'商品.商品分类', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.CASH, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/ItemGroupDetail",  component:ItemGroupDetail, name: "商品分类详情"},
    {path: "/ProductList",  component:ProductList, name: "商品品类", menuId:'商品.商品品类', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.CASH, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/ProductDetail",  component:ProductDetail, name: "商品品类详情"},
    {path: "/ItemUnitList",  component:ItemUnitList, name: "商品单位", menuId:'商品.商品单位', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.CASH, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/ItemUnitDetail",  component:ItemUnitDetail, name: "商品单位详情"},
    {path: "/ItemPackageList",  component:ItemPackageList, name: "商品详细品类", menuId:'商品.商品详细品类', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.CASH, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/ItemPackageDetail",  component:ItemPackageDetail, name: "商品详细品类详情"},
    {path: "/ItemSpecificationList",  component:ItemSpecificationList, name: "商品型号", menuId:'商品.商品型号', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.CASH, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/ItemSpecificationDetail",  component:ItemSpecificationDetail, name: "商品型号详情"},

    {path: "/WarehouseList",  component:WarehouseList, name: "仓库", menuId:'仓库.仓库', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/WarehouseDetail",  component:WarehouseDetail, name: "仓库详情"},
    {path: "/WarehousePriceRangeDetail",  component:WarehousePriceRangeDetail, name: "仓库详情"},
    {path: "/WarehouseRoomList",  component:WarehouseRoomList, name: "库房", menuId:'仓库.库房', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/WarehouseRoomDetail",  component:WarehouseRoomDetail, name: "库房详情"},
    {path: "/WarehouseLocationList",  component:WarehouseLocationList, name: "库位", menuId:'仓库.库位', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/WarehouseLocationDetail",  component:WarehouseLocationDetail, name: "库位详情"},
    // {path: "/ShipFeeConfigList",  component:ShipFeeConfigList, name: "配送费", menuId:'仓库.配送费', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.GEN_MGR]},
    // {path: "/ShipFeeConfigDetail",  component:ShipFeeConfigDetail, name: "配送费详情"},

    {path: "/OwnerList",  component:OwnerList, name: "货主", menuId:'货主.货主', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.CASH, x1.ROLE.GEN_MGR]},
    {path: "/OwnerDetail",  component:OwnerDetail, name: "货主详情"},
    {path: "/OwnerDepositList",  component:OwnerDepositList, name: "押金", menuId:'货主.押金', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.CASH, x1.ROLE.GEN_MGR, x1.ROLE.ORD]},
    {path: "/OwnerDepositDetail",  component:OwnerDepositDetail, name: "押金详情"},
    {path: "/OwnerPrePaymentList",  component:OwnerPrePaymentList, name: "预收款", menuId:'货主.预收款', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.CASH, x1.ROLE.GEN_MGR, x1.ROLE.ORD]},
    {path: "/OwnerPrePaymentDetail",  component:OwnerPrePaymentDetail, name: "预收款详情"},
    {path: "/ReportByOwnerList",  component:ReportByOwnerList, name: "货主余额", menuId:'货主.货主余额', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.ORD, x1.ROLE.MGR, x1.ROLE.GEN_MGR]},
    {path: "/StockInPrePaymentDetail",  component:StockInPrePaymentDetail, name: "预付款详情"},

    {path: "/PriceWarehouseList",  component:WarehouseList, name: "仓库", menuId:'价格.仓库', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.CASH, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/WarehousePriceList",  component:WarehousePriceList, name: "仓库价格"},
    {path: "/WarehousePriceDetail",  component:WarehousePriceDetail, name: "仓库价格详情"},
    {path: "/WarehousePriceRangeXDetail",  component:WarehousePriceRangeXDetail, name: "仓库时间区间价格"},

    {path: "/PriceProductList",  component:ProductList, name: "品类", menuId:'价格.品类', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.CASH, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/ProductPriceList",  component:ProductPriceList, name: "品类价格"},
    {path: "/ProductPriceDetail",  component:ProductPriceDetail, name: "品类价格详情"},
    {path: "/ProductPriceRangeDetail",  component:ProductPriceRangeDetail, name: "品类时间区间价格"},

    {path: "/OwnerProductList",  component:OwnerProductList, name: "货主品类", menuId:'价格.货主品类', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.CASH, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/OwnerPriceList",  component:OwnerPriceList, name: "货主价格"},
    {path: "/OwnerPriceDetail",  component:OwnerPriceDetail, name: "货主价格详情"},
    {path: "/OwnerPriceRangeDetail",  component:OwnerPriceRangeDetail, name: "货主时间区间价格"},
    {path: "/ShipFeeConfigList",  component:ShipFeeConfigList, name: "配送费", menuId:'价格.配送费', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.GEN_MGR]},
    {path: "/ShipFeeConfigDetail",  component:ShipFeeConfigDetail, name: "配送费详情"},
    {path: "/EntryMarketFee",  component:EntryMarketFee, name: "更新进场费"},
    {path: "/WarehouseMarketEntryPriceList",  component:WarehouseMarketEntryPriceList, name: "进场费"},
    {path: "/WarehouseMarketEntryPriceDetail",  component:WarehouseMarketEntryPriceDetail, name: "进场费详情"},

    {path: "/StockInList",  component:StockInList, name: "入库", menuId:'出入库.入库', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/StockInDetail",  component:StockInDetail, name: "入库详情"},
    {path: "/StockOutList",  component:StockOutList, name: "出库", menuId:'出入库.出库', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/StockOutDetail",  component:StockOutDetail, name: "出库详情"},
    
    {path: "/InventoryList",  component:InventoryList, name: "库存-按库位", menuId:'库存.库存-按库位', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/InventoryListByStockIn",  component:InventoryListByStockIn, name: "库存-按入库", menuId:'库存.库存-按入库', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/InventoryListByStockInDetail",  component:InventoryListByStockInDetail, name: "出库详情"},
    {path: "/TransferList",  component:TransferList, name: "移库", menuId:'库存.移库', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.GEN_MGR]},
    {path: "/TransferDetail",  component:TransferDetail, name: "移库详情"},

    {path: "/InvoiceWaitList",  component:InvoiceWaitList, name: "待生成账单", menuId:'账单.待生成账单', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/InvoiceWaitDetail",  component:InvoiceWaitDetail, name: "待生成账单详情"},
    {path: "/InvoiceList",  component:InvoiceList, name: "账单", menuId:'账单.已生成账单', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.ORD]},
    {path: "/InvoiceDetail",  component:InvoiceDetail, name: "账单详情"},
    {path: "/StockInOtherExpenseList",  component:StockInOtherExpenseList, name: "其它费用备注", menuId:'账单.其它费用备注', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.CASH]},
    {path: "/ReportInventoryByDate",  component:ReportInventoryByDate, name: "账单详情-按天数", menuId:'账单.账单详情-按天数', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/SummaryStockOutInvoiceByOwner",  component:SummaryStockOutInvoiceByOwner, name: "货主汇总-按单出", menuId:'账单.货主汇总-按单出', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/SummaryMonthInvoiceByOwner",  component:SummaryMonthInvoiceByOwner, name: "货主汇总-按月出", menuId:'账单.货主汇总-按月出', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/SummaryAllInvoiceByOwner",  component:SummaryAllInvoiceByOwner, name: "货主汇总-全部", menuId:'账单.货主汇总-全部', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},

    {path: "/RentOwnerList",  component:RentOwnerList, name: "货主", menuId:'包库.货主', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.CASH, x1.ROLE.GEN_MGR]},
    {path: "/RentOwnerDetail",  component:RentOwnerDetail, name: "货主详情"},
    {path: "/RentOrderList",  component:RentOrderList, name: "包库合同", menuId:'包库.包库合同', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.CASH, x1.ROLE.GEN_MGR]},
    {path: "/RentOrderDetail",  component:RentOrderDetail, name: "包库合同详情"},
    {path: "/RentWarehouseLocationList",  component:RentWarehouseLocationList, name: "包库库位", menuId:'包库.包库库位', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.CASH, x1.ROLE.GEN_MGR]},
    {path: "/RentWarehouseLocationDetail",  component:RentWarehouseLocationDetail, name: "包库库位详情"},
    {path: "/RentMonthChargeList",  component:RentMonthChargeList, name: "其它收费查询", menuId:'包库.其它收费查询', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.CASH, x1.ROLE.GEN_MGR]},
    {path: "/RentMonthChargeDetail",  component:RentMonthChargeDetail, name: "其它收费详情"},
    {path: "/RentOrderInvoiceList",  component:RentOrderInvoiceList, name: "包库", menuId:'包库.包库账单', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.CASH, x1.ROLE.GEN_MGR]},

    {path: "/MonthExpenseList",  component:MonthExpenseList, name: "收支", menuId:'收支.收支', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/MonthExpenseDetail",  component:MonthExpenseDetail, name: "收支详情"},

    {path: "/ReportByOwnerStockInList",  component:ReportByOwnerStockInList, name: "货主账单"},
    {path: "/ReportFinance",  component:ReportFinance, name: "营收总表", menuId:'报表.营收总表', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/ReportWorkDetail",  component:ReportWorkDetail, name: "每日明细单"},
    {path: "/ReportWorkStevedore",  component:ReportWorkStevedore, name: "工作报表-搬运工", menuId:'报表.工作报表-搬运工', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/ReportManagerOvertime",  component:ReportManagerOvertime, name: "工作报表-保管员加班费", menuId:'报表.工作报表-保管员', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/ReportManagerOvertimeDetail",  component:ReportManagerOvertimeDetail, name: "工作报表-保管员加班费详情"},
    {path: "/ReportInventoryByProduct",  component:ReportInventoryByProduct, name: "库存报表-按品类", menuId:'报表.库存报表-按品类', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/ReportInventoryByOwner",  component:ReportInventoryByOwner, name: "库存报表-按货主", menuId:'报表.库存报表-按货主', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/ReportInventoryByLocation",  component:ReportInventoryByLocation, name: "库存报表-按库房", menuId:'报表.库存报表-按库房', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/ReportRentOrderByLocation",  component:ReportRentOrderByLocation, name: "包库面积报表", menuId:'报表.包库面积报表', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},
    {path: "/ReportDataUpdate",  component:ReportDataUpdate, name: "刷新报表数据", menuId:'报表.刷新报表数据', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN]},

    {path: "/TemporaryJobList",  component:TemporaryJobList, name: "临时工作", menuId:'临时工作.临时工作', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/TemporaryJobDetail",  component:TemporaryJobDetail, name: "详情"},
    {path: "/ManagerOvertimeList",  component:ManagerOvertimeList, name:'员工加班', menuId:'临时工作.员工加班', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},
    {path: "/ManagerOvertimeDetail",  component:ManagerOvertimeDetail, name: "详情"},
    {path: "/StockInOutOvertimeList",  component:StockInOutOvertimeList, name: "出入库加班", menuId:'临时工作.出入库加班', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.BIZ, x1.ROLE.ORD, x1.ROLE.GEN_MGR]},

    {path: "/Blank",  component:Blank, name: ""},

    {path: "/XxTransactionList",  component:XxTransactionList, name: "运营报表", menuId:'运营报表.数据填报表', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.GUEST]},
    // {path: "/XxTransactionDetail",  component:XxTransactionDetail, name: "数据填报表"},
    {path: "/XxStockInfoList",  component:XxStockInfoList, name: "仓库信息表", menuId:'运营报表.仓库信息表', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.GUEST]},
    {path: "/XxStockInfoDetail",  component:XxStockInfoDetail, name: "详情"},
    {path: "/XxPalletUsageList",  component:XxPalletUsageList, name: "标准托盘使用率表", menuId:'运营报表.标准托盘使用率表', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.GUEST]},
    {path: "/XxPalletUsageDetail",  component:XxPalletUsageDetail, name: "详情"},
    {path: "/XxCarList",  component:XxCarList, name: "车辆信息表", menuId:'运营报表.车辆信息表', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.GUEST]},
    {path: "/XxCarDetail",  component:XxCarDetail, name: "详情"},
    {path: "/XxInventoryTurnRateList",  component:XxInventoryTurnRateList, name: "库存周转率表", menuId:'运营报表.库存周转率表', roles:[x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR, x1.ROLE.GUEST]},
    {path: "/XxInventoryTurnRateDetail",  component:XxInventoryTurnRateDetail, name: "详情"},

  ];

  componentWillMount(){
    var that = this;
    x1.router.initRouter(this.pages);
    x1.router.setRouteChangedListener(()=>{
      that.setState({});
    });
  };

  componentDidMount(){
  };

  getPageByPath = function(path){
    for(var i=0; i<this.pages.length; i++){
      var page = this.pages[i];
      if(path == page.path){
        return page;
      }
    }
    return null;
  }


  getStartPage(){
    // 从INIT_PAGES中选有画面权限的
    for(var i=0; i<this.INIT_PAGES.length; i++){
      var pageId = this.INIT_PAGES[i];
      var page = this.getPageByPath(pageId);
      if(page != null){
        for(var j=0; j<page.roles.length; j++){
          if(x1.user.hasRole(page.roles[j])){
            return pageId;
          }
        }
      }
    }
    return "/Blank";
  }

  getRouteTargetPage = function(){
    if(!x1.user.hasUserInfo()){
      // 没有登录
      var info = x1.user.getLocalSavedUserInfo();
      if(info != null && info.phone_num > '' && info.pw_hash_txt > ''){
        // 本地有用户信息，尝试自动登录
        const params = {
          apiUrl: "/api/user/login",
          ...info,
          success: (data) => {
            x1.user.login(data, true);
            x1.router.relaunch();
          },
          fail: (error) => {
            // 自动登录失败，跳转到登陆画面
            x1.user.logout();
          },
        };
        x1.request(params);

      } else {
        // 本地也没有用户信息，跳转到登陆画面
        return React.createElement(Login, null, null);
      }
    } else {
      // 没有画面栈，初始化默认画面
      let routePage = x1.router.getTopPage();
      if(routePage == null){
        let path = this.getStartPage();
        let page = this.getPageByPath(path);
        if(page != null){
          x1.router.initPage(path);
        }
      }

      // 加载最上层的画面
      routePage = x1.router.getTopPage();
      if(routePage == null){
        return;
      }
      let page = this.getPageByPath(routePage.path);
      if(page == null){
        return;
      }
      return React.createElement(page.component, null, null);
    }
  };

  render() {
    return (
      <div>
        {this.getRouteTargetPage()}
      </div>
    );
  };
}

export default App;
