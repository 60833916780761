import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class RentOwnerList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {rent_ownr_nm, ctac_psn_nm, rent_ownr_tel_num} = state;
        form.setFieldsValue({rent_ownr_nm, ctac_psn_nm, rent_ownr_tel_num});
        this.refreshPage();
      }
    }

    if(!isBack) {
      var paramIn = x1.router.getParamIn();
      if(x1.hasValue(paramIn.rent_ownr_id)){
        form.setFieldsValue({
          rent_ownr_id: paramIn.rent_ownr_id,
        });
      }
      this.initSearch();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  hasFunctionAdd(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH) || x1.user.hasRole(x1.ROLE.GEN_MGR)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/rentOwner/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      get_actv_loc_flg: 'Y',
      get_actv_ord_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/RentOwnerDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/RentOwnerDetail', record, x1.getStateSnapshort(this));
  };

  openOrder(record) {
    x1.router.push('/RentOrderList', record, x1.getStateSnapshort(this));
  };

  openLocation(record) {
    x1.router.push('/RentWarehouseLocationList', record, x1.getStateSnapshort(this));
  };

  openMonthCharge(record) {
    x1.router.push('/RentMonthChargeList', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/rentOwner/delete",
      rent_ownr_id: record.rent_ownr_id,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  columns = [
    {title: '货主编号', dataIndex: 'rent_ownr_id'},
    {title: '货主名称', dataIndex: 'rent_ownr_nm'},
    {title: '联系人', dataIndex: 'ctac_psn_nm'},
    {title: '手机号', dataIndex: 'rent_ownr_tel_num'},
    {title: '商品', dataIndex: 'item_nm'},
    {title: '备注', dataIndex: 'ownr_memo_txt'},
    {title: '押金收取日', render: (text, record) => (
      <div>{x1.date2Text(record.dep_rcv_dt)}</div>
      )},
    {title: '押金金额', dataIndex: 'dep_rcv_amt'},
    {title: '状态', dataIndex: 'rent_ownr_sts_nm'},
    {title: '当前包库', render: (text, record) => (
        <div>
          {record.listOrd.map((ord) => (
            <div>
              <div>{ord.wh_nm + ', 单价:' + ord.rent_unit_prc_amt}</div>
              <div>{x1.date2Text(ord.rent_from_dt) + ' ~ ' + x1.date2Text(ord.rent_to_dt)}</div>
            </div>
          ))}
        </div>
      )},
    {title: '当前库位', render: (text, record) => (
        <div>
          {record.listLoc.map((loc) => (
            <div>
              <div>{loc.wh_nm + ', ' + loc.wh_loc_nm}</div>
              <div>{x1.date2Text(loc.rent_from_dt) + ' ~ ' + x1.date2Text(loc.rent_to_dt)}</div>
            </div>
          ))}
        </div>
      )},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          <a onClick={() => this.openOrder(record)} style={{marginRight: '10px'}}>合同</a>
          <a onClick={() => this.openLocation(record)} style={{marginRight: '10px'}}>库位</a>
          <a onClick={() => this.openMonthCharge(record)} style={{marginRight: '10px'}}>其他收费</a>
          {this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除货主【" + record.rent_ownr_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="货主名称" allowClear={true}>
              {form.getFieldDecorator('rent_ownr_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="联系人" allowClear={true}>
              {form.getFieldDecorator('ctac_psn_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="电话" allowClear={true}>
              {form.getFieldDecorator('rent_ownr_tel_num')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            {this.hasFunctionAdd() &&
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增货主</Button>
            }
          </Form>
          <Table
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

RentOwnerList = Form.create({})(RentOwnerList);
export default RentOwnerList;
