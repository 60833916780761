import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class OwnerDepositList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listOwnr: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {dep_rcv_dt, ownr_id} = state;
        form.setFieldsValue({dep_rcv_dt, ownr_id});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initOwnerList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/ownerDeposit/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/OwnerDepositDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/OwnerDepositDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/ownerDeposit/delete",
      ownr_dep_pk: record.ownr_dep_pk,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  columns = [
    {title: '交易日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.dep_rcv_dt)}</div>
      )
    },
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '押金金额', dataIndex: 'dep_rcv_amt'},
    {title: '收取/退回',
      render: (text, record) => (
        record.dep_rcv_amt>0?<div>收</div>:<div>退</div>
      )
    },
    {title: '备注', dataIndex: 'memo_txt'},
    {title: '更新人', dataIndex: 'upd_usr_nm'},
    {title: '更新时间', dataIndex: 'upd_ts'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          {this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除押金【" + record.dept_rcv_amt + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'} />
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="日期" allowClear={true}>
              {form.getFieldDecorator('dept_rcv_dt')(
                <DatePicker style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="货主">
              {form.getFieldDecorator('ownr_id')(
                <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.state.listOwnr.map((item) => (
                    <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
          </Form>
          <Table
            rowKey={'ownr_dep_pk'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

OwnerDepositList = Form.create({})(OwnerDepositList);
export default OwnerDepositList;
