import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class XxStockInfoDetail extends PureComponent {
  state = {
    loading: false,
    listUsrRole: [],
    xx_wh_pk: '',
  };

  componentDidMount() {
    const {form} = this.props;

    var xx_wh_pk = x1.router.getParamIn().xx_wh_pk;
    if (xx_wh_pk > '') {
      this.setState({xx_wh_pk: xx_wh_pk});
      form.setFieldsValue({xx_wh_pk: xx_wh_pk});
      this.search(xx_wh_pk);
    } else {
    }
  };

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      const params = {
        apiUrl: this.state.xx_wh_pk > '' ? "/api/xxWh/update" : "/api/xxWh/insert" ,
        ...fieldsValue,
        xx_wh_pk: this.state.xx_wh_pk,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(this.state.xx_wh_pk == ''){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
      };
      x1.request(params);
    });
  };

  search(xx_wh_pk) {
    const {form} = this.props;
    const params = {
      apiUrl: "/api/xxWh/list",
      curPage: 0,
      pageSize: 1,
      xx_wh_pk: xx_wh_pk,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };


  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 3},
      wrapperCol: {span: 8},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 3},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>
                <FormItem {...formItemLayout} label="名称">
                    {form.getFieldDecorator('wh_num', {
                        rules: [{
                            required: true,
                            message: '请输入名称',
                        }]
                    })(
                        <Input style={{width: '150px'}}/>
                    )}
                </FormItem>
              <FormItem {...formItemLayout} label="地址">
                {form.getFieldDecorator('wh_addr', {
                  rules: [{
                    required: true,
                    message: '请输入地址',
                  }]
                })(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
                <FormItem {...formItemLayout} label="面积">
                    {form.getFieldDecorator('wh_area_amt', {
                        rules: [{
                            required: true,
                            message: '请输入面积',
                        }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="容积">
                    {form.getFieldDecorator('wh_vol_amt', {
                        rules: [{
                            required: true,
                            message: '请输入容积',
                        }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                </FormItem>


                <FormItem {...formItemLayout} label="类型">
                    {form.getFieldDecorator('wh_tp_nm', {
                        rules: [{
                            required: true,
                            message: '请选择角色',
                        }]
                    })(
                        <Select style={{width: '150px'}}>
                            <Option value={'冷藏库'}>冷藏库</Option>
                            <Option value={'冷冻库'}>冷冻库</Option>
                            <Option value={'成品库'}>成品库</Option>
                        </Select>
                    )}
                </FormItem>


              <FormItem {...submitItemLayout}>
                <Button type="primary" onClick={this.handleSubmit}>提交</Button>
              </FormItem>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

XxStockInfoDetail = Form.create({})(XxStockInfoDetail);
export default XxStockInfoDetail;
