import React, {PureComponent} from 'react';
import {Form, Button, Checkbox, Input, Icon} from 'antd';
import UserLayout from "../layouts/UserLayout";
import {x1} from "../x1";

const FormItem = Form.Item;

class Login extends PureComponent {
  state = {
  };

  componentDidMount() {
  };

  handleLogin = () => {
    const { form } = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      const params = {
        apiUrl: "/api/user/login",
        ...fieldsValue,
        success: (data) => {
          x1.user.login(data, fieldsValue.keepLogin);
          x1.router.relaunch();
          x1.showMessage('登录成功');
        },
      };
      x1.request(params);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <UserLayout>
        <div style={{display:'flex', justifyContent:'center', marginTop:'36px'}}>
          <Form style={{width:'300px'}}>
            <Form.Item>
              {getFieldDecorator('phone_num', {
                rules: [{ required: true, message: '请输入用户名' }],
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} size="large" placeholder="用户名" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('pw_hash_txt', {
                rules: [{ required: true, message: '请输入密码' }],
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} size="large" type="password" placeholder="密码" />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'8px'}}>
              {getFieldDecorator('keepLogin', {
                valuePropName: 'checked',
                initialValue: true,
              })(
                <Checkbox>保存登录信息</Checkbox>
              )}
            </Form.Item>
            <Button type="primary" style={{width:'100%'}} size="large" onClick={()=>this.handleLogin()}>登录</Button>
          </Form>
        </div>
      </UserLayout>
    );
  };
}

Login = Form.create({})(Login);
export default Login;
