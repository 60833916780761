import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, Upload, Icon, Modal, Table, DatePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class Blank extends PureComponent {
  render() {
    return (
      <BasicLayout>
      </BasicLayout>
    );
  };
}

Blank = Form.create({})(Blank);
export default Blank;
