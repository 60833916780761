import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const {MonthPicker} = DatePicker;
const FormItem = Form.Item;
const {Option} = Select;

class MonthExpenseList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listWh: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {exp_mth, wh_cd} = state;
        form.setFieldsValue({exp_mth, wh_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initWarehouseList();
    }
  };

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      invty_flg: 'Y',
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/monthExpense/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/MonthExpenseDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/MonthExpenseDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/monthExpense/delete",
      mth_exp_pk_pk: record.mth_exp_pk_pk,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  columns = [
    {title: '月份', render: (text, record) => (
        <div>{x1.month2Text(record.exp_mth)}</div>
      )
    },
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '员工人数', dataIndex: 'mth_emp_cnt'},
    {title: '搬运工工人数', dataIndex: 'mth_porter_cnt'},
    {title: '员工工资', dataIndex: 'mth_wage_amt'},
    {title: '搬运工工资', dataIndex: 'mth_porter_wage_amt'},
    {title: '电费支出', dataIndex: 'mth_power_amt'},
    {title: '场地支出', dataIndex: 'mth_rent_amt'},
    {title: '伙食费', dataIndex: 'mth_meal_amt'},
    {title: '杂项支出', dataIndex: 'mth_oth_amt'},
    {title: '其它收入', dataIndex: 'mth_oth_incm_amt'},
    {title: '更新时间', dataIndex: 'upd_ts'},
    {title: '更新人', dataIndex: 'upd_usr_nm'},

    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          {record.mth_exp_pk_pk > '' && this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除支出【" + x1.month2Text(record.pmt_mth) + record.wh_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="月份">
              {form.getFieldDecorator('exp_mth', {
              })(<MonthPicker style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="仓库">
              {form.getFieldDecorator('wh_cd')(
                <Select style={{width: '100px'}} allowClear={true}>
                  {this.state.listWh.map((item) => (
                    <Option key={item.wh_cd}>{item.wh_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
          </Form>
          <Table
            rowKey={'mth_exp_pk_pk'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

MonthExpenseList = Form.create({})(MonthExpenseList);
export default MonthExpenseList;
