import React, {Fragment, PureComponent} from 'react';
import {Tooltip, Button, Card, Form, Input, Popconfirm, Select, Table, DatePicker, Radio} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class ReportFinance extends PureComponent {
  state = {
    loading: false,
    loadingDownload: false,
    pagination: {},
    listItem: [],
    smryData: {},
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {from_rpt_dt, to_rpt_dt, wh_loc_nm} = state;
        form.setFieldsValue({from_rpt_dt, to_rpt_dt, wh_loc_nm});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initFromNoneBack();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initFromNoneBack(){
    const {form} = this.props;
    if(x1.report.hasConditionDate()){
      // 取上个画面的区间
      x1.report.loadConditionDate(this);
    } else {
      // 默认期间3个月
      var toDt = x1.getCurrentMoment();
      var fromDt = x1.getCurrentMoment().subtract(3, 'months');
      form.setFieldsValue({
        from_rpt_dt: fromDt,
        to_rpt_dt: toDt,
      });
    }
    this.initSearch();
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 50);
    this.searchSummary();
  };

  searchSummary() {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/reportFinanceSummary",
      ...values,
      success: (data) => {
        if(data.length > 0) {
          this.setState({
              smryData: data[0],
          });
        }
      },
      complete: () => {
        this.setState({loadingSummary: false});
      }
    };
    this.setState({loadingSummary: true});
    x1.request(params);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/reportFinance",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/downloadReportFinance",
      ...values,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  onChangeDate(){
    setTimeout(()=>{
      const {form} = this.props;
      var values = form.getFieldsValue();
      x1.report.saveConditionDate(values.from_rpt_dt, values.to_rpt_dt);
    },1);
  }

  columns = [
    {title: '仓库',
      render: (text, record) => (
        <div>
          <div>{record.wh_nm}</div>
          <div>{record.wh_area_amt + '㎡'}</div>
        </div>
      ),
    },
    {title: '报表天数', dataIndex: 'rpt_days', width:45},
    {title: '期初期末',
      render: (text, record) => (
        <div>
          <div>{'初：' + record.beg_invty_qty}</div>
          <div>{'末：' + record.end_invty_qty}</div>
        </div>
      ),
    },
    {title: <Tooltip title={
        <div>
          <div>期间内的出入库数量，单位为吨</div>
          <div>入库：新增入库量</div>
          <div>出库：新增出库量</div>
          <div>吞吐：入库数+出库数</div>
        </div>
    }><span>出入库</span></Tooltip>,
      render: (text, record) => (
        <div>
          <div>{'入库：' + record.stock_in_qty}</div>
          <div>{'出库：' + record.stock_out_qty}</div>
          <div>{'吞吐：' + record.in_out_qty}</div>
        </div>
      ),
    },
    {title: <Tooltip title="库存量，单位为吨"><span>库存量</span></Tooltip>,
      render: (text, record) => (
        <div>
          <div>{'总数：' + record.tot_invty_qty}</div>
          <div>{'日均：' + record.avg_invty_qty}</div>
        </div>
      ),
    },
    {title: '货物周转',
      render: (text, record) => (
        <div>
          <div>{'周期：' + record.turn_over_per}</div>
          <div>{'频率：' + record.turn_over_rate}</div>
        </div>
      ),
    },
    // {title: '平均在库时间', dataIndex: 'avg_invty_days'},
    // {title: '搬运费单价', dataIndex: 'load_unit_prc_amt'},
    // {title: '搬运费', dataIndex: 'load_amt'},

    {title: <div><div>{'包库'}</div><div>{'面积'}</div></div>, dataIndex: 'rent_area_amt'},
    {title: <div><div>{'包库'}</div><div>{'收入'}</div></div>, dataIndex: 'rent_fee_amt'},
    {title: <div><div>{'包库'}</div><div>{'其它收入'}</div></div>, dataIndex: 'rent_mth_oth_amt'},

    {title: '支出', width: 110,
      render: (text, record) => (
        <div>
          <div>{'工资：' + record.mth_wage_amt}</div>
          <div>{'电费：' + record.mth_power_amt}</div>
          <div>{'房租：' + record.mth_rent_amt}</div>
          <div>{'其它：' + record.mth_oth_amt}</div>
          <div>{'搬运：' + record.mth_porter_wage_amt}</div>
          <div>{'伙食：' + record.mth_meal_amt}</div>
        </div>
      ),
    },

    {title: <div><div>{'收入'}</div><div>{'其它'}</div></div>, dataIndex: 'mth_oth_incm_amt'},

    {title: '非包库收入', width: 110,
      render: (text, record) => (
        <div>
          <div>{'预冷：' + record.pre_cool_fee_amt}</div>
          <div>{'冷藏：' + record.store_fee_amt}</div>
          <div>{'装卸：' + record.load_fee_amt}</div>
          <div>{'转场：' + record.ship_fee_amt}</div>
          <div>{'工本：' + record.card_fee_amt}</div>
          <div>{'其它：' + record.oth_amt}</div>
        </div>
      ),
    },
    {title: <div><div>{'非包库'}</div><div>{'总收入'}</div></div>, dataIndex: 'tot_incm_by_wt_amt'},

    {title: '总收支',
      render: (text, record) => (
        <div>
          <div>{'收：' + record.tot_incm_amt}</div>
          <div>{'支：' + record.tot_exp_amt}</div>
        </div>
      ),
    },
    {title: '平米收支',
      render: (text, record) => (
        <div>
          <div>{'收：' + x1.nvl(record.sq_meter_incm_amt)}</div>
          <div>{'支：' + x1.nvl(record.sq_meter_exp_amt)}</div>
        </div>
      ),
    },

    {title: '毛利率%', dataIndex: 'grs_prft_rate'},
    {title: '利润率%', dataIndex: 'net_prft_rate'},

  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{marginBottom: '16px'}}>
            <div>
              <FormItem {...formItemLayout} label="开始日期">
                {form.getFieldDecorator('from_rpt_dt')(
                  <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="-" colon={false}>
                {form.getFieldDecorator('to_rpt_dt')(
                  <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
            </div>
            <div style={{display:'flex', justifyContent:'flex-end', marginTop:'8px'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              <span style={{paddingRight:'16px'}}></span>
              <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>
            </div>
          </Form>

          {/*<div className='box_cc' style={{background:'#FFFFB8', padding:'8px'}}>*/}
            {/*<div style={{marginRight:'32px'}}>{'报表天数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.rpt_days,'')}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'期初数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.beg_invty_qty,'')}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'期末数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.end_invty_qty,0)}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'入库数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.stock_in_qty,0)}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'出库数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.stock_out_qty,0)}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'吞吐量：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.in_out_qty,0)}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'期间总库存量：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.tot_invty_qty,0)}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'日均库存量：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.avg_invty_qty,0)}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'货物周转周期：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.turn_over_per,0)}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'货物周转率：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.turn_over_rate,0)}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'平均在库时间：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.avg_invty_days,0)}</div></div>*/}
            {/*<div style={{marginRight:'32px'}}>{'搬运费：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.load_amt,0)}</div></div>*/}
          {/*</div>*/}

          <Table
            rowKey={'wh_cd'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

ReportFinance = Form.create({})(ReportFinance);
export default ReportFinance;
