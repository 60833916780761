import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class UserDetail extends PureComponent {
  state = {
    loading: false,
    listUsrRole: [],
  };

  componentDidMount() {
    const {form} = this.props;
    this.initUserRole();

    var usrId = x1.router.getParamIn().usr_id;
    if (usrId > '') {
      form.setFieldsValue({usr_id: usrId});
      this.search(usrId);
    } else {
    }
  };

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      const params = {
        apiUrl: "/api/user/update",
        ...fieldsValue,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.usr_id)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
      };
      x1.request(params);
    });
  };

  search(usrId) {
    const {form} = this.props;
    const params = {
      apiUrl: "/api/user/list",
      curPage: 0,
      pageSize: 1,
      usr_id: usrId,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  initUserRole() {
    const params = {
      apiUrl: "/api/userRole/list",
      success: (data) => {
        this.setState({
          listUsrRole: data,
        });
      },
    };
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 3},
      wrapperCol: {span: 8},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 3},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>
              <FormItem {...formItemLayout} label="员工编码">
                {form.getFieldDecorator('usr_id')(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="姓名">
                {form.getFieldDecorator('usr_nm', {
                  rules: [{
                    required: true,
                    message: '请输入姓名',
                  }]
                })(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="手机">
                {form.getFieldDecorator('phone_num', {
                  rules: [{
                    required: true,
                    message: '请输入手机',
                  }]
                })(<Input style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="角色">
                {form.getFieldDecorator('usr_role_cd', {
                  rules: [{
                    required: true,
                    message: '请选择角色',
                  }]
                })(
                  <Select style={{width: '150px'}}>
                    {this.state.listUsrRole.map((item) => (<Option key={item.usr_role_cd}>{item.usr_role_nm}</Option>))}
                  </Select>
                )}
              </FormItem>

              <FormItem {...submitItemLayout}>
                <Button type="primary" onClick={this.handleSubmit}>提交</Button>
              </FormItem>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

UserDetail = Form.create({})(UserDetail);
export default UserDetail;
