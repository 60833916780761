import React, {Fragment, PureComponent} from 'react';
import {Modal, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import OtherAmountEntryPopup from "./OtherAmountEntryPopup";

const FormItem = Form.Item;
const {Option} = Select;

class InvoiceWaitDetail extends PureComponent {
  state = {
    loading: false,
    loadingSbmt: false,
    loadingDownload: false,
    pagination: {},
    head: {},
    invHead: {},
    listItem: [],
  };

  componentDidMount() {
    var head = x1.router.getParamIn();
    this.state.head = head;
    this.setState({head: head});
    this.initSearch();
  };

  hasFunctionInvoice(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN)){
      return true;
    }
    return false;
  }

  hasFunctionCash(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH)){
      return true;
    }
    return false;
  }

  downloadInvoice = e => {
    var that = this;
    const params = {
      apiUrl: "/api/invoice/download",
      stock_in_num: this.state.head.stock_in_num,
      stock_in_line_num: this.state.head.stock_in_line_num,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  generateInvoice = e => {
    e.preventDefault();
    var head = this.state.head;
    const params = {
      apiUrl: "/api/invoice/createInvoice",
      stock_in_num: head.stock_in_num,
      stock_in_line_num: head.stock_in_line_num,
      inv_crat_usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        x1.showMessage('账单生成成功。账单号：【' + data.inv_num + '】');
      },
      complete: () => {
        this.setState({loadingSbmt: false});
      }
    };
    this.setState({loadingSbmt: true});
    x1.request(params);
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    var head = this.state.head;

    const params = {
      apiUrl: "/api/invoice/getRealtimeInvoiceDetail",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      wh_cd: head.wh_cd,
      ownr_id: head.ownr_id,
      stock_in_num: head.stock_in_num,
      stock_in_line_num: head.stock_in_line_num,
      success: (data) => {
        this.setState({
          listItem: data.listOut,
          invHead: data.head,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  openDetail(record) {
    x1.router.push('/OwnerDetail', record);
  };

  editOtherAmount() {
    this.setState({isShowPopup: true});
  }

  onSubmitEditOtherAmount(input){
    this.setState({isShowPopup: false});
    const params = {
      loading: true,
      apiUrl: "/api/stockIn/editOtherAmount",
      stock_in_num: this.state.head.stock_in_num,
      stock_in_line_num: this.state.head.stock_in_line_num,
      oth_amt: input.oth_amt,
      oth_amt_memo_txt: input.oth_amt_memo_txt,
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
    };
    x1.request(params);
  };

  handleCancelPopup(){
    this.setState({isShowPopup: false});
  }

  columns = [
    {title: '出库日期', dataIndex: 'stock_out_dt_txt'},
    {title: '出库数量', dataIndex: 'stock_out_qty'},
    {title: '结存数量', dataIndex: 'rmn_qty'},
    {title: '出库吨数', dataIndex: 'stock_out_tnng_amt'},
    {title: '结存吨数', dataIndex: 'rmn_tnng_amt'},
    {title: '计费天数', dataIndex: 'inv_day_cnt'},
    // {title: '计费金额', dataIndex: 'inv_amt'},
    {title: '冷藏金额', dataIndex: 'line_store_amt'},
    {title: '装卸费', dataIndex: 'out_load_amt'},
    {title: '提前计算', dataIndex: 'pre_calc_flg'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <div>
          </div>
        </Fragment>
      ),
    },
  ];

  render() {

    var styleItem = {
      width:'200px',
      height: '40px',
      display: 'flex',
    };
    var styleItemTitle = {
      width:'80px',
    };
    var styleItemValue = {
      flex: 1,
    };

    var head = this.state.head;
    var invHead = this.state.invHead;

    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'flex-start', marginBottom: '16px'}}>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'仓库：'}</div>
                <div style={styleItemValue}>{head.wh_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'货主：'}</div>
                <div style={styleItemValue}>{head.ownr_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'商品：'}</div>
                <div style={styleItemValue}>{head.item_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库日期：'}</div>
                <div style={styleItemValue}>{x1.date2Text(head.stock_in_dt)}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'单位重量(kg)：'}</div>
                <div style={styleItemValue}>{head.unit_kg_amt}</div>
              </div>
            </div>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库数量：'}</div>
                <div style={styleItemValue}>{head.stock_in_qty}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库吨位：'}</div>
                <div style={styleItemValue}>{invHead.tot_in_tnng_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'出库数量：'}</div>
                <div style={styleItemValue}>{invHead.tot_out_qty}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'出库吨位：'}</div>
                <div style={styleItemValue}>{invHead.tot_out_tnng_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'结存数量：'}</div>
                <div style={styleItemValue}>{invHead.tot_rmn_qty}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'结存吨位：'}</div>
                <div style={styleItemValue}>{invHead.tot_rmn_tnng_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库搬运费：'}</div>
                <div style={styleItemValue}>{invHead.in_load_amt}</div>
              </div>
            </div>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'预冷费：'}</div>
                <div style={styleItemValue}>{invHead.pre_cool_fee_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'冷藏费：'}</div>
                <div style={styleItemValue}>{invHead.store_fee_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'装卸费：'}</div>
                <div style={styleItemValue}>{invHead.load_fee_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'转场费：'}</div>
                <div style={styleItemValue}>{invHead.ship_fee_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'提货卡工本费：'}</div>
                <div style={styleItemValue}>{invHead.card_fee_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'其它费用：'}</div>
                <div style={styleItemValue}>{invHead.oth_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'合计：'}</div>
                <div style={styleItemValue}>{invHead.tot_inv_amt}</div>
              </div>
            </div>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'预冷费单价：'}</div>
                <div style={styleItemValue}>{invHead.pre_cool_unit_prc_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'冷藏费单价：'}</div>
                <div style={styleItemValue}>{invHead.store_unit_prc_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'装卸费单价：'}</div>
                <div style={styleItemValue}>{invHead.load_unit_prc_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'转场费单价：'}</div>
                <div style={styleItemValue}>{invHead.ship_unit_prc_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'提货卡工本费单价：'}</div>
                <div style={styleItemValue}>{invHead.card_unit_prc_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'其它费用备注：'}</div>
                <div style={styleItemValue}>{invHead.oth_amt_memo_txt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'预收款：'}</div>
                <div style={styleItemValue}>{invHead.pre_paid_amt}</div>
              </div>
            </div>
            <div>
              <div>
                <div style={styleItem}>
                  <div style={styleItemTitle}>{'入库备注：'}</div>
                  <div style={styleItemValue}>{invHead.stock_in_memo_txt}</div>
                </div>
              </div>
              <div className={'box'}>
              {this.hasFunctionInvoice() &&
              <Fragment>
                <div><Button type="primary" onClick={this.generateInvoice} loading={this.state.loadingSbmt}>生成账单</Button></div>
                <div style={{width:'16px'}}></div>
                <div><Button type="primary" onClick={this.downloadInvoice} loading={this.state.loadingDownload}>下载账单</Button></div>
              </Fragment>
              }
              {this.hasFunctionCash() && x1.isNull(this.state.head.inv_num) &&
              <Fragment>
                <div style={{width: '16px'}}></div>
                <div><Button onClick={() => this.editOtherAmount()}>编辑其它费用</Button></div>
              </Fragment>
              }
              </div>
            </div>
          </Form>
          <Table
            rowKey={'stock_out_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>

        {this.state.isShowPopup &&
        <Modal visible={true} footer={null} onCancel={()=>this.handleCancelPopup()}>
          <OtherAmountEntryPopup inv={this.state.head} onSubmit={(input)=>this.onSubmitEditOtherAmount(input)}/>
        </Modal>
        }

      </BasicLayout>

    );
  };
}

InvoiceWaitDetail = Form.create({})(InvoiceWaitDetail);
export default InvoiceWaitDetail;
