import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const {MonthPicker} = DatePicker;
const FormItem = Form.Item;
const {Option} = Select;

class RentMonthChargeList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listWh: [],
    listOwnr: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state, ()=>{
          var {rent_ownr_id, rent_mth, wh_cd} = state;
          form.setFieldsValue({rent_ownr_id, rent_mth, wh_cd});
          this.refreshPage();
        });
      }
    }

    if(!isBack) {
      var param = x1.router.getParamIn();
      if(param != null){
        this.setState({rent_ownr_id: param.rent_ownr_id}, ()=>{
          form.setFieldsValue({rent_ownr_id: param.rent_ownr_id});
        });
      }
      this.initSearch();
      this.initWarehouseList();
      this.initOwnerList();
    }
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/rentOwner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      invty_flg: 'Y',
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/rentMonthCharge/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      rent_ownr_id: values.rent_ownr_id,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    const {form} = this.props;
    x1.router.push("/RentMonthChargeDetail", {rent_ownr_id: form.getFieldsValue().rent_ownr_id}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/RentMonthChargeDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/rentMonthCharge/delete",
      rent_mth_chrg_pk: record.rent_mth_chrg_pk,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  columns = [
    {title: '收费日期', render: (text, record) => (
        <div>{x1.date2Text(record.chrg_dt)}</div>
      )
    },
    {title: '货主', render: (text, record) => (
        <div>
          <div>{record.rent_ownr_nm}</div>
          <div>{record.rent_ownr_tel_num}</div>
        </div>
      )
    },
    {title: '商品', dataIndex: 'item_nm'},
    // {title: '仓库', render: (text, record) => (
    //     <div>
    //       <div>{record.wh_nm}</div>
    //       {/*<div>{record.wh_loc_nm}</div>*/}
    //     </div>
    //   )
    // },
    // {title: '租用面积m2', dataIndex: 'rent_area_amt'},
    // {title: '租用期间段', render: (text, record) => (
    //     <div>
    //       <div>{'从：' + x1.date2Text(record.rent_from_dt)}</div>
    //       <div>{'至：' + x1.date2Text(record.rent_to_dt)}</div>
    //     </div>
    //   ),
    // },
    // {title: '内部备注', dataIndex: 'memo_txt'},
    {title: '其它收费金额', dataIndex: 'rent_mth_oth_amt'},
    {title: '其它收费备注', dataIndex: 'rent_mth_chrg_memo_txt'},
    {title: '数量', dataIndex: 'chrg_qty'},
    {title: '单价', dataIndex: 'unit_prc_amt'},
    {title: '单位', dataIndex: 'chrg_unit_nm'},
    {title: '吨位', dataIndex: 'load_tnng_amt'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          {record.rent_mth_chrg_pk > '' && this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除收费【" + x1.month2Text(record.rent_mth) + record.wh_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="货主">
              {form.getFieldDecorator('rent_ownr_id')(
                <Select style={{width: '150px'}} allowClear={true} disabled={x1.hasValue(this.state.rent_ownr_id)}
                        showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {this.state.listOwnr.map((item) => (
                    <Option key={item.rent_ownr_id}>{item.rent_ownr_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="月份">
              {form.getFieldDecorator('rent_mth', {
              })(<MonthPicker style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="仓库">
              {form.getFieldDecorator('wh_cd')(
                <Select style={{width: '100px'}} allowClear={true}>
                  {this.state.listWh.map((item) => (
                    <Option key={item.wh_cd}>{item.wh_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft: '16px'}}>新增</Button>
          </Form>
          <Table
            // rowKey={'rent_mth_chrg_pk'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

RentMonthChargeList = Form.create({})(RentMonthChargeList);
export default RentMonthChargeList;
