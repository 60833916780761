import React, {PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';

const {MonthPicker} = DatePicker;
const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class RentMonthChargeDetail extends PureComponent {
  state = {
    loading: false,
    rent_mth_chrg_pk: '',
    listWh: [],
    listOwnr: [],
  };

  componentDidMount() {
    const {form} = this.props;

    var paramIn = x1.router.getParamIn();
    this.state.rent_mth_chrg_pk = paramIn.rent_mth_chrg_pk;
    this.state.rent_ownr_id = paramIn.rent_ownr_id;
    form.setFieldsValue(paramIn);
    if(x1.hasValue(paramIn.rent_mth_chrg_pk)){
      this.search();
    }
    this.initOwnerList();
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/rentOwner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  search() {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/rentMonthCharge/list",
      rent_mth_chrg_pk: this.state.rent_mth_chrg_pk,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/rentMonthCharge/update",
        ...fieldsValue,
        rent_mth_chrg_pk: this.state.rent_mth_chrg_pk,
        upd_usr_id: x1.user.getCurrentUserId(),
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          // if(x1.isNull(this.state.rent_mth_chrg_pk)){
            x1.router.back();
          // }
        },
      };
      x1.request(params);
    });
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="货主">
                    {form.getFieldDecorator('rent_ownr_id')(
                      <Select style={{width: '150px'}} allowClear={true} disabled={x1.hasValue(this.state.rent_ownr_id)}
                              showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {this.state.listOwnr.map((item) => (
                          <Option key={item.rent_ownr_id}>{item.rent_ownr_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  {/*<FormItem {...formItemLayout} label="月份">*/}
                    {/*{form.getFieldDecorator('rent_mth', {*/}
                      {/*rules: [{*/}
                        {/*required: true,*/}
                        {/*message: '请输入月份',*/}
                      {/*}]*/}
                    {/*})(<MonthPicker style={{width: '150px'}} locale={locale}/>*/}
                    {/*)}*/}
                  {/*</FormItem>*/}
                  <FormItem {...formItemLayout} label="收费日期">
                    {form.getFieldDecorator('chrg_dt', {
                      rules: [{
                        required: true,
                        message: '请输入收费日期',
                      }]
                    })(<DatePicker style={{width: '150px'}} locale={locale}/>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="其它收费金额">
                    {form.getFieldDecorator('rent_mth_oth_amt', {
                      rules: [{
                        required: true,
                        message: '请输入其它收费金额',
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="收费备注">
                    {form.getFieldDecorator('rent_mth_chrg_memo_txt', {
                      rules: [{
                        required: true,
                        message: '请输入收费备注',
                      }]
                    })(<Input style={{width: '300px'}}/>
                    )}
                  </FormItem>
                  {/*<FormItem {...formItemLayout} label="件数">*/}
                    {/*{form.getFieldDecorator('load_qty', {*/}
                    {/*})(<Input style={{width: '150px'}}/>*/}
                    {/*)}*/}
                  {/*</FormItem>*/}
                  <FormItem {...formItemLayout} label="数量">
                    {form.getFieldDecorator('chrg_qty', {
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="单价">
                    {form.getFieldDecorator('unit_prc_amt', {
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="单位">
                    {form.getFieldDecorator('chrg_unit_nm', {
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="吨位">
                    {form.getFieldDecorator('load_tnng_amt', {
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="输入时间">
                    {form.getFieldDecorator('upd_ts', {
                    })(<Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="输入人">
                    {form.getFieldDecorator('upd_usr_nm', {
                    })(<Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  }
                  <FormItem {...submitItemLayout}>
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                  </FormItem>
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

RentMonthChargeDetail = Form.create({})(RentMonthChargeDetail);
export default RentMonthChargeDetail;
