import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider, Radio} from 'antd';
import {message} from "antd/lib/index";
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;


class StockInDetailPriceEntryPopup extends PureComponent {
  state = {
  };

  componentDidMount() {
    const {form} = this.props;
    form.setFieldsValue({
      unit_prc_amt: this.props.stockInLine.unit_prc_amt,
    });
  };

  handleSubmit= e  => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      var param = {
        stockInLine: this.props.stockInLine,
        unit_prc_amt: fieldsValue.unit_prc_amt,
      }
      this.props.onSubmit(param);
    });
  }

  render() {
    const {form} = this.props;
    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };
    return (
      <div>
        <Form style={{marginBottom: '16px'}}>
          <FormItem {...formItemLayout} label="单价">
            {form.getFieldDecorator('unit_prc_amt', {
              rules: [{
                required: true,
                message: '请输入单价',
              }]
            })(
              <Input style={{width: '150px'}}/>
            )}
          </FormItem>
          <FormItem {...submitItemLayout}>
            <Button type="primary" onClick={this.handleSubmit}>提交</Button>
          </FormItem>
        </Form>
      </div>
    );
  };
}

StockInDetailPriceEntryPopup = Form.create({})(StockInDetailPriceEntryPopup);
export default StockInDetailPriceEntryPopup;
