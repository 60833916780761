import React from 'react';
import './UserLayout.css';

class UserLayout extends React.PureComponent {
  render() {
    const { children } = this.props;
    return (
      <div className="container">
        <div className="content">
          <div className="top">
            <div className="header">
                {/*<img alt="logo" className={styles.logo} src={logo} />*/}
                <span className="title">冷库管理系统</span>
            </div>
          </div>
          {children}
        </div>
      </div>
    );
  }
}

export default UserLayout;
