import React, {PureComponent} from 'react';
import {Divider, DatePicker, Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class RentWarehouseLocationDetail extends PureComponent {
  state = {
    loading: false,

    listOwnr: [],
    listWh: [],
    listWhLoc: [],
  };

  componentDidMount() {
    const {form} = this.props;

    var rent_wh_loc_pk = x1.router.getParamIn().rent_wh_loc_pk;
    if (rent_wh_loc_pk > '') {
      // 更新
      form.setFieldsValue({rent_wh_loc_pk: rent_wh_loc_pk});
      this.search(rent_wh_loc_pk);
    } else {
      // 新增
      var paramIn = x1.router.getParamIn();
      form.setFieldsValue({
        rent_ownr_id: paramIn.rent_ownr_id,
        rent_from_dt: paramIn.rent_from_dt,
        rent_to_dt: paramIn.rent_to_dt,
        wh_cd: paramIn.wh_cd,
      });
      if(x1.hasValue(paramIn.wh_cd)){
        this.initWarehouseLocationList();
      }
    }
    this.initWarehouseList();
    this.initOwnerList();
  };

  hasFunctionUpdate(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH)){
      return true;
    }
    return false;
  }

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/rentWarehouseLocation/update",
        ...fieldsValue,
        usr_id: x1.user.getCurrentUserId(),
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.rent_wh_loc_pk)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
        complete: () => {
          this.setState({loading: false});
        }
      };
      this.setState({loading: true});
      x1.request(params);
    });
  };

  search(rent_wh_loc_pk) {
    var that = this;
    const {form} = this.props;
    const params = {
      loading: true,
      apiUrl: "/api/rentWarehouseLocation/list",
      rent_wh_loc_pk: rent_wh_loc_pk,
      success: (data) => {
        form.setFieldsValue(data[0]);
        this.initWarehouseLocationList();
      },
    };
    x1.request(params);
  };

  onChanageWarehouse(wh_cd){
    const {form} = this.props;
    form.setFieldsValue({'wh_cd': wh_cd});
    this.initWarehouseLocationList();
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/rentOwner/list",
      rent_ownr_sts_cd: 'AC',
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseLocationList() {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(x1.isNull(values.wh_cd)){
      this.setState({listWhLoc:[]});
      return;
    }
    const params = {
      apiUrl: "/api/warehouseLocation/list",
      wh_cd: values.wh_cd,
      success: (data) => {
        this.setState({
          listWhLoc: data,
        });
      },
    };
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 8},
      wrapperCol: {span: 16},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 8},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={false}>

              <Row gutter={24}>
                <Col span={8}>
                  <FormItem {...formItemLayout} label="货主">
                    {form.getFieldDecorator('rent_ownr_id', {
                      rules: [{
                        required: true,
                        message: '请输入货主',
                      }]
                    })(
                      <Select style={{width: '150px'}} allowClear={true} onChange={(value)=>this.onChanageOwner(value)} disabled={true}>
                        {this.state.listOwnr.map((item) => (
                          <Option key={item.rent_ownr_id}>{item.rent_ownr_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="仓库">
                    {form.getFieldDecorator('wh_cd', {
                      rules: [{
                        required: true,
                        message: '请输入仓库',
                      }]
                    })(
                      <Select style={{width: '150px'}} allowClear={true} onChange={(value)=>this.onChanageWarehouse(value)}>
                        {this.state.listWh.map((item) => (
                          <Option key={item.wh_cd}>{item.wh_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="库位">
                    {form.getFieldDecorator('wh_loc_cd', {
                      rules: [{
                        required: true,
                        message: '请输入库位',
                      }]
                    })(
                      <Select style={{width: '150px'}} allowClear={true}>
                        {this.state.listWhLoc.map((item) => (
                          <Option key={item.wh_loc_cd}>{item.wh_loc_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="开始日期">
                    {form.getFieldDecorator('rent_from_dt', {
                      rules: [{
                        required: true,
                        message: '请输入开始日期',
                      }]
                    })(
                      <DatePicker style={{width: '130px'}} allowClear={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="结束日期">
                    {form.getFieldDecorator('rent_to_dt', {
                      rules: [{
                        required: true,
                        message: '请输入结束日期',
                      }]
                    })(
                      <DatePicker style={{width: '130px'}} allowClear={true}/>
                    )}
                  </FormItem>

                  {this.hasFunctionUpdate() &&
                  <FormItem {...submitItemLayout}>
                    <Button type="primary" onClick={this.handleSubmit} loading={this.state.loading}>提交</Button>
                  </FormItem>
                  }
                </Col>

                <Col span={8}>
                  <FormItem {...formItemLayout} label="更新人">
                    {form.getFieldDecorator('upd_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="录时间">
                    {form.getFieldDecorator('upd_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="记录编码">
                    {form.getFieldDecorator('rent_wh_loc_pk')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>

                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

RentWarehouseLocationDetail = Form.create({})(RentWarehouseLocationDetail);
export default RentWarehouseLocationDetail;
