import React, {Fragment, PureComponent} from 'react';
import {Radio, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class XxStockInfoList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listWh: [],
    listOwnr: [],
    listSts: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {wh_cd, ownr_id, stock_in_sts_cd, stock_in_dt, item_nm, stock_in_num} = state;
        form.setFieldsValue({wh_cd, ownr_id, stock_in_sts_cd, stock_in_dt, item_nm, stock_in_num});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionNew(){
    if(x1.user.hasRole(x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR)){
      return true;
    }
    return false;
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN, x1.ROLE.FIN, x1.ROLE.MGR)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/xxWh/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      item_list_desc_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
          offset: pageSize * curPage,
          limit: pageSize,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/XxStockInfoDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/XxStockInfoDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/xxWh/delete",
      xx_wh_pk: record.xx_wh_pk,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };


  columns = [
    {title: '仓库名称', dataIndex: 'wh_num'},
    {title: '仓库地址', dataIndex: 'wh_addr'},
    {title: '仓库面积', dataIndex: 'wh_area_amt'},
    {title: '仓库容积', dataIndex: 'wh_vol_amt'},
    {title: '仓库类型', dataIndex: 'wh_tp_nm'},

    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          {this.hasFunctionDelete() &&
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          }
          {this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除仓库【" + record.wh_num + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'}/>
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
                <FormItem {...formItemLayout} label="名称">
                    {form.getFieldDecorator('wh_num_like', {
                    })(
                        <Input style={{width: '150px'}} allowClear={true}/>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="地址">
                    {form.getFieldDecorator('wh_addr_like', {
                    })(
                        <Input style={{width: '150px'}} allowClear={true}/>
                    )}
                </FormItem>

              <FormItem {...formItemLayout} label="类型">{form.getFieldDecorator(`stock_in_sts_cd`, {initialValue:'', rules: [{}]})(
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="">全部</Radio.Button>
                  <Radio.Button value="冷藏库">冷藏库</Radio.Button>
                  <Radio.Button value="冷冻库">冷冻库</Radio.Button>
                  <Radio.Button value="成品库">成品库</Radio.Button>
                </Radio.Group>
              )}</FormItem>
            </div>
            <div style={{marginBottom:'16px', display:'flex', justifyContent:'flex-end'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              {this.hasFunctionNew() &&
              <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
              }
            </div>
          </Form>
          <Table
            rowKey={'stock_in_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

XxStockInfoList = Form.create({})(XxStockInfoList);
export default XxStockInfoList;
