import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, DatePicker, Radio} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class ReportManagerOvertimeDetail extends PureComponent {
  state = {
    wh_mgr_usr_id: '',
    loading: false,
    loadingDownload: false,
    pagination: {},
    listItem: [],
    smryData: {},
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {from_rpt_dt, to_rpt_dt} = state;
        form.setFieldsValue({from_rpt_dt, to_rpt_dt});
        this.refreshPage();
      }
    }
    if(!isBack) {
      this.initFromNoneBack();
    }
  };
  
  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initFromNoneBack(){
    const {form} = this.props;
    if(x1.report.hasConditionDate()){
      // 取上个画面的区间
      x1.report.loadConditionDate(this);
    } else {
      // 默认期间3个月
      var toDt = x1.getCurrentMoment();
      var fromDt = x1.getCurrentMoment().subtract(3, 'months');
      form.setFieldsValue({
        from_rpt_dt: fromDt,
        to_rpt_dt: toDt,
      });
    }
    this.initSearch();
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 50);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    const params = {
      apiUrl: "/api/mgrWorkOver/summaryByManagerDate",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      wh_mgr_usr_id: x1.router.getParamIn().wh_mgr_usr_id,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  // handleDownload = e => {
  //   var that = this;
  //   const {form} = this.props;
  //   var values = form.getFieldsValue();
  //
  //   const params = {
  //     apiUrl: "/api/report/downloadReportByLocation",
  //     ...values,
  //     success: (data)=>{
  //       x1.download(data.file_url);
  //     },
  //     complete: ()=>{
  //       that.setState({loadingDownload: false});
  //     }
  //   };
  //   that.setState({loadingDownload: true});
  //   x1.request(params);
  // };

  onChangeDate(){
    setTimeout(()=>{
      const {form} = this.props;
      var values = form.getFieldsValue();
      x1.report.saveConditionDate(values.from_rpt_dt, values.to_rpt_dt);
    },1);
  }

  columns = [
    {title:'保管员',dataIndex:'wh_mgr_usr_nm'},
    {title:'日期',dataIndex:'trx_dt',
      render: (text, record, index) => (
          <div>{x1.date2Text(record.trx_dt)}</div>
      )
    },
    {title: '工时(H)', dataIndex: 'work_hr_amt'},
    {title: '费用(元)', dataIndex: 'work_fee_amt'},
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
        <BasicLayout>
          <Card bordered={false}>
            <Form layout={'inline'} style={{marginBottom: '16px'}}>
              <div>
                <FormItem {...formItemLayout} label="开始日期">
                  {form.getFieldDecorator('from_rpt_dt')(
                      <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="-" colon={false}>
                  {form.getFieldDecorator('to_rpt_dt')(
                      <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                  )}
                </FormItem>
              </div>
              <div style={{display:'flex', justifyContent:'flex-end', marginTop:'8px'}}>
                <Button type="primary" onClick={this.handleSearch}>查询</Button>
                {/*<span style={{paddingRight:'16px'}}></span>*/}
                {/*<Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>*/}
              </div>
            </Form>

            <Table
                // rowKey={'work_dt'}
                loading={this.state.loading}
                dataSource={this.state.listItem}
                pagination={this.state.pagination}
                columns={this.columns}
                onChange={this.handleStandardTableChange}
                size="middle"
            />
          </Card>
        </BasicLayout>

    );
  };
}

ReportManagerOvertimeDetail = Form.create({})(ReportManagerOvertimeDetail);
export default ReportManagerOvertimeDetail;
