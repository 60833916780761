import React, {Fragment, PureComponent} from 'react';
import {Modal, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import OtherAmountEntryPopup from "./OtherAmountEntryPopup";

const FormItem = Form.Item;
const {Option} = Select;

class InvoiceWaitList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listInvty: [],
    listWh: [],
    listOwnr: [],
    isShowPopup: false,
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {wh_cd, ownr_id, item_nm, stock_in_dt} = state;
        form.setFieldsValue({wh_cd, ownr_id, item_nm, stock_in_dt});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initWarehouseList();
      this.initOwnerList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionFinance(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN)){
      return true;
    }
    return false;
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      invty_flg: 'Y',
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      // invty_flg: 'Y',
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/inventory/listByStockIn",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      wait_for_inv_flg: 'Y',
      success: (data) => {
        this.setState({
          listInvty: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  openDetail(record) {
    x1.router.push('/InvoiceWaitDetail', record, x1.getStateSnapshort(this));
  };

  // editOtherAmount(record) {
  //   this.state.selIndex = record.index;
  //   this.setState({isShowPopup: true});
  // }
  //
  // onSubmitEditOtherAmount(input){
  //   this.setState({isShowPopup: false});
  //   const params = {
  //     loading: true,
  //     apiUrl: "/api/stockIn/editOtherAmount",
  //     stock_in_num: input.stock_in_num,
  //     stock_in_line_num: input.rcv_pmt_amtstock_in_line_num,
  //     oth_amt: input.oth_amt,
  //     oth_amt_memo_txt: input.oth_amt_memo_txt,
  //     usr_id: x1.user.getCurrentUserId(),
  //     success: (data) => {
  //       this.initSearch();
  //       x1.showMessage('提交成功');
  //     },
  //     complete: () => {
  //       this.setState({loading: false});
  //     }
  //   };
  //   this.setState({loading: true});
  //   x1.request(params);
  // };
  //
  // handleCancelPopup(){
  //   this.setState({isShowPopup: false});
  // }

  columns = [
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '商品', dataIndex: 'item_nm'},
    {title: '入库日期', dataIndex: 'stock_in_dt_txt'},
    {title: '入库数量', dataIndex: 'stock_in_qty'},
    {title: '出库数量', dataIndex: 'stock_out_qty'},
    {title: '库存数量', dataIndex: 'invty_qty'},
    {title: '最后出库日期', dataIndex: 'last_stock_out_dt_txt'},
    {title: '最后出库数量', dataIndex: 'last_stock_out_qty'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <div>
            <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>账单详情</a>
          </div>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="仓库">
              {form.getFieldDecorator('wh_cd')(
                <Select style={{width: '100px'}} allowClear={true}>
                  {this.state.listWh.map((item) => (
                    <Option key={item.wh_cd}>{item.wh_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="货主">
              {form.getFieldDecorator('ownr_id')(
                <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.state.listOwnr.map((item) => (
                    <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="商品">
              {form.getFieldDecorator('item_nm', {
              })(
                <Input style={{width: '130px'}} allowClear={true}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="入库日期">
              {form.getFieldDecorator('stock_in_dt', {
              })(
                <DatePicker style={{width: '130px'}} allowClear={true}/>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
          </Form>
          <Table
            // rowKey={'stock_in_line_full_num'}
            loading={this.state.loading}
            dataSource={this.state.listInvty}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>

      </BasicLayout>

    );
  };
}

InvoiceWaitList = Form.create({})(InvoiceWaitList);
export default InvoiceWaitList;
