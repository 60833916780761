import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider, Radio} from 'antd';
import {message} from "antd/lib/index";
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;


class OtherAmountEntryPopup extends PureComponent {
  state = {
  };

  componentDidMount() {
    const {form} = this.props;
    form.setFieldsValue({
      oth_amt: this.props.inv.oth_amt,
      oth_amt_memo_txt: this.props.inv.oth_amt_memo_txt,
    });
  };

  handleSubmit= e  => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      var param = {
        stock_in_num: this.props.inv.stock_in_num,
        stock_in_line_num: this.props.inv.stock_in_line_num,
        oth_amt: fieldsValue.oth_amt,
        oth_amt_memo_txt: fieldsValue.oth_amt_memo_txt,
      }
      this.props.onSubmit(param);
    });
  }

  render() {
    const {form} = this.props;
    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };
    return (
      <div>
        <Form style={{marginBottom: '16px'}}>
          <FormItem {...formItemLayout} label="其它收款">
            {form.getFieldDecorator('oth_amt', {
              rules: [{
                required: true,
                message: '请输入其它收款',
              }]
            })(
              <Input style={{width: '150px'}}/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="备注">
            {form.getFieldDecorator('oth_amt_memo_txt', {
              rules: [{
                required: true,
                message: '请输入备注',
              }]
            })(
              <Input style={{width: '300px'}}/>
            )}
          </FormItem>
          <FormItem {...submitItemLayout}>
            <Button type="primary" onClick={this.handleSubmit}>提交</Button>
          </FormItem>
        </Form>
      </div>
    );
  };
}

OtherAmountEntryPopup = Form.create({})(OtherAmountEntryPopup);
export default OtherAmountEntryPopup;
