import React, {Fragment, PureComponent} from 'react';
import {Radio, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const {MonthPicker} = DatePicker;

class TemporaryJobList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listWh: [],
    listOwnr: [],
    listSts: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {temp_job_ts, temp_job_num, temp_job_nm, ownr_nm, item_nm} = state;
        form.setFieldsValue({temp_job_ts, temp_job_num, temp_job_nm, ownr_nm, item_nm});
        this.refreshPage();
      }
    }

    if(!isBack) {
      form.setFieldsValue({
        temp_job_ts: x1.getSystemDate(),
      });
      this.initSearch();
      this.initWarehouseList();
      this.initOwnerList();
      this.initStatusList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionNew(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.MGR) || x1.user.hasRole(x1.ROLE.ORD)){
      return true;
    }
    return false;
  }

  // hasFunctionDeleteAfterSubmit(){
  //   if(x1.user.hasRole(x1.ROLE.ADMIN)){
  //     return true;
  //   }
  //   return false;
  // }
  //
  // hasFunctionDeleteAfterConfirm(){
  //   if(x1.user.hasRole(x1.ROLE.ADMIN)){
  //     return true;
  //   }
  //   return false;
  // }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initStatusList() {
    const params = {
      apiUrl: "/api/stockInStatus/list",
      success: (data) => {
        this.setState({
          listSts: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    const temp_job_ts = x1.timeStamp2Text(values.temp_job_ts).substr(0,10);
    values.temp_job_ts = temp_job_ts;
    const params = {
      apiUrl: "/api/tempJob/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/TemporaryJobDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/TemporaryJobDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/tempJob/delete",
      temp_job_num: record.temp_job_num,
      admin_flg: x1.user.hasRole(x1.ROLE.ADMIN) ? 'Y' : '',
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  columns = [
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '工作单号', dataIndex: 'temp_job_num'},
    {title: '工作时间', dataIndex: 'temp_job_ts'},
    {title: '工作名称', dataIndex: 'temp_job_nm'},
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '商品', dataIndex: 'item_nm'},
    {title: '重量 Kg/件', dataIndex: 'unit_kg_amt'},
    {title: '件数', dataIndex: 'pkg_amt'},
    {title: '总重量/吨', dataIndex: 'tnng_amt'},
    {title: '总价', dataIndex: 'tot_prc_amt'},
    {title: '附注', dataIndex: 'job_memo_txt'},
    {title: '更新人', dataIndex: 'upd_usr_nm'},
    {title: '更新状态', dataIndex: 'temp_job_sts_nm'},
    {title: '更新时间', dataIndex: 'upd_ts'},
    {title: '保管员', dataIndex: 'wh_mgr_usr_nm'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          <Popconfirm title={"确定要删除工作单【" + record.temp_job_num + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'}/>
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="日期">
                {form.getFieldDecorator('temp_job_ts', {
                })(
                    <DatePicker style={{width: '130px'}}  allowClear={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="月份">
                {form.getFieldDecorator('temp_job_mth', {
                })(<MonthPicker style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="工作单号">
                {form.getFieldDecorator('temp_job_num', {
                })(
                    <Input style={{width: '150px'}} allowClear={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="工作名称">
                {form.getFieldDecorator('temp_job_nm', {
                })(
                    <Input style={{width: '100px'}} allowClear={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="货主">
                {form.getFieldDecorator('ownr_nm', {
                })(
                    <Input style={{width: '100px'}} allowClear={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="商品">
                {form.getFieldDecorator('item_nm', {
                })(
                  <Input style={{width: '100px'}} allowClear={true}/>
                )}
              </FormItem>
            </div>
            <div style={{marginBottom:'16px', display:'flex', justifyContent:'flex-end'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              {this.hasFunctionNew() &&
              <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
              }
            </div>
          </Form>
          <Table
            rowKey={'temp_job_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

TemporaryJobList = Form.create({})(TemporaryJobList);
export default TemporaryJobList;
