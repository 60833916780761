import React, {Fragment, PureComponent} from 'react';
import {Radio, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class StockInOtherExpenseList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listWh: [],
    listOwnr: [],
    listSts: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {wh_cd, ownr_id, stock_in_dt, stock_in_oth_exp_sts_cd} = state;
        form.setFieldsValue({wh_cd, ownr_id, stock_in_dt, stock_in_oth_exp_sts_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      form.setFieldsValue({stock_in_dt:x1.getSystemDate()});
      this.initSearch();
      this.initWarehouseList();
      this.initOwnerList();
      this.initStatusList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDeleteAfterSubmit(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  hasFunctionDeleteAfterConfirm(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initStatusList() {
    const params = {
      apiUrl: "/api/stockInStatus/list",
      success: (data) => {
        this.setState({
          listSts: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/stockIn/listByLine",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      item_list_desc_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  markNoOtherAmount(record) {
    const params = {
      loading: true,
      apiUrl: "/api/stockIn/markNoOtherAmount",
      stock_in_num: record.stock_in_num,
      stock_in_line_num: record.stock_in_line_num,
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        this.refreshPage();
      },
    };
    x1.request(params);
  };

  openDetail(record) {
    x1.router.push('/InvoiceWaitDetail', record, x1.getStateSnapshort(this));
  };

  disableLine(record) {
  };

  columns = [
    {title: '入库单号', dataIndex: 'stock_in_num'},
    {title: '明细号', dataIndex: 'stock_in_line_num'},
    {title: '入库日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.stock_in_dt)}</div>
      )
    },
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '商品', dataIndex: 'item_desc'},
    {title: '入库件数', dataIndex: 'stock_in_qty'},
    {title: '入库吨位', dataIndex: 'stock_in_tnng_amt'},
    {title: '货主备注', dataIndex: 'ownr_memo_txt'},
    {title: '内部备注', dataIndex: 'stock_in_memo_txt'},
    {title: '入库提交人', dataIndex: 'sbmt_usr_nm'},
    {title: '入库提交时间', dataIndex: 'sbmt_ts'},
    {title: '其它费用', dataIndex: 'oth_amt'},
    {title: '费用提交人', dataIndex: 'oth_amt_entry_usr_nm'},
    {title: '费用提交时间', dataIndex: 'oth_amt_entry_ts'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <div><a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>{x1.isNull(record.oth_amt)?'费用输入':'费用编辑'}</a></div>
          {x1.isNull(record.oth_amt) &&
          <div><a onClick={() => this.markNoOtherAmount(record)} style={{marginRight: '10px'}}>无需其他费用</a></div>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="仓库">
                {form.getFieldDecorator('wh_cd')(
                  <Select style={{width: '100px'}} allowClear={true}>
                    {this.state.listWh.map((item) => (
                      <Option key={item.wh_cd}>{item.wh_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="货主">
                {form.getFieldDecorator('ownr_id')(
                  <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {this.state.listOwnr.map((item) => (
                      <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              {/*<FormItem {...formItemLayout} label="入库日期">*/}
                {/*{form.getFieldDecorator('stock_in_dt', {*/}
                {/*})(*/}
                  {/*<DatePicker style={{width: '130px'}} allowClear={true}/>*/}
                {/*)}*/}
              {/*</FormItem>*/}
              <FormItem {...formItemLayout} label="入库日期">
                {form.getFieldDecorator('from_stock_in_dt', {
                })(
                  <DatePicker style={{width: '130px'}} allowClear={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="-" colon={false}>
                {form.getFieldDecorator('to_stock_in_dt', {
                })(
                  <DatePicker style={{width: '130px'}} allowClear={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="状态">{form.getFieldDecorator(`stock_in_oth_exp_sts_cd`, {initialValue:'*', rules: [{}]})(
                <Radio.Group buttonStyle="solid" onChange={()=>this.initSearch()}>
                  <Radio.Button value="*">全部</Radio.Button>
                  <Radio.Button value="D">有其他费用</Radio.Button>
                  <Radio.Button value="U">无其它费用</Radio.Button>
                  <Radio.Button value="W">待确认</Radio.Button>
                </Radio.Group>
              )}</FormItem>
            </div>
            <div style={{marginBottom:'16px', display:'flex', justifyContent:'flex-end'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
            </div>
          </Form>
          <Table
            // rowKey={'stock_in_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

StockInOtherExpenseList = Form.create({})(StockInOtherExpenseList);
export default StockInOtherExpenseList;
