import React, {Fragment, PureComponent} from 'react';
import {Modal, Checkbox, ConfigProvider, Table, Popconfirm, DatePicker, Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';
import StockInDetailPriceEntryPopup from "./StockInDetailPriceEntryPopup";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class TemporaryJobDetail extends PureComponent {
  state = {
    loading: false,
    loadingSubmit: false,
    loadingConfirm: false,
    loadingReject: false,
    loadingPrint: false,
    loadingTime: false,

    listLine: [],
    listWh: [],
    listOwnr: [],
    listWhMgrUsr: [],
    listItem: [],
    listTempJobType: [],
    listEmp: [],
    listWhLoc: [],
    checkedItem: false,
    checkedPmt: false,
    unit_prc_amt: '',
    isShowPopupPrice: false,
  };

  componentDidMount() {
    const {form} = this.props;
    var temp_job_num = x1.router.getParamIn().temp_job_num;
    if (temp_job_num > '') {
      // 更新
      form.setFieldsValue({temp_job_num: temp_job_num});
      this.search(temp_job_num);
    } else {
      // 新增
      form.setFieldsValue({
        temp_job_dt: x1.getSystemDate(),
        temp_job_tm: x1.getCurrentMoment()
      });
      // this.initJobDefaultPrcList();
    }
    this.initTempJobTypeList();
    this.initWarehouseList();
    this.initOwnerList();
    this.initItemList();
    this.initWhMgrUsrList();
    this.initEmpList();
  };

  hasFunctionUpdateBaseHead(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.MGR) || x1.user.hasRole(x1.ROLE.ORD)){
      return true;
    }
    return false;
  }

  hasFunctionSubmit(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.MGR) || x1.user.hasRole(x1.ROLE.ORD)){
      return true;
    }
    return false;
  }

  hasFunctionConfirm(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.MGR) || x1.user.hasRole(x1.ROLE.ORD)){
      return true;
    }
    return false;
  }

  hasFunctionConfirmReject(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  // hasFunctionChangePrice(){
  //   if(x1.user.hasRole(x1.ROLE.ADMIN)){
  //     return true;
  //   }
  //   return false;
  // }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseLocationList() {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(x1.isNull(values.wh_cd)){
      this.setState({listWhLoc:[]});
      return;
    }
    const params = {
      apiUrl: "/api/warehouseLocation/list",
      wh_cd: values.wh_cd,
      success: (data) => {
        this.setState({
          listWhLoc: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  // initEmpList(temp_job_tp_cd) {
  initEmpList() {
    // const {form} = this.props;
    // var values = form.getFieldsValue();
    // if(x1.isNull(temp_job_tp_cd)){
    //   this.setState({listEmp:[]});
    //   return;
    // }
    const params = {
      apiUrl: "/api/tempEmp/listByTp",
      // temp_job_tp_cd: temp_job_tp_cd,
      success: (data) => {
        this.setState({
          listEmp: data,
        });
      },
    };
    x1.request(params);
  };

  getEmployeeListByJobType(temp_job_tp_cd){
    var list = [];
    for(var i=0; i<this.state.listEmp.length; i++){
      var emp = this.state.listEmp[i];
      if(emp.temp_job_tp_cd == temp_job_tp_cd){
        list.push(emp);
      }
    }
    return list;
  }

  initTempJobTypeList() {
    const {form} = this.props;
    const params = {
      apiUrl: "/api/tempJobTp/list",
      success: (data) => {
        this.setState({
          listTempJobType: data,
        });
      },
    };
    x1.request(params);
  };

  // initJobDefaultPrcList() {
  //   const {form} = this.props;
  //   const params = {
  //     apiUrl: "/api/tempJobTp/list",
  //     success: (data) => {
  //       console.log(data);
  //       for(var i=0; i<data.length; i++){
  //         switch(data[i].temp_job_tp_cd){
  //           case 'LD': form.setFieldsValue({load_unit_ton_prc_amt: data[i].unit_prc_amt});break;
  //           case 'UL': form.setFieldsValue({unload_unit_ton_prc_amt: data[i].unit_prc_amt});break;
  //           case 'TR': form.setFieldsValue({trnsf_unit_ton_prc_amt: data[i].unit_prc_amt});break;
  //           case 'SH': form.setFieldsValue({ship_unit_car_prc_amt: data[i].unit_prc_amt});break;
  //           default: ;
  //         }
  //       }
  //     },
  //   };
  //   x1.request(params);
  // };

  onChanageTempJobType(index, temp_job_tp_cd) {
    const {form} = this.props;
    var listLine = this.state.listLine;
    var line = listLine[index];
    line.temp_job_tp_cd = temp_job_tp_cd;
    this.setState({listLine: [...listLine]});
    // form.setFieldsValue({'temp_job_tp_cd': temp_job_tp_cd});
    // this.initEmpList(temp_job_tp_cd);
  }

  initWhMgrUsrList() {
    const params = {
      apiUrl: "/api/user/list",
      usr_role_cd: 'R004',
      success: (data) => {
        this.setState({
          listWhMgrUsr: data,
        });
      },
    };
    x1.request(params);
  }

  initItemList() {
    const params = {
      apiUrl: "/api/item/list",
      success: (data) => {
        this.setState({
          listItem: data,
        });
      },
    };
    x1.request(params);
  };

  onChanageWarehouse(wh_cd){
    const {form} = this.props;
    form.setFieldsValue({'wh_cd': wh_cd});
    this.initWarehouseLocationList();
  };

  handleSave = e => {
    var sbmt_flg = 'N';
    this.submit(sbmt_flg);
  }

  handleSubmit = e => {
    var sbmt_flg = 'Y';
    this.submit(sbmt_flg);
  }

  handleConfirm = e => {
    this.confirm();
  }

  handleReject = e => {
    this.reject();
  }

  handlePrint(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    const params = {
      apiUrl: "/api/tempJob/print",
      temp_job_num: fieldsValue.temp_job_num,
      usr_id: x1.user.getCurrentUserId(),
      usr_nm: x1.user.getCurrentUser().usr_nm,
      success: (data) => {
        x1.showMessage('提交成功');
        x1.download(data.file_url);
      },
      complete:()=>{
        this.setState({
          loadingPrint:false,
        });
      }
    };
    this.setState({
      loadingPrint:true,
    });
    x1.request(params);
  }

  //计算总价
  calculateTotalPrice() {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const custLoadPrc = (x1.isNull(values.cust_load_unit_ton_prc_amt))? 0:values.cust_load_unit_ton_prc_amt*1000;
    const custUnloadPrc = (x1.isNull(values.cust_unload_unit_ton_prc_amt))? 0:values.cust_unload_unit_ton_prc_amt*1000;
    const custShipPrc = (x1.isNull(values.cust_ship_unit_car_prc_amt))? 0:values.cust_ship_unit_car_prc_amt*1000;

    const loadPrc = (x1.isNull(values.load_unit_ton_prc_amt))? 0:values.load_unit_ton_prc_amt*1000;
    const unloadPrc = (x1.isNull(values.unload_unit_ton_prc_amt))? 0:values.unload_unit_ton_prc_amt*1000;
    const shipPrc = (x1.isNull(values.ship_unit_car_prc_amt))? 0:values.ship_unit_car_prc_amt*1000;
    const transferPrc = (x1.isNull(values.trnsf_unit_ton_prc_amt))? 0:values.trnsf_unit_ton_prc_amt*1000;
    const carTimes = (x1.isNull(values.ship_times_amt))? 0:values.ship_times_amt;
    const weight = (x1.isNull(values.tnng_amt))? 0:values.tnng_amt*1000;

    const custTotalPrc = [(custLoadPrc+custUnloadPrc)*weight]/1000000 + (custShipPrc*carTimes)/1000;
    const loadTotalPrc = [(loadPrc+unloadPrc+transferPrc)*weight]/1000000;
    const shipTotalPrc = (shipPrc*carTimes)/1000;

    form.setFieldsValue({tot_prc_amt: custTotalPrc.toFixed(2)});
    form.setFieldsValue({load_tot_prc_amt: loadTotalPrc.toFixed(2)});
    form.setFieldsValue({ship_tot_prc_amt: shipTotalPrc.toFixed(2)});
  };

  confirm() {
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    if(!this.state.checkedItem){
        x1.showError('请确认临时工作信息正确');
        return false;
    }
    // 检查是否有明细单价
    for(var i=0; i<this.state.listLine.length; i++){
      var line = this.state.listLine[i];
      if(x1.isNull(line.temp_job_tp_cd) || x1.isNull(line.temp_emp_id)){
        x1.showError('请先点击【新增人员】录入人员信息');
        return false;
      }
    }
    const params = {
      apiUrl: "/api/tempJob/confirm",
      temp_job_num: fieldsValue.temp_job_num,
      usr_id: x1.user.getCurrentUserId(),
      job_memo_txt: fieldsValue.job_memo_txt,
      success: (data) => {
        x1.showMessage('提交成功');
        this.search(fieldsValue.temp_job_num);
      },
      complete:()=>{
        this.setState({
          loadingConfirm:false,
        });
      }
    };
    this.setState({
      loadingConfirm:true,
    });
    x1.request(params);
  };

  reject() {
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    const params = {
      apiUrl: "/api/tempJob/reject",
      temp_job_num: fieldsValue.temp_job_num,
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        x1.showMessage('提交成功');
        this.search(fieldsValue.temp_job_num);
      },
      complete:()=>{
        this.setState({
          loadingReject:false,
        });
      }
    };
    this.setState({
      loadingReject:true,
    });
    x1.request(params);
  };

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();

    // 根据输入的工作类型来定必须输入的单价
    var listLine = x1.mergeDeep(this.state.listLine, fieldsValue.listLine);
    for(var i=0; i<listLine.length; i++){
      var line = listLine[i];
      if(line.temp_job_tp_cd == 'LD'){
        if(x1.isNull(fieldsValue.cust_load_unit_ton_prc_amt)){
          x1.showError('请输入客户装车单价');
          return false;
        }
        if(x1.isNull(fieldsValue.load_unit_ton_prc_amt)){
          x1.showError('请输入搬运工装车单价');
          return false;
        }
      } else if(line.temp_job_tp_cd == 'UL'){
        if(x1.isNull(fieldsValue.cust_unload_unit_ton_prc_amt)){
          x1.showError('请输入客户卸车单价');
          return false;
        }
        if(x1.isNull(fieldsValue.unload_unit_ton_prc_amt)){
          x1.showError('请输入搬运工卸车单价');
          return false;
        }
      } else if(line.temp_job_tp_cd == 'SH'){
        if(x1.isNull(fieldsValue.cust_ship_unit_car_prc_amt)){
          x1.showError('请输入客户运输单价');
          return false;
        }
        if(x1.isNull(fieldsValue.ship_unit_car_prc_amt)){
          x1.showError('请输入司机运输单价');
          return false;
        }
      } else if(line.temp_job_tp_cd == 'TR'){
        if(x1.isNull(fieldsValue.trnsf_unit_ton_prc_amt)){
          x1.showError('请输入搬运工移库单价');
          return false;
        }
      }
    }

    // 同一工作类型选择“所有员工”后，不能再选个别员工；员工重复
    var mapJobTp = {};
    for(var i=0; i<listLine.length; i++) {
      var line = listLine[i];
      var list = mapJobTp[line.temp_job_tp_cd];
      line.row_num = (i+1);
      if(list == null){
        list = [];
      }
      list.push(line);
      mapJobTp[line.temp_job_tp_cd] = list;
    }
    for(var key in mapJobTp){
      var listLine = mapJobTp[key];
      for(var i=0; i<listLine.length; i++){
        for(var j=i+1; j<listLine.length; j++){
          if(listLine[i].temp_emp_id == listLine[j].temp_emp_id){
            x1.showError('第【' + listLine[i].row_num + '】行和第【'+ listLine[j].row_num + '】行的员工重复');
            return false;
          }
        }
      }
      for(var i=0; i<listLine.length; i++){
        var line = listLine[i];
        if(line.temp_emp_id == '*' && listLine.length > 1){
          x1.showError('第【' + listLine[i].row_num + '】行选了所有员工后不能再选个别员工');
          return false;
        }
      }
    }

    return true;
  }

  submit(sbmt_flg) {
    this.calculateTotalPrice();
    const {form} = this.props;
    var values = form.getFieldsValue();
    const url = (values.temp_job_num > '')? "/api/tempJob/update":"/api/tempJob/insert";
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: url,
        ...fieldsValue,
        listLine: x1.mergeDeep(this.state.listLine, fieldsValue.listLine),
        upd_usr_id: x1.user.getCurrentUserId(),
        usr_id: x1.user.getCurrentUserId(),
        sbmt_flg: sbmt_flg,
        success: (data) => {
          x1.showMessage('提交成功');
          this.search(data.temp_job_num);
        },
        complete:()=>{
          this.setState({
            loadingSubmit:false,
            sbmt_flg: sbmt_flg,
          });
        }
      };
      this.setState({
        loadingSubmit:true,
        sbmt_flg: sbmt_flg,
      });
      x1.request(params);
    });
  };

  search(temp_job_num) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/tempJob/list",
      temp_job_num: temp_job_num,
      success: (data) => {
        form.setFieldsValue(data[0]);
        this.setState(data[0], ()=>{
          this.calculateTotalPrice();
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  removeLine(index){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listLine = x1.mergeDeep(this.state.listLine, formValues.listLine);
    listLine.splice(index, 1);
    this.setState({listLine:listLine});
    form.setFieldsValue({listLine:listLine});
  };

  newLine(){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listLine = x1.mergeDeep(this.state.listLine, formValues.listLine);
    //放入一条空数据
    listLine.push({});
    this.setState({listLine: listLine});
    form.setFieldsValue({listLine: listLine});
  };

  isTempJobConfirm(){
    if(this.state.temp_job_sts_cd === 'CP'){
      return true;
    }
    return false;
  }

  isConfirm(){
    if(this.state.temp_job_sts_cd === 'SU' || this.state.temp_job_sts_cd === 'CP'){
      return true;
    }
    return false;
  }

  onChangeCheckboxItem = e => {
    this.setState({
      checkedItem: e.target.checked,
    });
  };

  onChangeCheckboxPayment = e => {
    this.setState({
      checkedPmt: e.target.checked,
    });
  };

  onChangeRadioPayment = e => {
    this.setState({
      pre_paid_load_tp_cd: e.target.value,
    });
  };

  // -------------------------------
  // 编辑单价
  // -------------------------------
  // onSubmitPrice(input){
  //   const {form} = this.props;
  //   var values = form.getFieldsValue();
  //   this.setState({isShowPopupPrice: false});
  //   var stockInLine = input.stockInLine;
  //
  //   var api = '';
  //   if(stockInLine.prc_tp_cd == 'preCool'){
  //     api = "/api/stockIn/updateLinePreCoolPrice";
  //   } else if(stockInLine.prc_tp_cd == 'store'){
  //     api = "/api/stockIn/updateLineStorePrice";
  //   }
  //   if(!x1.hasValue(api)){
  //     x1.showError('价格类型不正确');
  //     return;
  //   }
  //
  //   const params = {
  //     loading: true,
  //     apiUrl: api,
  //     stock_in_num: stockInLine.stock_in_num,
  //     stock_in_line_num: stockInLine.stock_in_line_num,
  //     pre_cool_unit_prc_amt: input.unit_prc_amt,
  //     store_unit_prc_amt: input.unit_prc_amt,
  //     usr_id: x1.user.getCurrentUserId(),
  //     success: (data) => {
  //       this.search(stockInLine.stock_in_num);
  //       x1.showMessage('提交成功');
  //     },
  //   };
  //   x1.request(params);
  // };
  //
  // openPopupPrice(record, prc_tp_cd) {
  //   var param = {...record, prc_tp_cd:prc_tp_cd};
  //   if(prc_tp_cd == 'preCool'){
  //     param.unit_prc_amt = record.pre_cool_unit_prc_amt;
  //   } else if(prc_tp_cd == 'store'){
  //     param.unit_prc_amt = record.store_unit_prc_amt;
  //   }
  //   this.setState({selStockInLine: param}, ()=>{
  //     this.setState({isShowPopupPrice: true});
  //   });
  // }
  //
  // cancelPopupPrice(){
  //   this.setState({isShowPopupPrice: false});
  // }

  render() {
    const {form} = this.props;

    const rowInputStyle = {
      marginBottom: '0'
    };

    var customizeRenderEmpty = () => {
        return <div>没有数据</div>
      }
    ;

    // ------------------------
    // 站点
    // ------------------------
    const columnsLine = [
      {title: '工作明细号', dataIndex: 'temp_job_line_num'},
      {title: '工作类型', dataIndex: 'temp_job_tp_cd',
        render: (text, record, index) => {
          return (
              <FormItem style={rowInputStyle}>
                {
                  form.getFieldDecorator(
                      `listLine[${index}].temp_job_tp_cd`,
                      {rules: [{required: true, message:'请输入工作类型'}], initialValue: text}
                  )
                  (
                      <Select style={{width: '150px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              disabled={this.isConfirm()}
                              onChange={(value)=>this.onChanageTempJobType(index, value)}
                      >
                        {this.state.listTempJobType.map((item) => (
                            <Option key={item.temp_job_tp_cd}>{item.temp_job_tp_nm}</Option>
                        ))}
                      </Select>
                  )
                }
              </FormItem>
          );
        },
      },
      {title: '员工姓名', dataIndex: 'temp_emp_id',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listLine[${index}].temp_emp_id`, {
              rules: [{required: true, message:'请输入员工姓名'}],
              initialValue: text
            })(
              <Select style={{minWidth: '150px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      disabled={this.isConfirm()}
              >
                {this.getEmployeeListByJobType(record.temp_job_tp_cd).map((item) => (
                  <Option key={item.temp_emp_id}>{item.temp_emp_nm}</Option>
                ))}
              </Select>
            )}</FormItem>
          );
        },
      },
      {title: '操作', key: 'action',
        render: (text, record, index) => {
          return (
            <span>
              {!this.isConfirm() &&
              <Fragment>
                <Popconfirm title="是否要删除此行？" onConfirm={() => this.removeLine(index)}>
                  <a>删除</a>
                </Popconfirm>
              </Fragment>
              }
            </span>
          );
        },
      },
    ];

    const formItemLayoutAmount = {
      labelCol: {span: 12},
      wrapperCol: {span: 12},
    };
    const formItemLayout = {
      labelCol: {span: 10},
      wrapperCol: {span: 14},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 10},
    };

    return (
      <BasicLayout>
        <Card bordered={false} className={'TemporaryJobDetail'}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={false}>

              <Row gutter={24} className={'TemporaryJobDetail-head'}>
                <Col span={4}>
                  <FormItem {...formItemLayout} label="仓库">
                    {form.getFieldDecorator('wh_cd', {
                      rules: [{
                        required: true,
                        message: '请输入仓库',
                      }]
                    })(
                      <Select style={{width: '100px'}} allowClear={true}>
                        {this.state.listWh.map((item) => (
                          <Option key={item.wh_cd}>{item.wh_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="工作名称">
                    {
                      form.getFieldDecorator('temp_job_nm', {rules: [{required: true, message: '请输入工作名称',}]})(
                        <Input style={{width: '150px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="工作日期">
                    {form.getFieldDecorator('temp_job_dt', {
                      rules: [{
                        required: true,
                        message: '请输入工作日期',
                      }]
                    })(
                        <DatePicker style={{width: '150px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="工作时间">
                    {form.getFieldDecorator('temp_job_tm', {
                      rules: [{
                        required: true,
                        message: '请输入工作时间',
                      }]
                    })(
                        <TimePicker style={{width: '150px'}} format={'HH:mm'} disabled={this.isConfirm()}
                        />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="货主">
                    {form.getFieldDecorator('ownr_nm', {
                      rules: [{
                        required: true,
                        message: '请输入货主',
                      }]
                    })(
                        <Input style={{width: '150px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="工作单号">
                    {form.getFieldDecorator('temp_job_num', {
                    })(
                        <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem {...formItemLayout} label="商品">
                    {form.getFieldDecorator('item_nm', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="重量Kg/件">
                    {form.getFieldDecorator('unit_kg_amt', {initialValue: '', rules: []})
                    (
                      <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="件数">
                    {form.getFieldDecorator('pkg_amt', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="总重量/吨">
                    {form.getFieldDecorator('tnng_amt', {initialValue: '', rules: [{required: true, message: '请输入总重量'}]})(
                      <Input style={{width: '100px'}} disabled={this.isConfirm()} />
                    )}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem {...formItemLayoutAmount} label="单价类型">
                      <Input style={{width: '100px'}} disabled={true} value={'客户'}/>
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="装车单价/吨">
                    {form.getFieldDecorator('cust_load_unit_ton_prc_amt', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="卸车单价/吨">
                    {form.getFieldDecorator('cust_unload_unit_ton_prc_amt', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="运输单价/车">
                    {form.getFieldDecorator('cust_ship_unit_car_prc_amt', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="移库单价/吨">
                      <Input style={{width: '100px', visibility:'hidden'}} disabled={true}/>
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="车次数量">
                    {form.getFieldDecorator('ship_times_amt', {initialValue: '', rules: []})(
                        <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="总价">
                    {form.getFieldDecorator('tot_prc_amt', {
                    })(
                        <Input style={{width: '100px'}} disabled={true}/>
                    )}
                  </FormItem>
                </Col>
                <Col span={2}>
                  <FormItem {...formItemLayoutAmount} label="">
                    <Input style={{width: '100px'}} disabled={true} value={'搬运工'}/>
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                    {form.getFieldDecorator('load_unit_ton_prc_amt', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                    {form.getFieldDecorator('unload_unit_ton_prc_amt', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                      <Input style={{width: '100px', visibility:'hidden'}} disabled={true} />
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                    {form.getFieldDecorator('trnsf_unit_ton_prc_amt', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                    <Input style={{width: '100px', visibility:'hidden'}} disabled={true} />
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                    {form.getFieldDecorator('load_tot_prc_amt', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={true}/>
                    )}
                  </FormItem>
                </Col>
                <Col span={2}>
                  <FormItem {...formItemLayoutAmount} label="">
                    <Input style={{width: '100px'}} disabled={true} value={'司机'}/>
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                    <Input style={{width: '100px', visibility:'hidden'}} disabled={true} />
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                      <Input style={{width: '100px', visibility:'hidden'}} disabled={true} />
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                    {form.getFieldDecorator('ship_unit_car_prc_amt', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={this.isConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                    <Input style={{width: '100px', visibility:'hidden'}} disabled={true} />
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                    <Input style={{width: '100px', visibility:'hidden'}} disabled={true} />
                  </FormItem>
                  <FormItem {...formItemLayoutAmount} label="">
                    {form.getFieldDecorator('ship_tot_prc_amt', {initialValue: '', rules: []})(
                      <Input style={{width: '100px'}} disabled={true}/>
                    )}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem {...formItemLayout} label="更新人">
                    {form.getFieldDecorator('upd_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="更新时间">
                    {form.getFieldDecorator('upd_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="保管员">
                    {form.getFieldDecorator('wh_mgr_usr_id', {
                      rules: [{
                        required: true,
                        message: '请输入保管员',
                      }]
                    })(
                      <Select style={{width: '150px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              disabled={this.isConfirm()  || !this.hasFunctionUpdateBaseHead()}
                      >
                        {this.state.listWhMgrUsr.map((item) => (
                          <Option key={item.usr_id}>{item.usr_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="内部备注">
                    {form.getFieldDecorator('job_memo_txt', {
                    })(
                        <Input style={{width: '150px'}} disabled={this.isTempJobConfirm()}/>
                    )}
                  </FormItem>
                  <FormItem {...submitItemLayout} label="">
                    <Button loading={this.state.loadingTime} onClick={() => this.calculateTotalPrice()} icon={'check'}
                            disabled={this.isConfirm()  || !this.hasFunctionConfirm()}
                    >计算总价</Button>
                    <div style={{lineHeight:'100%'}}>{'总价=（装车单价+卸车单价+移库单价）*总重量+运输单价*车次数量'}</div>
                    {/*<div style={{lineHeight:'100%'}}>{'画面修改后，请先保存再点击确定时间。'}</div>*/}
                  </FormItem>
                </Col>
              </Row>
              <div>
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowKey={'temp_job_line_num'}
                    columns={columnsLine}
                    dataSource={this.state.listLine}
                    pagination={false}
                    size={'middle'}
                  />
                  {!this.isConfirm() &&
                  <Button style={{marginTop: 16, marginBottom: 8}} icon="plus" onClick={() => this.newLine()}>新增人员</Button>
                  }
                </ConfigProvider>
              </div>
              <div>
                <div style={{marginTop:'32px'}}>
                  {(x1.isNull(this.state.temp_job_sts_cd) || this.state.temp_job_sts_cd == 'CR') && this.hasFunctionSubmit() &&
                  <Fragment>
                    <Button type="primary" onClick={this.handleSave} loading={this.state.loadingSubmit && this.state.sbmt_flg=='N'}>信息暂存</Button>
                    <div style={{display:'inline-block', width:'16px'}}></div>
                    <Popconfirm title={"确定要提交临时工作吗？"} onConfirm={() => this.handleSubmit()}>
                      <Button type="primary" loading={this.state.loadingSubmit && this.state.sbmt_flg=='Y'} style={{marginRight:'16px'}}>提交临时工作</Button>
                    </Popconfirm>
                  </Fragment>
                  }

                  {/*打单*/}
                  {x1.hasValue(this.state.temp_job_sts_cd) && this.state.temp_job_sts_cd != 'CR' && this.hasFunctionConfirm() &&
                  <Fragment>
                    <Button loading={this.state.loadingPrint} style={{marginRight:'16px'}} onClick={() => this.handlePrint()}>打印工作单</Button>
                  </Fragment>
                  }

                  {
                    (this.state.temp_job_sts_cd == 'SU') && this.hasFunctionConfirm() &&
                    <Fragment>
                      <Popconfirm title={"确认临时工作没有错误吗？"} onConfirm={() => this.handleConfirm()}>
                        <Button type="primary" loading={this.state.loadingConfirm} style={{marginRight:'8px'}} >工作确认</Button>
                      </Popconfirm>
                      <Checkbox checked={this.state.checkedItem} onChange={this.onChangeCheckboxItem}>{'确认临时工作信息正确'}</Checkbox>
                      <Popconfirm title={"确认撤销临时工作吗？"} onConfirm={() => this.handleReject()}>
                        <Button loading={this.state.loadingReject} style={{marginLeft:'16px'}}>驳回修改</Button>
                      </Popconfirm>
                    </Fragment>
                  }
                  {
                    (this.state.temp_job_sts_cd == 'CP') && this.hasFunctionConfirmReject() &&
                      <Fragment>
                        <Popconfirm title={"确认撤销临时工作吗？"} onConfirm={() => this.handleReject()}>
                          <Button loading={this.state.loadingReject} style={{marginLeft:'16px'}}>驳回修改</Button>
                        </Popconfirm>
                      </Fragment>
                  }
                </div>
              </div>

            </Form>
          </Spin>
        </Card>

        {/*/!*编辑单价*!/*/}
        {/*{this.state.isShowPopupPrice &&*/}
        {/*<Modal visible={true} footer={null} onCancel={()=>this.cancelPopupPrice()}>*/}
          {/*<StockInDetailPriceEntryPopup stockInLine={this.state.selStockInLine} onSubmit={(input)=>this.onSubmitPrice(input)}/>*/}
        {/*</Modal>*/}
        {/*}*/}

      </BasicLayout>
    );
  };

}

TemporaryJobDetail = Form.create({})(TemporaryJobDetail);
export default TemporaryJobDetail;
