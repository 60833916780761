import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col, Popconfirm, ConfigProvider, Table} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

/**
 * 20190803 废弃，使用WarehousePriceRangeXDetail，分日期段来设置
 */
class WarehousePriceRangeDetail extends PureComponent {
  state = {
    loading: false,
    itemInfo: {},
    listPrc:[],
  };

  componentDidMount() {
    const {form} = this.props;

    var item = x1.router.getParamIn();
    this.state.itemInfo = item;
    this.setState({
      itemInfo: item,
    });
    this.search();
  };

  hasFunctionEdit(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH) || x1.user.hasRole(x1.ROLE.GEN_MGR)){
      return true;
    }
    return false;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      const params = {
        loading: true,
        apiUrl: "/api/warehousePriceRange/updateByWarehouse",
        wh_cd: this.state.itemInfo.wh_cd,
        listPrc: x1.mergeDeep(this.state.listPrc, fieldsValue.listPrc),
        usr_id: x1.user.getCurrentUserId(),
        success: (data) => {
          form.setFieldsValue(data);
          x1.showMessage('提交成功');
          this.search();
        },
        complete: () => {
          this.setState({loading:false});
        }
      };
      this.setState({loading:true});
      x1.request(params);
    });
  };

  search() {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/warehousePriceRange/list",
      wh_cd: this.state.itemInfo.wh_cd,
      success: (data) => {
        let values = {listPrc: data};
        this.setState(values);
      },
    };
    x1.request(params);
  };

  removeLine(index){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listPrc = x1.mergeDeep(this.state.listPrc, formValues.listPrc);
    listPrc.splice(index, 1);
    this.setState({listPrc:listPrc});
  };

  newLine(){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listPrc = x1.mergeDeep(this.state.listPrc, formValues.listPrc);
    listPrc.push({});
    this.setState({listPrc:listPrc});
  };

  render() {
    const {form} = this.props;

    const rowInputStyle = {
      marginBottom: '0'
    };

    var customizeRenderEmpty = () => {
        return <div>没有数据</div>
      }
    ;

    // ------------------------
    // 站点
    // ------------------------
    const columnsStation = [
      {
        title: '开始时间', dataIndex: 'from_tm',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listPrc[${index}].from_tm`, {
              rules: [{required: true, message:'请输入开始时间'}],
              initialValue: text
            })(
              <TimePicker format={'HH:mm'} />
            )}</FormItem>
          );
        },
      },
      {
        title: '结束时间', dataIndex: 'to_tm',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listPrc[${index}].to_tm`, {
              rules: [{required: true, message:'请输入结束时间'}],
              initialValue: text
            })(
              <TimePicker format={'HH:mm'} />
            )}</FormItem>
          );
        },
      },
      {
        title: '区间搬运费单价',
        dataIndex: 'load_unit_prc_amt',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listPrc[${index}].load_unit_prc_amt`, {
              rules: [{required: true, message:'请输入区间搬运费单价'}],
              initialValue: text
            })(<Input/>)}</FormItem>
          );
        },
      },
      {
        title: '操作',
        key: 'action',
        render: (text, record, index) => {
          return (
            <span>
              <Popconfirm title="是否要删除此行？" onConfirm={() => this.removeLine(index)}>
                <a>删除</a>
              </Popconfirm>
            </span>
          );
        },
      },
    ];

    const formItemLayout = {
      labelCol: {span: 8},
      wrapperCol: {span: 16},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 8},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <div className='box_cc' style={{marginBottom:'16px'}}>
                <div>{'仓库：' + this.state.itemInfo.wh_nm}</div>
                <div style={{marginLeft:'32px'}}>{'开始日期：' + x1.date2Text(this.state.itemInfo.from_dt)}</div>
                <div style={{marginLeft:'32px'}}>{'结束日期：' + x1.date2Text(this.state.itemInfo.to_dt)}</div>
              </div>

              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowKey={'wh_prc_rng_pk'}
                  columns={columnsStation}
                  dataSource={this.state.listPrc}
                  pagination={false}
                  size={'middle'}
                />
                <Button style={{ marginTop: 16, marginBottom: 8 }} icon="plus" onClick={()=>this.newLine()}>新增价格</Button>
                <div style={{marginTop:'32px'}}>
                  {this.hasFunctionEdit() &&
                  <Button type="primary" onClick={this.handleSubmit} loading={this.state.loading}>提交</Button>
                  }
                </div>
              </ConfigProvider>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

WarehousePriceRangeDetail = Form.create({})(WarehousePriceRangeDetail);
export default WarehousePriceRangeDetail;
