import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class ItemList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listItemGrp: [],
    listProd: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {item_nm, item_grp_cd, prod_cd} = state;
        form.setFieldsValue({item_nm, item_grp_cd, prod_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initItemGroupList();
      this.initProductList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  initItemGroupList() {
    const params = {
      apiUrl: "/api/itemGroup/list",
      success: (data) => {
        this.setState({
          listItemGrp: data,
        });
      },
    };
    x1.request(params);
  };

  initProductList() {
    const params = {
      apiUrl: "/api/product/list",
      success: (data) => {
        this.setState({
          listProd: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/item/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/ItemDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/ItemDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/item/delete",
      item_cd: record.item_cd,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  columns = [
    {title: '商品编号', dataIndex: 'item_cd'},
    {title: '商品名称', dataIndex: 'item_nm'},
    {title: '分类', dataIndex: 'item_grp_nm'},
    {title: '品类', dataIndex: 'prod_nm'},
    {title: '详情', dataIndex: 'item_pkg_nm'},
    {title: '规格', dataIndex: 'item_spec_nm'},
    {title: '单位', dataIndex: 'item_unit_nm'},
    {title: '商品状态', dataIndex: 'item_sts_nm'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          {this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除商品【" + record.item_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'} />
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="商品名称" allowClear={true}>
              {form.getFieldDecorator('item_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="商品分类">
              {form.getFieldDecorator('item_grp_cd')(
                <Select style={{width: '200px'}} allowClear={true}>
                  {this.state.listItemGrp.map((item) => (
                    <Option key={item.item_grp_cd}>{item.item_grp_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="品类">
              {form.getFieldDecorator('prod_cd')(
                <Select style={{width: '200px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.state.listProd.map((item) => (
                    <Option key={item.prod_cd}>{item.prod_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
          </Form>
          <Table
            rowKey={'item_cd'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

ItemList = Form.create({})(ItemList);
export default ItemList;
