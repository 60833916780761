import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select,DatePicker, TimePicker, Spin} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class TemporaryEmployeeDetail extends PureComponent {
  state = {
    loading: false,
    listJobTtl: [],
    listWhMgrUsr:[],//管理员
  };

  componentDidMount() {
    const {form} = this.props;
    var mgr_work_over_id = x1.router.getParamIn().mgr_work_over_id;
    if (mgr_work_over_id > '') {
      form.setFieldsValue({mgr_work_over_id: mgr_work_over_id});
      this.search(mgr_work_over_id);
    } else {
    }
    this.initWhMgrUsrList();
  };

  handleSubmit = e => {
    const {form} = this.props;
    const fieldsValue = form.getFieldsValue();
    console.log(fieldsValue)
    form.validateFields((err) => {
      if (err) return;
    });
    if(x1.isNull(fieldsValue.mgr_work_over_id)){
      const params = {
        apiUrl: "/api/mgrWorkOver/insert",
        ...fieldsValue,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.mgr_work_over_id)){
            x1.router.back();
          }
        },
      };
      x1.request(params);
    } else {
      const params = {
        apiUrl: "/api/mgrWorkOver/update",
        ...fieldsValue,
        success: (data) => {
          x1.showMessage('提交成功');
          form.setFieldsValue(data);
        },
      };
      x1.request(params);
    };
  };

  search(mgr_work_over_id) {
    const {form} = this.props;
    const params = {
      apiUrl: "/api/mgrWorkOver/list",
      curPage: 0,
      pageSize: 1,
      mgr_work_over_id: mgr_work_over_id,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  initWhMgrUsrList() {
    const params = {
      apiUrl: "/api/tempEmp/list",
      // usr_role_cd: 'R004',
      temp_emp_sts_cd: 'AC',
      excl_all_flg: 'Y',
      success: (data) => {
        this.setState({
          listWhMgrUsr: data,
        });
      },
    };
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 3},
      wrapperCol: {span: 8},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 3},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>
              <FormItem {...formItemLayout} label="编号">
                {
                  form.getFieldDecorator('mgr_work_over_id', {})
                  (
                    <Input style={{width: '150px'}} disabled={true} />
                  )
                }
              </FormItem>
              <FormItem {...formItemLayout} label="员工">
                {form.getFieldDecorator('wh_mgr_usr_id', {
                  rules: [{
                    required: true,
                    message: '请输入保管员',
                  }]
                })(
                    <Select style={{width: '150px'}}>
                      {this.state.listWhMgrUsr.map((item) => (
                          <Option key={item.temp_emp_id}>{item.temp_emp_nm}</Option>
                      ))}
                    </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="工作名称">
                {form.getFieldDecorator('work_nm', {
                  rules: [{
                    required: true,
                    message: '请输入姓名',
                  }]
                })(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="工作日期">
                {form.getFieldDecorator('work_dt', {
                  rules: [{
                    required: true,
                    message: '请输入工作日期',
                  }]
                })(
                    <DatePicker style={{width: '150px'}} />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="开始时间">
                {form.getFieldDecorator('work_start_tm', {
                  rules: [{
                    required: true,
                    message: '请输入工作开始时间',
                  }]
                })(
                    <TimePicker style={{width: '150px'}} format={'HH:mm'}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="结束时间">
                {form.getFieldDecorator('work_end_tm', {
                  rules: [{
                    required: true,
                    message: '请输入工作时间',
                  }]
                })(
                    <TimePicker style={{width: '150px'}} format={'HH:mm'}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="小时单价">
                {form.getFieldDecorator('unit_prc_amt', {
                  rules: [{
                    required: true,
                    message: '请输入小时单价',
                  }]
                })(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...submitItemLayout}>
                <Button type="primary" onClick={this.handleSubmit}>提交</Button>
              </FormItem>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

TemporaryEmployeeDetail = Form.create({})(TemporaryEmployeeDetail);
export default TemporaryEmployeeDetail;
