import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, DatePicker, Radio} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class ReportRentOrderByLocation extends PureComponent {
  state = {
    loading: false,
    loadingDownload: false,
    pagination: {},
    listItem: [],
    smryData: {},
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {from_rpt_dt, to_rpt_dt, prod_nm} = state;
        form.setFieldsValue({from_rpt_dt, to_rpt_dt, prod_nm});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initFromNoneBack();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initFromNoneBack(){
    const {form} = this.props;
    if(x1.report.hasConditionDate()){
      // 取上个画面的区间
      x1.report.loadConditionDate(this);
    } else {
      // 默认期间3个月
      var toDt = x1.getCurrentMoment();
      var fromDt = x1.getCurrentMoment().subtract(3, 'months');
      form.setFieldsValue({
        from_rpt_dt: fromDt,
        to_rpt_dt: toDt,
      });
    }
    this.initSearch();
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 50);
    this.searchSummary();
  };

  searchSummary() {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/reportRentOrderByLocationSummary",
      ...values,
      success: (data) => {
        if(data.length > 0) {
          this.setState({
              smryData: data[0],
          });
        }
      },
      complete: () => {
        this.setState({loadingSummary: false});
      }
    };
    this.setState({loadingSummary: true});
    x1.request(params);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/reportRentOrderByLocation",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/trip/downloadReportByDriver",
      ...values,
      cmpy_own_car_flg: values.cmpy_own_car_flg=='*'?'':values.cmpy_own_car_flg,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  onChangeDate(){
    setTimeout(()=>{
      const {form} = this.props;
      var values = form.getFieldsValue();
      x1.report.saveConditionDate(values.from_rpt_dt, values.to_rpt_dt);
    },1);
  }

  columns = [
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '库房', dataIndex: 'wh_loc_nm'},
    {title: '租用面积', dataIndex: 'rent_area_amt'},
    {title: '仓库面积', dataIndex: 'wh_loc_area_amt'},
    {title: '库存面积使用率%', dataIndex: 'wh_loc_area_usg_rate'},
    {title: '租金', dataIndex: 'rent_fee_amt'},
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{marginBottom: '16px'}}>
            <div>
              <FormItem {...formItemLayout} label="开始日期">
                {form.getFieldDecorator('from_rpt_dt')(
                  <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="-" colon={false}>
                {form.getFieldDecorator('to_rpt_dt')(
                  <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
            </div>
            <div style={{display:'flex', justifyContent:'flex-end', marginTop:'8px'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              <span style={{paddingRight:'16px'}}></span>
              {/*<Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>*/}
            </div>
          </Form>

          <div className='box_cc' style={{background:'#FFFFB8', padding:'8px'}}>
            <div style={{marginRight:'32px'}}>{'报表天数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.rpt_days,'')}</div></div>
            <div style={{marginRight:'32px'}}>{'租用面积：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.rent_area_amt,'')}</div></div>
            <div style={{marginRight:'32px'}}>{'租金：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.rent_fee_amt,'')}</div></div>
          </div>

          <Table
            rowKey={'wh_loc_cd'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

ReportRentOrderByLocation = Form.create({})(ReportRentOrderByLocation);
export default ReportRentOrderByLocation;
