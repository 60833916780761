import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class InventoryListByStockInDetail extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    head: {},
    listItem: [],
  };

  componentDidMount() {
    var head = x1.router.getParamIn();
    this.state.head = head;
    this.setState({head: head});
    this.initSearch();
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    var head = this.state.head;

    const params = {
      apiUrl: "/api/invoice/getRealtimeInvoiceDetail",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      wh_cd: head.wh_cd,
      ownr_id: head.ownr_id,
      stock_in_num: head.stock_in_num,
      stock_in_line_num: head.stock_in_line_num,
      success: (data) => {
        this.setState({
          listItem: data.listOut,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  openDetail(record) {
    x1.router.push('/OwnerDetail', record);
  };

  columns = [
    {title: '出库日期', dataIndex: 'stock_out_dt_txt'},
    {title: '出库数量', dataIndex: 'stock_out_qty'},
    {title: '结存数量', dataIndex: 'rmn_qty'},
    {title: '出库吨数', dataIndex: 'stock_out_tnng_amt'},
    {title: '结存吨数', dataIndex: 'rmn_tnng_amt'},
    {title: '计费天数', dataIndex: 'inv_day_cnt'},
    {title: '计费金额', dataIndex: 'inv_amt'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <div>
          </div>
        </Fragment>
      ),
    },
  ];

  render() {

    var styleItem = {
      width:'200px',
      height: '40px',
      display: 'flex',
    };
    var styleItemTitle = {
      width:'80px',
    };
    var styleItemValue = {
      flex: 1,
    };

    var head = this.state.head;

    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'flex-start', marginBottom: '16px'}}>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'仓库：'}</div>
                <div style={styleItemValue}>{head.wh_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'货主：'}</div>
                <div style={styleItemValue}>{head.ownr_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'商品：'}</div>
                <div style={styleItemValue}>{head.item_nm}</div>
              </div>
            </div>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库日期：'}</div>
                <div style={styleItemValue}>{x1.date2Text(head.stock_in_dt)}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库数量：'}</div>
                <div style={styleItemValue}>{head.stock_in_qty}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'单位重量(kg)：'}</div>
                <div style={styleItemValue}>{head.unit_kg_amt}</div>
              </div>
            </div>
            {/*<Button type="primary" onClick={this.handleSearch}>查询</Button>*/}
          </Form>
          <Table
            rowKey={'stock_out_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

InventoryListByStockInDetail = Form.create({})(InventoryListByStockInDetail);
export default InventoryListByStockInDetail;
