import React, {PureComponent} from 'react';
import {Divider, DatePicker, Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class RentOrderDetail extends PureComponent {
  state = {
    loading: false,

    listOwnr: [],
    listWh: [],
    listWhLoc: [],
  };

  componentDidMount() {
    const {form} = this.props;

    var rent_ord_num = x1.router.getParamIn().rent_ord_num;
    if (rent_ord_num > '') {
      // 更新
      form.setFieldsValue({rent_ord_num: rent_ord_num});
      this.search(rent_ord_num);
    } else {
      // 新增
      var paramIn = x1.router.getParamIn();
      if(x1.hasValue(paramIn.rent_ownr_id)){
        this.state.rent_ownr_id = paramIn.rent_ownr_id;
        form.setFieldsValue({
          rent_ownr_id: paramIn.rent_ownr_id,
        });
      }
      // 固定按月收
      form.setFieldsValue({
        rent_unit_prc_tp_cd: 'M',
      })
    }
    this.initWarehouseList();
    this.initOwnerList(()=>{
      if(x1.hasValue(this.state.rent_ownr_id)){
        this.onChanageOwner(this.state.rent_ownr_id);
      }
    });
  };

  hasFunctionUpdate(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH)){
      return true;
    }
    return false;
  }

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/rentOrder/update",
        ...fieldsValue,
        usr_id: x1.user.getCurrentUserId(),
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.rent_ord_num)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
        complete: () => {
          this.setState({loading: false});
        }
      };
      this.setState({loading: true});
      x1.request(params);
    });
  };

  search(rent_ord_num) {
    var that = this;
    const {form} = this.props;
    const params = {
      loading: true,
      apiUrl: "/api/rentOrder/list",
      rent_ord_num: rent_ord_num,
      success: (data) => {
        form.setFieldsValue(data[0]);
        this.initWarehouseLocationList();
      },
    };
    x1.request(params);
  };

  onChanageWarehouse(wh_cd){
    const {form} = this.props;
    form.setFieldsValue({'wh_cd': wh_cd});
    this.initWarehouseLocationList();
  };

  onChanageOwner(rent_ownr_id){
    const {form} = this.props;
    if(x1.isNull(rent_ownr_id)){
      form.setFieldsValue({
        'ownr_nm': '',
        'ctac_psn_nm': '',
        'ownr_tel_num': '',
        'item_nm': '',
        'ownr_memo_txt': '',
        'dep_rcv_dt': null,
        'dep_rcv_amt': '',
      });
      return;
    }
    for(var i=0; i<this.state.listOwnr.length; i++){
      var ownr = this.state.listOwnr[i];
      if(ownr.rent_ownr_id == rent_ownr_id){
        form.setFieldsValue({
          'ownr_nm': ownr.rent_ownr_nm,
          'ctac_psn_nm': ownr.ctac_psn_nm,
          'ownr_tel_num': ownr.rent_ownr_tel_num,
          'item_nm': ownr.item_nm,
          'ownr_memo_txt': ownr.ownr_memo_txt,
          'dep_rcv_dt': ownr.dep_rcv_dt,
          'dep_rcv_amt': ownr.dep_rcv_amt,
        });
      }
    }
  };

  initOwnerList(callback) {
    const params = {
      apiUrl: "/api/rentOwner/list",
      rent_ownr_sts_cd: 'AC',
      success: (data) => {
        this.setState({
          listOwnr: data,
        }, ()=>{
          if(callback != null){
            callback();
          }
        });
      },
    };
    x1.request(params);
  };

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseLocationList() {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(x1.isNull(values.wh_cd)){
      this.setState({listWhLoc:[]});
      return;
    }
    const params = {
      apiUrl: "/api/warehouseLocation/list",
      wh_cd: values.wh_cd,
      success: (data) => {
        this.setState({
          listWhLoc: data,
        });
      },
    };
    x1.request(params);
  };

  onChangeTotalPrice(e){
    const {form} = this.props;
    var values = form.getFieldsValue();
    var totPrc = e.target.value;
    var area = values.rent_area_amt;
    var unitPrc = totPrc / area / 30;
    if(isNaN(unitPrc)){
      unitPrc = '';
    } else {
      unitPrc = unitPrc.toFixed(3);
    }
    form.setFieldsValue({rent_unit_prc_amt: unitPrc});
  }

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 8},
      wrapperCol: {span: 16},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 8},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={false}>

              <Row gutter={24}>
                <Col span={8}>
                  <FormItem {...formItemLayout} label="货主">
                    {form.getFieldDecorator('rent_ownr_id', {
                      rules: [{
                        required: true,
                        message: '请输入货主',
                      }]
                    })(
                      <Select style={{width: '150px'}} allowClear={true} onChange={(value)=>this.onChanageOwner(value)} disabled={x1.hasValue(this.state.rent_ownr_id)}>
                        {this.state.listOwnr.map((item) => (
                          <Option key={item.rent_ownr_id}>{item.rent_ownr_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="货主姓名">
                    {form.getFieldDecorator('ownr_nm', {
                      rules: [{
                        // required: true,
                        // message: '请输入货主姓名',
                      }]
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="联系人">
                    {form.getFieldDecorator('ctac_psn_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="详情货主电话">
                    {form.getFieldDecorator('ownr_tel_num', {
                      // rules: [{
                      //   required: true,
                      //   message: '请输入货主电话',
                      // }]
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="商品">
                    {form.getFieldDecorator('item_nm', {
                      rules: [{
                        // required: true,
                        // message: '请输入商品',
                      }]
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="货主备注">
                    {form.getFieldDecorator('ownr_memo_txt', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="押金收取日">
                    {form.getFieldDecorator('dep_rcv_dt', {
                    })(
                      <DatePicker style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="押金">
                    {form.getFieldDecorator('dep_rcv_amt', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>

                  {this.hasFunctionUpdate() &&
                  <FormItem {...submitItemLayout}>
                    <Button type="primary" onClick={this.handleSubmit} loading={this.state.loading}>提交</Button>
                  </FormItem>
                  }
                </Col>

                <Col span={8}>
                  <FormItem {...formItemLayout} label="仓库">
                    {form.getFieldDecorator('wh_cd', {
                      rules: [{
                        required: true,
                        message: '请输入仓库',
                      }]
                    })(
                      <Select style={{width: '150px'}} allowClear={true} onChange={(value)=>this.onChanageWarehouse(value)}>
                        {this.state.listWh.map((item) => (
                          <Option key={item.wh_cd}>{item.wh_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="租用面积m2">
                    {form.getFieldDecorator('rent_area_amt', {
                      rules: [{
                        required: true,
                        message: '请输入面积',
                      }]
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="月总价">
                    {form.getFieldDecorator('rent_mth_tot_prc_amt', {
                      rules: [{
                        required: true,
                        message: '请输入月总价',
                      }]
                    })(
                      <Input style={{width: '150px'}} onChange={(e)=>this.onChangeTotalPrice(e)}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="单价/每平米/每天">
                    {form.getFieldDecorator('rent_unit_prc_amt', {
                      rules: [{
                        required: true,
                        message: '请输入单价',
                      }]
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="单价类型">
                    {form.getFieldDecorator('rent_unit_prc_tp_cd', {
                      rules: [{
                        required: true,
                        message: '请输入单价类型',
                      }]
                    })(
                      <Radio.Group buttonStyle="solid" disabled={true}>
                        <Radio.Button value="D">按天</Radio.Button>
                        <Radio.Button value="M">按月</Radio.Button>
                      </Radio.Group>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="开始日期">
                    {form.getFieldDecorator('rent_from_dt', {
                    })(
                      <DatePicker style={{width: '130px'}} allowClear={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="结束日期">
                    {form.getFieldDecorator('rent_to_dt', {
                    })(
                      <DatePicker style={{width: '130px'}} allowClear={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="合同备注">
                    {form.getFieldDecorator('memo_txt', {
                    })(
                      <Input style={{width: '300px'}}/>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="录单人">
                    {form.getFieldDecorator('ord_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="录时间">
                    {form.getFieldDecorator('ord_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="详情编码">
                    {form.getFieldDecorator('rent_ord_num')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                </Col>

                <Col span={8}>
                  {/*<FormItem {...formItemLayout} label="库位">*/}
                    {/*{form.getFieldDecorator('wh_loc_cd', {*/}
                    {/*})(*/}
                      {/*<Select style={{width: '150px'}} allowClear={true}>*/}
                        {/*{this.state.listWhLoc.map((item) => (*/}
                          {/*<Option key={item.wh_loc_cd}>{item.wh_loc_nm}</Option>*/}
                        {/*))}*/}
                      {/*</Select>*/}
                    {/*)}*/}
                  {/*</FormItem>*/}
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

RentOrderDetail = Form.create({})(RentOrderDetail);
export default RentOrderDetail;
