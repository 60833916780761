import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider, Radio} from 'antd';
import {message} from "antd/lib/index";
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;


class InvoicePaymentEntryPopup extends PureComponent {
  state = {
  };

  componentDidMount() {
    const {form} = this.props;
    form.setFieldsValue({
      rcv_pmt_amt: this.props.inv.tot_inv_amt,
      // rcv_pmt_dt: this.props.inv.stock_in_dt.add(1, "months"),
      rcv_pmt_dt: this.props.inv.stock_in_dt,
    });
  };

  handleSubmit= e  => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      var param = {
        inv_num: this.props.inv.inv_num,
        rcv_pmt_amt: fieldsValue.rcv_pmt_amt,
        rcv_pmt_dt: fieldsValue.rcv_pmt_dt,
      }
      this.props.onSubmit(param);
    });
  }

  render() {
    const {form} = this.props;
    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };
    return (
      <div>
        <Form style={{marginBottom: '16px'}}>
          <FormItem {...formItemLayout} label="支付日期">
            {form.getFieldDecorator('rcv_pmt_dt', {
              rules: [{
                required: true,
                message: '请输入支付日期',
              }]
            })(
              <DatePicker style={{width: '150px'}}/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="支付金额">
            {form.getFieldDecorator('rcv_pmt_amt', {
              rules: [{
                required: true,
                message: '请输入支付金额',
              }]
            })(
              <Input style={{width: '150px'}}/>
            )}
          </FormItem>
          <FormItem {...submitItemLayout}>
            <Button type="primary" onClick={this.handleSubmit}>提交</Button>
          </FormItem>
        </Form>
      </div>
    );
  };
}

InvoicePaymentEntryPopup = Form.create({})(InvoicePaymentEntryPopup);
export default InvoicePaymentEntryPopup;
