import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class WarehouseLocationList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listWh: [],
    listWhRoom: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {wh_loc_nm, wh_cd} = state;
        form.setFieldsValue({wh_loc_nm, wh_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initWarehouseList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseRoomList() {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(x1.isNull(values.wh_cd)){
      this.setState({listWhRoom:[]});
      return;
    }
    const params = {
      apiUrl: "/api/warehouseRoom/list",
      wh_cd: values.wh_cd,
      success: (data) => {
        this.setState({
          listWhRoom: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/warehouseLocation/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/WarehouseLocationDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/WarehouseLocationDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/warehouseLocation/delete",
      wh_loc_cd: record.wh_loc_cd,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  handleChangeWarehouse= value => {
    setTimeout(()=>{
      this.initWarehouseRoomList();
    },1);
  };

  columns = [
    {title: '库位编号', dataIndex: 'wh_loc_cd'},
    {title: '库位名称', dataIndex: 'wh_loc_nm'},
    {title: '面积', dataIndex: 'wh_loc_area_amt'},
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '库房', dataIndex: 'wh_room_nm'},
    {title: '库位状态', dataIndex: 'wh_loc_sts_nm'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          {this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除库位【" + record.wh_loc_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'} />
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="库位名称" allowClear={true}>
              {form.getFieldDecorator('wh_loc_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="仓库">
              {form.getFieldDecorator('wh_cd')(
                <Select style={{width: '200px'}} allowClear={true} onChange={this.handleChangeWarehouse}>
                  {this.state.listWh.map((item) => (
                    <Option key={item.wh_cd}>{item.wh_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="库房">
              {form.getFieldDecorator('wh_room_cd')(
                <Select style={{width: '200px'}} allowClear={true}>
                  {this.state.listWhRoom.map((item) => (
                    <Option key={item.wh_room_cd}>{item.wh_room_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
          </Form>
          <Table
            rowKey={'wh_loc_cd'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

WarehouseLocationList = Form.create({})(WarehouseLocationList);
export default WarehouseLocationList;
