import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, DatePicker, Radio} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import ReportWorkDetail from "./ReportWorkDetail";
import ReportManagerOvertimeDetail from "./ReportManagerOvertimeDetail";

const FormItem = Form.Item;
const {Option} = Select;

class ReportManagerOvertime extends PureComponent {
  state = {
    loading: false,
    loadingDownload: false,
    pagination: {},
    listItem: [],
    // listWh: [],
    smryData: {},
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {from_rpt_dt, to_rpt_dt} = state;
        form.setFieldsValue({from_rpt_dt, to_rpt_dt});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initFromNoneBack();
    }
    // this.initWarehouseList();
  };

  // initWarehouseList() {
  //   const params = {
  //     apiUrl: "/api/warehouse/list",
  //     success: (data) => {
  //       this.setState({
  //         listWh: data,
  //       });
  //     },
  //   };
  //   x1.request(params);
  // };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initFromNoneBack(){
    const {form} = this.props;
    if(x1.report.hasConditionDate()){
      // 取上个画面的区间
      x1.report.loadConditionDate(this);
    } else {
      // 默认期间3个月
      var toDt = x1.getCurrentMoment();
      var fromDt = x1.getCurrentMoment().subtract(3, 'months');
      form.setFieldsValue({
        from_rpt_dt: fromDt,
        to_rpt_dt: toDt,
      });
    }
    this.initSearch();
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 50);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    const params = {
      apiUrl: "/api/mgrWorkOver/summaryByManager",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  // handleDownload = e => {
  //   var that = this;
  //   const {form} = this.props;
  //   var values = form.getFieldsValue();
  //
  //   const params = {
  //     apiUrl: "/api/report/downloadReportByLocation",
  //     ...values,
  //     success: (data)=>{
  //       x1.download(data.file_url);
  //     },
  //     complete: ()=>{
  //       that.setState({loadingDownload: false});
  //     }
  //   };
  //   that.setState({loadingDownload: true});
  //   x1.request(params);
  // };

  onChangeDate(){
    setTimeout(()=>{
      const {form} = this.props;
      var values = form.getFieldsValue();
      x1.report.saveConditionDate(values.from_rpt_dt, values.to_rpt_dt);
    },1);
  }

  openDetail(record) {
      x1.router.push('/ReportManagerOvertimeDetail', record);
  };

  columns = [
    {title:'保管员ID',dataIndex:'wh_mgr_usr_id'},
    {title:'保管员',dataIndex:'wh_mgr_usr_nm'},
    {title: '加班时长(小时)', dataIndex: 'work_hr_amt'},
    {title: '加班费用(元)', dataIndex: 'work_fee_amt'},
    {title: '加班天数', dataIndex: 'work_day_cnt'},
    {title: '操作',
        render: (text, record) => (
            <Fragment>
                <div>
                    <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>每日明细</a>
                </div>
            </Fragment>
        ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{marginBottom: '16px'}}>
            <div>
              <FormItem {...formItemLayout} label="开始日期">
                {form.getFieldDecorator('from_rpt_dt')(
                  <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="-" colon={false}>
                {form.getFieldDecorator('to_rpt_dt')(
                  <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
            </div>
            <div style={{display:'flex', justifyContent:'flex-end', marginTop:'8px'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              {/*<span style={{paddingRight:'16px'}}></span>*/}
              {/*<Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>*/}
            </div>
          </Form>

          <Table
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

ReportManagerOvertime = Form.create({})(ReportManagerOvertime);
export default ReportManagerOvertime;
