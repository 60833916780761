import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class UserDataSecurityDetail extends PureComponent {
  state = {
    loading: false,
    listCourt: [],
  };

  componentDidMount() {
    const {form} = this.props;
    this.initCourt();
    var usr_id = x1.router.getParamIn().usr_id;
    var usr_nm = x1.router.getParamIn().usr_nm;
    if (usr_id > '') {
      this.state.usr_id = usr_id;
      this.state.usr_nm = usr_nm;
      form.setFieldsValue({usr_nm: usr_nm});
    }
  };

  hasFunctionUpdate(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  initCourt() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        data.unshift({wh_cd:'*', wh_nm:'全部'})
        this.setState({
          listCourt: data,
        });
      },
    };
    x1.request(params);
  };

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      const params = {
        apiUrl: "/api/userDataSecurity/add",
        ...fieldsValue,
        usr_id: this.state.usr_id,
        success: (data) => {
          form.setFieldsValue(data);
          x1.showMessage('提交成功');
          x1.router.back();
        },
      };
      x1.request(params);
    });
  };

  // search(usr_id) {
  //   var that = this;
  //   const {form} = this.props;
  //   const params = {
  //     apiUrl: "/api/userDataSecurity/list",
  //     curPage: 0,
  //     pageSize: 1,
  //     usr_id: usr_id,
  //     success: (data) => {
  //       form.setFieldsValue(data[0]);
  //     },
  //     complete: () => {
  //       this.setState({loading: false});
  //     }
  //   };
  //   this.setState({loading: true});
  //   x1.request(params);
  // };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 3},
      wrapperCol: {span: 8},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 3},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>
              <FormItem {...formItemLayout} label="姓名">
                {form.getFieldDecorator('usr_nm')(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="仓库">
                {form.getFieldDecorator('wh_cd', {
                  rules: [{
                    required: true,
                    message: '请选择仓库',
                  }]
                })(
                  <Select style={{width: '150px'}}
                          showSearch
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {this.state.listCourt.map((item) => (<Option key={item.wh_cd}>{item.wh_nm}</Option>))}
                  </Select>
                )}
              </FormItem>

              <FormItem {...submitItemLayout}>
                {this.hasFunctionUpdate() &&
                  <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                }
              </FormItem>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

UserDataSecurityDetail = Form.create({})(UserDataSecurityDetail);
export default UserDataSecurityDetail;
