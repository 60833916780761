import React, {Fragment, PureComponent} from 'react';
import {ConfigProvider, Table, Popconfirm, DatePicker, Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class TransferDetail extends PureComponent {
  state = {
    loading: false,
    loadingSubmit: false,
    listLine: [],
    listWh: [],
    listItem: [],
    listFromLoc: [],
    listToLoc: [],

    loadingWh: false,
  };

  componentDidMount() {
    const {form} = this.props;

    var trnsf_num = x1.router.getParamIn().trnsf_num;
    if (trnsf_num > '') {
      // 更新
      form.setFieldsValue({trnsf_num: trnsf_num});
      this.search(trnsf_num);
    } else {
      // 新增
      form.setFieldsValue({
        trnsf_dt: x1.getSystemDate(),
      });
    }
    this.initWarehouseList();
    this.initFromWarehouseLocationList();
    this.initToWarehouseLocationList();
  };

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
      complete: ()=>{
        this.setState({loadingWh: false});
      }
    };
    this.setState({loadingWh: true});
    x1.request(params);
  };

  initFromWarehouseLocationList() {
    const {form} = this.props;
    var values = form.getFieldsValue();
    const params = {
      apiUrl: "/api/inventory/listLocationForTransfer",
      wh_cd: values.wh_cd,
      success: (data) => {
        this.setState({
          listFromLoc: data,
        });
      },
    };
    x1.request(params);
  };

  initToWarehouseLocationList() {
    const {form} = this.props;
    var values = form.getFieldsValue();
    const params = {
      apiUrl: "/api/warehouseLocation/list",
      wh_cd: values.wh_cd,
      success: (data) => {
        this.setState({
          listToLoc: data,
        });
      },
    };
    x1.request(params);
  };

  initItemList(wh_loc_cd, indexLine) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(x1.isNull(values.wh_cd) || x1.isNull(wh_loc_cd) || indexLine < 0){
      return;
    }
    const params = {
      apiUrl: "/api/inventory/listItemForTransfer",
      wh_cd: values.wh_cd,
      wh_loc_cd: wh_loc_cd,
      success: (data) => {
        // 更新下拉框
        let formValues = form.getFieldsValue();
        let listLine = x1.mergeDeep(this.state.listLine, formValues.listLine);
        var line = listLine[indexLine];
        if(line != null){
          line.listAvalItem = data;
          this.setState({listLine:listLine});
          form.setFieldsValue({listLine:listLine});
        }
      },
    };
    x1.request(params);
  };

  onChangeWarehouse(wh_cd){
    const {form} = this.props;
    form.setFieldsValue({'wh_cd': wh_cd});
    this.initListByWarehouse();
  };

  onChangeFromLocation(wh_loc_cd, indexLine){
    this.initItemList(wh_loc_cd, indexLine);
  };

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSave = e => {
    var sbmt_flg = 'N';
    this.submit(sbmt_flg);
  }

  handleSubmit = e => {
    var sbmt_flg = 'Y';
    this.submit(sbmt_flg);
  }

  submit(sbmt_flg) {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/transfer/update",
        ...fieldsValue,
        listLine: x1.mergeDeep(this.state.listLine, fieldsValue.listLine),
        rqst_usr_id: x1.user.getCurrentUserId(),
        sbmt_flg: sbmt_flg,
        success: (data) => {
          x1.showMessage('提交成功');
          this.search(data.trnsf_num);
        },
        complete:()=>{
          this.setState({
            loadingSubmit:false,
            sbmt_flg: sbmt_flg,
          });
        }
      };
      this.setState({
        loadingSubmit:true,
        sbmt_flg: sbmt_flg,
      });
      x1.request(params);
    });
  };

  initListByWarehouse(){
    this.initFromWarehouseLocationList();
    this.initToWarehouseLocationList();
  }

  search(trnsf_num) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/transfer/list",
      trnsf_num: trnsf_num,
      get_line_flg: 'Y',
      success: (data) => {
        form.setFieldsValue(data[0]);
        this.setState(data[0]);
        this.initListByWarehouse();
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  removeLine(index){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listLine = x1.mergeDeep(this.state.listLine, formValues.listLine);
    listLine.splice(index, 1);
    this.setState({listLine:listLine});
    form.setFieldsValue({listLine:listLine});
  };

  newLine(){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listLine = x1.mergeDeep(this.state.listLine, formValues.listLine);
    listLine.push({listAvalItem:[]});
    this.setState({listLine:listLine});
    form.setFieldsValue({listLine:listLine});
  };

  isTrxDone(){
    if(this.state.trnsf_sts_cd == 'CP'){
      return true;
    }
    return false;
  }

  render() {
    const {form} = this.props;

    const rowInputStyle = {
      marginBottom: '0'
    };

    var customizeRenderEmpty = () => {
        return <div>没有数据</div>
      }
    ;

    // ------------------------
    // 站点
    // ------------------------
    const columnsLine = [
      {
        title: '移库明细号', dataIndex: 'trnsf_line_num',
      },
      {
        title: '移动前库房', dataIndex: 'from_wh_loc_cd',
        render: (text, record, index) => {
          return (
            <div>
              {this.isTrxDone() &&
              <div>
                <div>{record.from_wh_loc_nm}</div>
              </div>
              }
              {!this.isTrxDone() &&
              <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listLine[${index}].from_wh_loc_cd`, {
                rules: [{required: true, message:'请输入移动前库房'}],
                initialValue: text
              })(
                <Select style={{width: '150px'}} allowClear={true}
                        onChange={(value)=>this.onChangeFromLocation(value, index)}
                        disabled={this.isTrxDone()}
                >
                  {this.state.listFromLoc.map((item) => (
                    <Option key={item.wh_loc_cd}>{item.wh_loc_nm + ' (库存:' + item.invty_qty + ")"}</Option>
                  ))}
                </Select>
              )}</FormItem>
              }
            </div>
          );
        },
      },
      {
        title: '商品', dataIndex: 'from_invty_pk',
        render: (text, record, index) => {
          return (
            <div>
              {this.isTrxDone() &&
              <div>
                <div>{record.item_nm}</div>
                <div>{record.ownr_nm}</div>
                <div>{x1.date2Text(record.stock_in_dt)}</div>
              </div>
              }
              {!this.isTrxDone() &&
              <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listLine[${index}].from_invty_pk`, {
                rules: [{required: true, message:'请输入商品'}],
                initialValue: text
              })(
                <Select style={{minWidth: '300px'}} allowClear={true}
                        showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        disabled={this.isTrxDone()}
                >
                  {record.listAvalItem.map((item) => (
                    <Option key={item.invty_pk}>{item.ownr_nm + ' ' + item.item_nm + ' ' + x1.date2Text(item.stock_in_dt) + ' (库存:' + item.invty_qty + ")"}</Option>
                  ))}
                </Select>
              )}</FormItem>
              }
            </div>
          );
        },
      },
      {
        title: '移库数量', dataIndex: 'trnsf_qty',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listLine[${index}].trnsf_qty`, {
              initialValue: text
            })(<Input style={{width:'70px'}} disabled={this.isTrxDone()} />)}</FormItem>
          );
        },
      },
      {
        title: '移动后库房', dataIndex: 'to_wh_loc_cd',
        render: (text, record, index) => {
          return (
            <div>
              {this.isTrxDone() &&
              <div>
                <div>{record.to_wh_loc_nm}</div>
              </div>
              }
              {!this.isTrxDone() &&
              <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listLine[${index}].to_wh_loc_cd`, {
                rules: [{required: true, message:'请输入移动后库房'}],
                initialValue: text
              })(
                <Select style={{width: '100px'}} allowClear={true}
                        onChange={(value)=>this.onChangeFromLocation(value, index)}
                        disabled={this.isTrxDone()}
                >
                  {this.state.listToLoc.map((item) => (
                    <Option key={item.wh_loc_cd}>{item.wh_loc_nm}</Option>
                  ))}
                </Select>
              )}</FormItem>
              }
            </div>
          );
        },
      },
      {
        title: '操作',
        key: 'action',
        render: (text, record, index) => {
          return (
            <span>
              {!this.isTrxDone() &&
              <Fragment>
                <Popconfirm title="是否要删除此行？" onConfirm={() => this.removeLine(index)}>
                  <a>删除</a>
                </Popconfirm>
              </Fragment>
              }
            </span>
          );
        },
      },
    ];

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={8}>
                  <FormItem {...formItemLayout} label="仓库">
                    {form.getFieldDecorator('wh_cd', {
                      rules: [{
                        required: true,
                        message: '请输入仓库',
                      }]
                    })(
                      <Select style={{width: '150px'}} allowClear={true} onChange={(value)=>this.onChangeWarehouse(value)}
                              disabled={this.isTrxDone()} loading={this.state.loadingWh}>
                        {this.state.listWh.map((item) => (
                          <Option key={item.wh_cd}>{item.wh_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="备注">
                    {form.getFieldDecorator('rqst_memo_txt', {
                    })(
                      <Input style={{width: '150px'}} disabled={this.isTrxDone()} />
                    )}
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem {...formItemLayout} label="移库单号">
                    {form.getFieldDecorator('trnsf_num')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="移库单状态">
                    {form.getFieldDecorator('trnsf_sts_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem {...formItemLayout} label="更新人">
                    {form.getFieldDecorator('rqst_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="更新时间">
                    {form.getFieldDecorator('rqst_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="批准新人">
                    {form.getFieldDecorator('apvl_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="批准时间">
                    {form.getFieldDecorator('apvl_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <div>
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowKey={'trnsf_line_num'}
                    columns={columnsLine}
                    dataSource={this.state.listLine}
                    pagination={false}
                    size={'middle'}
                  />
                  {!this.isTrxDone() &&
                  <Button style={{marginTop: 16, marginBottom: 8}} icon="plus" onClick={() => this.newLine()}>新增明细</Button>
                  }
                </ConfigProvider>
              </div>
              <div>
                <div style={{marginTop:'32px'}}>
                  {!this.isTrxDone() &&
                  <Fragment>
                    <Button type="primary" onClick={this.handleSave} loading={this.state.loadingSubmit && this.state.sbmt_flg=='N'}>保存</Button>
                    <div style={{display:'inline-block', width:'16px'}}></div>
                    <Popconfirm title={"确定要移库吗？"} onConfirm={() => this.handleSubmit()}>
                      <Button type="primary" loading={this.state.loadingSubmit && this.state.sbmt_flg=='Y'}>移库</Button>
                    </Popconfirm>
                  </Fragment>
                  }
                </div>
              </div>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

TransferDetail = Form.create({})(TransferDetail);
export default TransferDetail;
