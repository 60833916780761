import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class XxTransactionList extends PureComponent {
  state = {
    loading: false,
    loadingDownload: false,
    pagination: {},
    listItem: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {prod_tp_nm, trx_tp_cd} = state;
        form.setFieldsValue({prod_tp_nm, trx_tp_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/xxTrx/getReportList",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  // openDetail(record) {
  //   x1.router.push('/XxTransactionDetail', record, x1.getStateSnapshort(this));
  // };

  openProductPrice(record) {
    x1.router.push('/ProductPriceList', record, x1.getStateSnapshort(this));
  };

  onChangeDate(){
    setTimeout(()=>{
      const {form} = this.props;
      var values = form.getFieldsValue();
      x1.report.saveConditionDate(values.from_rpt_dt, values.to_rpt_dt);
    },1);
  }

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/xxTrx/downloadReport",
      ...values,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  columns = [
    {title: '单号', dataIndex: 'trx_num'},
    {title: '单据类型', dataIndex: 'trx_tp_nm'},
    {title: '商品类型', dataIndex: 'prod_tp_nm'},
    {title: '件数', dataIndex: 'trx_qty'},
    {title: '重量', dataIndex: 'trx_tnng_amt'},
    {title: '司机', dataIndex: 'drvr_nm'},
    {title: '车牌', dataIndex: 'car_plate_num'},
    {title: '运费', dataIndex: 'ship_fee_amt'},
    {title: '入库时间', dataIndex: 'stk_in_ts'},
    {title: '出库时间', dataIndex: 'stk_out_ts'},
    {title: '送达时间', dataIndex: 'arv_out_ts'},
    {title: '是否异常', dataIndex: 'err_flg'},
    // {
    //   title: '操作',
    //   render: (text, record) => (
    //     <Fragment>
    //       <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
    //     </Fragment>
    //   ),
    // },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="开始日期">
              {form.getFieldDecorator('from_rpt_dt')(
                <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="-" colon={false}>
              {form.getFieldDecorator('to_rpt_dt')(
                <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="单据类型">
              {form.getFieldDecorator('trx_tp_cd')(
                <Select style={{width: '200px'}} allowClear={true}>
                  <Option key={'I'}>{'入库'}</Option>
                  <Option key={'O'}>{'出库'}</Option>
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="商品类型" allowClear={true}>
              {form.getFieldDecorator('prod_tp_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <span style={{paddingRight:'16px'}}></span>
            <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>
          </Form>
          <Table
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

XxTransactionList = Form.create({})(XxTransactionList);
export default XxTransactionList;
