import React, {Fragment, PureComponent} from 'react';
import {Button, Card, DatePicker, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {message} from "antd/lib/index";
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const {MonthPicker} = DatePicker;

class TemporaryEmployeeAssignListByDate extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {temp_emp_asg_mth, temp_emp_nm} = state;
        form.setFieldsValue({temp_emp_asg_mth, temp_emp_nm});
        this.refreshPage();
      }
    }

    if(!isBack) {
      form.setFieldsValue({
        temp_emp_asg_mth: x1.getCurrentMoment()
      });
      this.initSearch();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 31);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/tempEmpAsg/summaryByDate",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  openDetail(record) {
    x1.router.push('/TemporaryEmployeeAssignByDate', record, x1.getStateSnapshort(this));
  };

  columns = [
    {title: '日期',
      render: (text, record) => (
        <div>
          {x1.date2Text(record.temp_emp_asg_dt)}
        </div>
      ),
    },
    {title: '人数', dataIndex: 'emp_cnt'},
    {title: '人数(上午)', dataIndex: 'am_emp_cnt'},
    {title: '人数(下午)', dataIndex: 'pm_emp_cnt'},
    {title: '人数(晚上)', dataIndex: 'ng_emp_cnt'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="月份">
              {form.getFieldDecorator('temp_emp_asg_mth', {
              })(
                  <MonthPicker style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="姓名">
              {form.getFieldDecorator('temp_emp_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
          </Form>
          <Table
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

TemporaryEmployeeAssignListByDate = Form.create({})(TemporaryEmployeeAssignListByDate);
export default TemporaryEmployeeAssignListByDate;
