import React, {Fragment, PureComponent} from 'react';
import {Button, Card, DatePicker, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {message} from "antd/lib/index";
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class TemporaryEmployee extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listJobTtl:[],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {temp_emp_id, temp_emp_nm, temp_emp_phone, job_ttl_tp_cd} = state;
        form.setFieldsValue({temp_emp_id, temp_emp_nm, temp_emp_phone, job_ttl_tp_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initJobTtl();
    }
  };

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  initJobTtl(){
    const params = {
        apiUrl: "/api/jobTtlTp/list",
        success: (data) => {
          this.setState({
            listJobTtl: data,
          });
        },
        complete: () => {
            this.setState({loading: false});
        }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/tempEmp/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/TemporaryEmployeeDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/TemporaryEmployeeDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/tempEmp/delete",
      temp_emp_id: record.temp_emp_id,
      success: (data) => {
        this.initSearch();
        message.success('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  columns = [
    {title: '编号', dataIndex: 'temp_emp_id'},
    {title: '姓名', dataIndex: 'temp_emp_nm'},
    {title: '电话', dataIndex: 'temp_emp_phone'},
    {title: '职位', dataIndex: 'job_ttl_tp_nm'},
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '状态', dataIndex: 'temp_emp_sts_nm'},
    // {title: '单价', dataIndex: 'unit_prc_amt'},
    // {title: '加班单价', dataIndex: 'unit_over_hr_amt'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          {this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除员工【" + record.temp_emp_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'} />
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="编号">
              {form.getFieldDecorator('temp_emp_id', {
              })(
                  <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="姓名">
              {form.getFieldDecorator('temp_emp_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="电话">
              {form.getFieldDecorator('temp_emp_phone')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="职位">
              {form.getFieldDecorator('job_ttl_tp_cd')(
                <Select style={{width: '100px'}} allowClear={true}>
                  {this.state.listJobTtl.map((item) => (
                    <Option key={item.job_ttl_tp_cd}>{item.job_ttl_tp_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="状态">
              {form.getFieldDecorator('temp_emp_sts_cd')(
                <Select style={{width: '100px'}} allowClear={true}>
                  <Option key={'AC'}>{'可用'}</Option>
                  <Option key={'IN'}>{'不可用'}</Option>
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
          </Form>
          <Table
            rowKey={'temp_emp_id'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

TemporaryEmployee = Form.create({})(TemporaryEmployee);
export default TemporaryEmployee;
