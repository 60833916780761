import React, {Fragment, PureComponent} from 'react';
import {Modal, Checkbox, ConfigProvider, Table, Popconfirm, DatePicker, Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';
import StockInDetailPriceEntryPopup from "./StockInDetailPriceEntryPopup";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class StockInDetail extends PureComponent {
  state = {
    loading: false,
    loadingSubmit: false,
    loadingConfirm: false,
    loadingReject: false,
    loadingPrint: false,
    loadingTime: false,

    listLine: [],
    listWh: [],
    listOwnr: [],
    listItem: [],
    listWhLoc: [],

    checkedItem: false,
    checkedPmt: false,

    isShowPopupPrice: false,
  };

  componentDidMount() {
    const {form} = this.props;

    var stock_in_num = x1.router.getParamIn().stock_in_num;
    if (stock_in_num > '') {
      // 更新
      form.setFieldsValue({stock_in_num: stock_in_num});
      this.search(stock_in_num);
    } else {
      // 新增
      form.setFieldsValue({
        stock_in_dt: x1.getSystemDate(),
        // stock_in_tm: x1.getCurrentMoment(),
      });
    }
    this.initWarehouseList();
    this.initOwnerList();
    this.initItemList();
  };

  hasFunctionUpdateBaseHead(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.MGR)){
      return true;
    }
    return false;
  }

  hasFunctionSubmit(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.MGR) || x1.user.hasRole(x1.ROLE.BIZ)){
      return true;
    }
    return false;
  }

  hasFunctionConfirm(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.MGR) || x1.user.hasRole(x1.ROLE.ORD)){
      return true;
    }
    return false;
  }

  hasFunctionConfirmReject(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  hasFunctionChangePrice(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseLocationList() {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(x1.isNull(values.wh_cd)){
      this.setState({listWhLoc:[]});
      return;
    }
    const params = {
      apiUrl: "/api/warehouseLocation/list",
      wh_cd: values.wh_cd,
      success: (data) => {
        this.setState({
          listWhLoc: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  initItemList() {
    const params = {
      apiUrl: "/api/item/list",
      success: (data) => {
        this.setState({
          listItem: data,
        });
      },
    };
    x1.request(params);
  };

  onChanageWarehouse(wh_cd){
    const {form} = this.props;
    form.setFieldsValue({'wh_cd': wh_cd});
    this.initWarehouseLocationList();
  };


  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSave = e => {
    var sbmt_flg = 'N';
    this.submit(sbmt_flg);
  }

  handleSubmit = e => {
    var sbmt_flg = 'Y';
    this.submit(sbmt_flg);
  }

  handleConfirm = e => {
    this.confirm();
  }

  handleReject = e => {
    this.reject();
  }

  handlePrint(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    const params = {
      apiUrl: "/api/stockIn/print",
      stock_in_num: fieldsValue.stock_in_num,
      usr_id: x1.user.getCurrentUserId(),
      usr_nm: x1.user.getCurrentUser().usr_nm,
      success: (data) => {
        x1.showMessage('提交成功');
        x1.download(data.file_url);
      },
      complete:()=>{
        this.setState({
          loadingPrint:false,
        });
      }
    };
    this.setState({
      loadingPrint:true,
    });
    x1.request(params);
  }

  confirmTime() {
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    if(x1.isNull(fieldsValue.stock_in_tm)){
      x1.showError('请输入入库时间');
      return false;
    }
    if(x1.isNull(fieldsValue.stock_in_num)){
      x1.showError('没有入库单号');
      return false;
    }

    const params = {
      apiUrl: "/api/stockIn/confirmTime",
      stock_in_num: fieldsValue.stock_in_num,
      usr_id: x1.user.getCurrentUserId(),
      stock_in_tm: fieldsValue.stock_in_tm,
      success: (data) => {
        x1.showMessage('提交成功');
        this.search(fieldsValue.stock_in_num);
      },
      complete:()=>{
        this.setState({
          loadingTime:false,
        });
      }
    };
    this.setState({
      loadingTime:true,
    });
    x1.request(params);
  };

  confirm() {
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    if(x1.isNull(fieldsValue.stock_in_tm)){
      x1.showError('请输入入库时间');
      return false;
    }
    if(!this.state.checkedItem){
        x1.showError('请确认单据信息正确（品类、数量、价格等）');
        return false;
    }
    if(x1.isNull(this.state.pre_paid_load_tp_cd)){
      x1.showError('请确认装卸费收费方式');
      return false;
    }

    // 检查是否有明细单价
    for(var i=0; i<this.state.listLine.length; i++){
      var line = this.state.listLine[i];
      if(x1.isNull(line.load_unit_prc_amt)
        || x1.isNull(line.pre_cool_unit_prc_amt)
        || x1.isNull(line.store_unit_prc_amt)
        || x1.isNull(line.card_unit_prc_amt) ){
        x1.showError('请先点击【确定入库时间】按获取明细单价');
        return false;
      }
    }

    const params = {
      apiUrl: "/api/stockIn/confirm",
      stock_in_num: fieldsValue.stock_in_num,
      usr_id: x1.user.getCurrentUserId(),
      pre_paid_load_tp_cd: this.state.pre_paid_load_tp_cd,
      stock_in_memo_txt: fieldsValue.stock_in_memo_txt,
      stock_in_tm: fieldsValue.stock_in_tm,
      over_time_fee_amt: fieldsValue.over_time_fee_amt,
      success: (data) => {
        x1.showMessage('提交成功');
        this.search(fieldsValue.stock_in_num);
      },
      complete:()=>{
        this.setState({
          loadingConfirm:false,
        });
      }
    };
    this.setState({
      loadingConfirm:true,
    });
    x1.request(params);
  };

  reject() {
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    const params = {
      apiUrl: "/api/stockIn/reject",
      stock_in_num: fieldsValue.stock_in_num,
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        x1.showMessage('提交成功');
        this.search(fieldsValue.stock_in_num);
      },
      complete:()=>{
        this.setState({
          loadingReject:false,
        });
      }
    };
    this.setState({
      loadingReject:true,
    });
    x1.request(params);
  };

  submit(sbmt_flg) {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/stockIn/update",
        ...fieldsValue,
        listLine: x1.mergeDeep(this.state.listLine, fieldsValue.listLine),
        ord_src_cd: 'U',
        ord_usr_id: x1.user.getCurrentUserId(),
        upd_usr_id: x1.user.getCurrentUserId(),
        sbmt_usr_id: x1.user.getCurrentUserId(),
        usr_id: x1.user.getCurrentUserId(),
        sbmt_flg: sbmt_flg,
        success: (data) => {
          x1.showMessage('提交成功');
          this.search(data.stock_in_num);
        },
        complete:()=>{
          this.setState({
            loadingSubmit:false,
            sbmt_flg: sbmt_flg,
          });
        }
      };
      this.setState({
        loadingSubmit:true,
        sbmt_flg: sbmt_flg,
      });
      x1.request(params);
    });
  };

  search(stock_in_num) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/stockIn/list",
      stock_in_num: stock_in_num,
      get_loc_flg: 'Y',
      success: (data) => {
        form.setFieldsValue(data[0]);
        this.setState(data[0]);
        this.initWarehouseLocationList();
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  addLocation(index){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listLine = x1.mergeDeep(this.state.listLine, formValues.listLine);
    var line = listLine[index];
    line.listLoc.push({});
    this.setState({listLine:listLine});
    form.setFieldsValue({listLine:listLine});
  }

  removeLocation(index, indexLoc){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listLine = x1.mergeDeep(this.state.listLine, formValues.listLine);
    var line = listLine[index];
    line.listLoc.splice(indexLoc, 1);
    this.setState({listLine:listLine});
    form.setFieldsValue({listLine:listLine});
  };

  removeLine(index){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listLine = x1.mergeDeep(this.state.listLine, formValues.listLine);
    listLine.splice(index, 1);
    this.setState({listLine:listLine});
    form.setFieldsValue({listLine:listLine});
  };

  newLine(){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listLine = x1.mergeDeep(this.state.listLine, formValues.listLine);
    listLine.push({listLoc:[]});
    this.setState({listLine:listLine});
    form.setFieldsValue({listLine:listLine});
  };

  isStockInConfirm(){
    if(this.state.stock_in_sts_cd == 'CP'){
      return true;
    }
    return false;
  }

  isStockIn(){
    if(this.state.stock_in_sts_cd == 'SU' || this.state.stock_in_sts_cd == 'CP'){
      return true;
    }
    return false;
  }

  onChangeCheckboxItem = e => {
    this.setState({
      checkedItem: e.target.checked,
    });
  };

  onChangeCheckboxPayment = e => {
    this.setState({
      checkedPmt: e.target.checked,
    });
  };

  onChangeRadioPayment = e => {
    this.setState({
      pre_paid_load_tp_cd: e.target.value,
    });
  };

  // -------------------------------
  // 编辑单价
  // -------------------------------
  onSubmitPrice(input){
    const {form} = this.props;
    var values = form.getFieldsValue();
    this.setState({isShowPopupPrice: false});
    var stockInLine = input.stockInLine;

    var api = '';
    if(stockInLine.prc_tp_cd == 'preCool'){
      api = "/api/stockIn/updateLinePreCoolPrice";
    } else if(stockInLine.prc_tp_cd == 'store'){
      api = "/api/stockIn/updateLineStorePrice";
    }
    if(!x1.hasValue(api)){
      x1.showError('价格类型不正确');
      return;
    }

    const params = {
      loading: true,
      apiUrl: api,
      stock_in_num: stockInLine.stock_in_num,
      stock_in_line_num: stockInLine.stock_in_line_num,
      pre_cool_unit_prc_amt: input.unit_prc_amt,
      store_unit_prc_amt: input.unit_prc_amt,
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        this.search(stockInLine.stock_in_num);
        x1.showMessage('提交成功');
      },
    };
    x1.request(params);
  };

  openPopupPrice(record, prc_tp_cd) {
    var param = {...record, prc_tp_cd:prc_tp_cd};
    if(prc_tp_cd == 'preCool'){
      param.unit_prc_amt = record.pre_cool_unit_prc_amt;
    } else if(prc_tp_cd == 'store'){
      param.unit_prc_amt = record.store_unit_prc_amt;
    }
    this.setState({selStockInLine: param}, ()=>{
      this.setState({isShowPopupPrice: true});
    });
  }

  cancelPopupPrice(){
    this.setState({isShowPopupPrice: false});
  }

  render() {
    const {form} = this.props;

    const rowInputStyle = {
      marginBottom: '0'
    };

    var customizeRenderEmpty = () => {
        return <div>没有数据</div>
      }
    ;

    // ------------------------
    // 站点
    // ------------------------
    const columnsLine = [
      {
        title: '入库明细号', dataIndex: 'stock_in_line_num',
      },
      {
        title: '商品', dataIndex: 'item_cd',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listLine[${index}].item_cd`, {
              rules: [{required: true, message:'请输入商品'}],
              initialValue: text
            })(
              <Select style={{minWidth: '300px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      disabled={this.isStockIn()}
              >
                {this.state.listItem.map((item) => (
                  <Option key={item.item_cd}>{item.item_nm + '/' + item.item_unit_nm}</Option>
                ))}
              </Select>
            )}</FormItem>
          );
        },
      },
      {
        title: '单位重量(KG)', dataIndex: 'unit_kg_amt',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listLine[${index}].unit_kg_amt`, {
              rules: [{required: true, message:'请输入单位重量'}],
              initialValue: text
            })(<Input style={{width: '100px'}} disabled={this.isStockIn()} />)}</FormItem>
          );
        },
      },
      {
        title: '供应商信息', dataIndex: 'sply_nm',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listLine[${index}].sply_nm`, {
              initialValue: text
            })(<Input disabled={this.isStockIn()} />)}</FormItem>
          );
        },
      },
      {
        title: '',
        render: (text, record, index) => {
          return (
            <div>
              {!this.isStockIn() &&
              <div>
                {record.listLoc.map((loc, indexLoc)=>
                  <div style={{height:'39px'}} className={'box_cc'}>
                    <a onClick={() => this.removeLocation(index, indexLoc)} style={{marginRight: '10px'}}>删除库位</a>
                  </div>
                )}
              </div>
              }
            </div>
          );
        },
      },
      {
        title: '库位', dataIndex: 'wh_loc_nm',
        render: (text, record, index) => {
          return (
            <div>
              {record.listLoc.map((loc, indexLoc)=>
                <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listLine[${index}].listLoc[${indexLoc}].wh_loc_cd`, {
                  rules: [{required: true, message:'请输入库位'}],
                  initialValue: loc.wh_loc_cd
                })(
                  <Select style={{width: '150px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          disabled={this.isStockIn()}
                  >
                    {this.state.listWhLoc.map((item) => (
                      <Option key={item.wh_loc_cd}>{item.wh_loc_nm}</Option>
                    ))}
                  </Select>
                )}</FormItem>
              )}
            </div>
          );
        },
      },
      {
        title: '入库数量', dataIndex: 'stock_in_qty',
        render: (text, record, index) => {
          return (
            <div>
              {record.listLoc.map((loc, indexLoc)=>
                <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listLine[${index}].listLoc[${indexLoc}].stock_in_qty`, {
                  rules: [{required: true, message:'请输入入库数量'}],
                  initialValue: loc.stock_in_qty
                })(<Input disabled={this.isStockIn()} />)}</FormItem>
              )}
            </div>
          );
        },
      },
      {
        title: '操作',
        key: 'action',
        render: (text, record, index) => {
          return (
            <span>
              {!this.isStockIn() &&
              <Fragment>
                <a onClick={() => this.addLocation(index)} style={{marginRight: '10px'}}>新增库位</a>
                <Popconfirm title="是否要删除此行？" onConfirm={() => this.removeLine(index)}>
                  <a>删除</a>
                </Popconfirm>
              </Fragment>
              }
            </span>
          );
        },
      },
      {
        title: '单价信息',
        render: (text, record, index) => {
          return (
            <div>
              <div>
                <span>{'预冷费：' + x1.nvl(record.pre_cool_unit_prc_amt)}</span>
                {this.hasFunctionChangePrice() &&
                <span> <a onClick={() => this.openPopupPrice(record, 'preCool')}>编辑</a></span>
                }
              </div>
              <div>
                <span>{'冷藏费：' + x1.nvl(record.store_unit_prc_amt)}</span>
                {this.hasFunctionChangePrice() &&
                <span> <a onClick={() => this.openPopupPrice(record, 'store')}>编辑</a></span>
                }
              </div>
              <div>{'装卸费：' + x1.nvl(record.load_unit_prc_amt)}</div>
              <div>{'工本费：' + x1.nvl(record.card_unit_prc_amt)}</div>
            </div>
          );
        },
      },
    ];

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={false}>

              <Row gutter={24}>
                <Col span={6}>
                  <FormItem {...formItemLayout} label="仓库">
                    {form.getFieldDecorator('wh_cd', {
                      rules: [{
                        required: true,
                        message: '请输入仓库',
                      }]
                    })(
                      <Select style={{width: '150px'}} allowClear={true} onChange={(value)=>this.onChanageWarehouse(value)} disabled={this.isStockIn() || !this.hasFunctionUpdateBaseHead()}>
                        {this.state.listWh.map((item) => (
                          <Option key={item.wh_cd}>{item.wh_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="货主">
                    {form.getFieldDecorator('ownr_id', {
                      rules: [{
                        required: true,
                        message: '请输入货主',
                      }]
                    })(
                      <Select style={{width: '150px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              disabled={this.isStockIn()  || !this.hasFunctionUpdateBaseHead()}
                      >
                        {this.state.listOwnr.map((item) => (
                          <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="入库日期">
                    {form.getFieldDecorator('stock_in_dt', {
                      rules: [{
                        required: true,
                        message: '请输入入库日期',
                      }]
                    })(
                      <DatePicker style={{width: '150px'}} disabled={this.isStockIn()  || !this.hasFunctionUpdateBaseHead()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="入库时间">
                    {form.getFieldDecorator('stock_in_tm', {
                    })(
                      <TimePicker style={{width: '150px'}} format={'HH:mm'}
                                  disabled={this.isStockInConfirm()  || !this.hasFunctionConfirm()}
                      />
                    )}
                  </FormItem>
                  {this.state.stock_in_num &&
                  <FormItem {...submitItemLayout} label="">
                    <Button loading={this.state.loadingTime} onClick={() => this.confirmTime()} icon={'check'}
                            disabled={this.isStockInConfirm()  || !this.hasFunctionConfirm()}
                    >确定入库时间</Button>
                    <div style={{lineHeight:'100%'}}>{'确定入库时间时，自动刷新单价。'}</div>
                    <div style={{lineHeight:'100%'}}>{'画面修改后，请先保存再点击确定时间。'}</div>
                  </FormItem>
                  }
                </Col>
                <Col span={6}>
                  <FormItem {...formItemLayout} label="货车数量">
                    {form.getFieldDecorator('car_cnt', {
                    })(
                      <Input style={{width: '150px'}} disabled={this.isStockIn()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="货车吨位">
                    {form.getFieldDecorator('car_unit_tnng_amt', {
                    })(
                      <Input style={{width: '150px'}} disabled={this.isStockIn()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="货主备注">
                    {form.getFieldDecorator('ownr_memo_txt', {
                    })(
                      <Input style={{width: '150px'}} disabled={this.isStockIn()}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="内部备注">
                    {form.getFieldDecorator('stock_in_memo_txt', {
                    })(
                      <Input style={{width: '150px'}} disabled={this.isStockInConfirm()  || !this.hasFunctionConfirm()} />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="加班费">
                    {form.getFieldDecorator('over_time_fee_amt', {
                    })(
                      <Input style={{width: '150px'}} disabled={this.isStockInConfirm()  || !this.hasFunctionConfirm()} />
                    )}
                  </FormItem>

                </Col>
                <Col span={6}>
                  <FormItem {...formItemLayout} label="入库单号">
                    {form.getFieldDecorator('stock_in_num')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="入库单状态">
                    {form.getFieldDecorator('stock_in_sts_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="更新人">
                    {form.getFieldDecorator('upd_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="更新时间">
                    {form.getFieldDecorator('upd_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem {...formItemLayout} label="提交人">
                    {form.getFieldDecorator('sbmt_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="提交时间">
                    {form.getFieldDecorator('sbmt_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="确认人">
                    {form.getFieldDecorator('conf_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="确认时间">
                    {form.getFieldDecorator('conf_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="创建人">
                    {form.getFieldDecorator('ord_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="创建时间">
                    {form.getFieldDecorator('ord_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <div>
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowKey={'stock_in_line_num'}
                    columns={columnsLine}
                    dataSource={this.state.listLine}
                    pagination={false}
                    size={'middle'}
                  />
                  {!this.isStockIn() &&
                  <Button style={{marginTop: 16, marginBottom: 8}} icon="plus" onClick={() => this.newLine()}>新增明细</Button>
                  }
                </ConfigProvider>
              </div>
              <div>
                <div style={{marginTop:'32px'}}>
                  {(x1.isNull(this.state.stock_in_sts_cd) || this.state.stock_in_sts_cd == 'CR') && this.hasFunctionSubmit() &&
                  <Fragment>
                    <Button type="primary" onClick={this.handleSave} loading={this.state.loadingSubmit && this.state.sbmt_flg=='N'}>信息暂存</Button>
                    <div style={{display:'inline-block', width:'16px'}}></div>
                    <Popconfirm title={"确定要入库吗？"} onConfirm={() => this.handleSubmit()}>
                      <Button type="primary" loading={this.state.loadingSubmit && this.state.sbmt_flg=='Y'} style={{marginRight:'16px'}}>入库完成</Button>
                    </Popconfirm>
                  </Fragment>
                  }

                  {/*打单*/}
                  {x1.hasValue(this.state.stock_in_sts_cd) && this.state.stock_in_sts_cd != 'CR' && this.hasFunctionConfirm() &&
                  <Fragment>
                    <Button loading={this.state.loadingPrint} style={{marginRight:'16px'}} onClick={() => this.handlePrint()}>打印入库单</Button>
                  </Fragment>
                  }

                  {(this.state.stock_in_sts_cd == 'SU') && this.hasFunctionConfirm() &&
                  <Fragment>
                    <Popconfirm title={"确认入库没有错误吗？"} onConfirm={() => this.handleConfirm()}>
                      <Button type="primary" loading={this.state.loadingConfirm} style={{marginRight:'8px'}} >入库确认</Button>
                    </Popconfirm>
                    <Checkbox checked={this.state.checkedItem} onChange={this.onChangeCheckboxItem}>{'确认单据信息正确（品类、数量、价格等）'}</Checkbox>
                    <span style={{display:'inline-block', marginLeft:'16px', marginRight:'4px'}}>{'装卸款: '}</span>
                    <Radio.Group value={this.state.pre_paid_load_tp_cd} onChange={this.onChangeRadioPayment}>
                      <Radio value={'F'}>不用支付</Radio>
                      <Radio value={'U'}>未支付</Radio>
                      <Radio value={'W'}>微信</Radio>
                      <Radio value={'C'}>现金</Radio>
                    </Radio.Group>

                    <Popconfirm title={"确认撤销入库吗？"} onConfirm={() => this.handleReject()}>
                      <Button loading={this.state.loadingReject} style={{marginLeft:'16px'}}>驳回修改</Button>
                    </Popconfirm>
                  </Fragment>
                  }
                  {(this.state.stock_in_sts_cd == 'CP') && this.hasFunctionConfirmReject() &&
                  <Fragment>
                    <span style={{display:'inline-block', marginLeft:'16px', marginRight:'4px'}}>{'装卸费收款: '}</span>
                    <Radio.Group value={this.state.pre_paid_load_tp_cd} disabled={true}>
                      <Radio value={'F'}>不用支付</Radio>
                      <Radio value={'U'}>未支付</Radio>
                      <Radio value={'W'}>微信</Radio>
                      <Radio value={'C'}>现金</Radio>
                    </Radio.Group>

                    <Popconfirm title={"确认撤销入库吗？"} onConfirm={() => this.handleReject()}>
                      <Button loading={this.state.loadingReject} style={{marginLeft:'16px'}}>驳回修改</Button>
                    </Popconfirm>
                  </Fragment>
                  }
                </div>
              </div>

            </Form>
          </Spin>
        </Card>

        {/*编辑单价*/}
        {this.state.isShowPopupPrice &&
        <Modal visible={true} footer={null} onCancel={()=>this.cancelPopupPrice()}>
          <StockInDetailPriceEntryPopup stockInLine={this.state.selStockInLine} onSubmit={(input)=>this.onSubmitPrice(input)}/>
        </Modal>
        }

      </BasicLayout>
    );
  };

}

StockInDetail = Form.create({})(StockInDetail);
export default StockInDetail;
