import React, {PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class RentOwnerDetail extends PureComponent {
  state = {
    loading: false,
  };

  componentDidMount() {
    const {form} = this.props;

    var rent_ownr_id = x1.router.getParamIn().rent_ownr_id;
    if (rent_ownr_id > '') {
      // 更新
      form.setFieldsValue({rent_ownr_id: rent_ownr_id});
      this.search(rent_ownr_id);
    } else {
      // 新增
    }
  };

  hasFunctionEdit(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH) || x1.user.hasRole(x1.ROLE.GEN_MGR)){
      return true;
    }
    return false;
  }

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/rentOwner/update",
        ...fieldsValue,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.rent_ownr_id)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
            this.search(data.rent_ownr_id);
          }
        },
      };
      x1.request(params);
    });
  };

  search(rent_ownr_id) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/rentOwner/list",
      rent_ownr_id: rent_ownr_id,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="货主编码">
                    {form.getFieldDecorator('rent_ownr_id')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="货主名称">
                    {form.getFieldDecorator('rent_ownr_nm', {
                      rules: [{
                        required: true,
                        message: '请输入货主名称',
                      }]
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="联系人">
                    {form.getFieldDecorator('ctac_psn_nm', {
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="手机号">
                    {form.getFieldDecorator('rent_ownr_tel_num', {
                    })(
                      <Input style={{width: '300px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="s商品备注">
                    {form.getFieldDecorator('item_nm', {
                      rules: [{
                      }]
                    })(
                      <Input style={{width: '500px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="备注">
                    {form.getFieldDecorator('ownr_memo_txt', {
                    })(
                      <Input style={{width: '500px'}} />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="押金收取日">
                    {form.getFieldDecorator('dep_rcv_dt', {
                    })(
                      <DatePicker style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="押金">
                    {form.getFieldDecorator('dep_rcv_amt', {
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="状态">
                    {form.getFieldDecorator('rent_ownr_sts_cd', {
                    })(
                      <Radio.Group buttonStyle="solid" >
                        <Radio.Button value="AC">可用</Radio.Button>
                        <Radio.Button value="IN">不可用</Radio.Button>
                      </Radio.Group>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="更新人">
                    {form.getFieldDecorator('upd_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="更新时间">
                    {form.getFieldDecorator('upd_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>

                  <FormItem {...submitItemLayout}>
                    {this.hasFunctionEdit() &&
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                    }
                  </FormItem>
                </Col>
                <Col span={12}>
                  {/*TODO*/}
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

RentOwnerDetail = Form.create({})(RentOwnerDetail);
export default RentOwnerDetail;
