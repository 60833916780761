import React, {Fragment, PureComponent} from 'react';
import {Radio, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import ManagerOvertimeDetail from "./ManagerOvertimeDetail";

const FormItem = Form.Item;
const {Option} = Select;
const {MonthPicker} = DatePicker;

class ManagerOvertimeList extends PureComponent {
  state = {
    loading: false,
    loadingDownload: false,
    pagination: {},
    listItem: [],
    listWh: [],
    listOwnr: [],
    listWhMgrUsr: [],
    listSts: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {work_mth, work_dt, wh_mgr_usr_id, work_nm} = state;
        form.setFieldsValue({work_mth, work_dt, wh_mgr_usr_id, work_nm});
        this.refreshPage();
      }
    }

    if(!isBack) {
      form.setFieldsValue({
        temp_job_ts: x1.getSystemDate(),
      });
      this.initSearch();
      this.initWarehouseList();
      this.initOwnerList();
      this.initStatusList();
      this.initWhMgrUsrList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initWhMgrUsrList() {
    const params = {
      apiUrl: "/api/tempEmp/list",
      // usr_role_cd: 'R004',
      temp_emp_sts_cd: 'AC',
      excl_all_flg: 'Y',
      success: (data) => {
        this.setState({
          listWhMgrUsr: data,
        });
      },
    };
    x1.request(params);
  }

  hasFunctionNew(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.MGR) || x1.user.hasRole(x1.ROLE.ORD)){
      return true;
    }
    return false;
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initStatusList() {
    const params = {
      apiUrl: "/api/stockInStatus/list",
      success: (data) => {
        this.setState({
          listSts: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    const params = {
      apiUrl: "/api/mgrWorkOver/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };
  deleteLine(record) {
    const params = {
      apiUrl: "/api/mgrWorkOver/delete",
      mgr_work_over_id: record.mgr_work_over_id,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/ManagerOvertimeDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/ManagerOvertimeDetail', record, x1.getStateSnapshort(this));
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/mgrWorkOver/download",
      ...values,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  columns = [
    {title: '保管员', dataIndex: 'wh_mgr_usr_nm'},
    {title: '工作名称', dataIndex: 'work_nm'},
    {title: '工作日期',
      render: (text, record) => (
          <div>{x1.date2Text(record.work_dt)}</div>
      )
    },
    {title: '开始时间', dataIndex: 'work_start_tm',
      render: (text, record) => (
          <div>{x1.time2Text(record.work_start_tm)}</div>
      )
    },
    {title: '结束时间', dataIndex: 'work_end_tm',
      render: (text, record) => (
          <div>{x1.time2Text(record.work_end_tm)}</div>
      )},
    {title: '工时(H)', dataIndex: 'work_hr_amt'},
    {title: '单价', dataIndex: 'unit_prc_amt'},
    {title: '金额', dataIndex: 'work_fee_amt'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          <Popconfirm title={"确定要删除记录【" + record.temp_job_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'}/>
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="月份">
                {form.getFieldDecorator('work_mth', {
                })(<MonthPicker style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="日期">
                {form.getFieldDecorator('work_dt', {
                })(
                    <DatePicker style={{width: '130px'}} allowClear={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="员工">
                {form.getFieldDecorator('wh_mgr_usr_id', {})(
                    <Select style={{width: '150px'}} allowClear={true}>
                      {this.state.listWhMgrUsr.map((item) => (
                          <Option key={item.temp_emp_id}>{item.temp_emp_nm}</Option>
                      ))}
                    </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="工作名称">
                {form.getFieldDecorator('work_nm', {
                })(
                    <Input style={{width: '150px'}} allowClear={true}/>
                )}
              </FormItem>
            </div>
            <div style={{marginBottom:'16px', display:'flex', justifyContent:'flex-end'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              {this.hasFunctionNew() &&
              <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
              }
              <span style={{paddingRight:'16px'}}></span>
              <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>
            </div>
          </Form>
          <Table
            rowKey={'temp_job_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

ManagerOvertimeList = Form.create({})(ManagerOvertimeList);
export default ManagerOvertimeList;
