import React, {Fragment, PureComponent} from 'react';
import {Modal, Tag, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import RentMonthChargeList from "./RentMonthChargeList";
import RentInvoicePaymentEntryPopup from "./RentInvoicePaymentEntryPopup";

const FormItem = Form.Item;
const {Option} = Select;
const {MonthPicker} = DatePicker;

class RentOrderInvoiceList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],

    listWh: [],
    listOwnr: [],

    isShowPopup: false,
    selIndex: -1,
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {inv_from_mth, inv_to_mth, ownr_nm} = state;
        form.setFieldsValue({inv_from_mth, inv_to_mth});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initFromNoneBack();
      this.initOwnerList();
    }
  };

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initFromNoneBack(){
    const {form} = this.props;
    if(x1.report.hasConditionDate()){
      // 取上个画面的区间
      x1.report.loadConditionDate(this);
    } else {
      // 默认期间为上个月
      var toDt = x1.getCurrentMoment().subtract(1, 'months');;
      var fromDt = x1.getCurrentMoment().subtract(1, 'months');
      form.setFieldsValue({
        inv_from_mth: fromDt,
        inv_to_mth: toDt,
      });
    }
    this.initSearch();
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/rentOrder/listInvoice",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        x1.generateListIndex(data);
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/rentOrder/downloadListInvoice",
      ...values,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  downloadInvoice(record) {
    const params = {
      apiUrl: "/api/rentOrder/downloadInvoice",
      rent_inv_num: record.rent_inv_num,
      inv_mth: record.inv_mth,
      inv_from_mth: record.inv_mth,
      inv_to_mth: record.inv_mth,
      // ownr_nm: record.ownr_nm,
      rent_ownr_id: record.rent_ownr_id,
      usr_id: x1.user.getCurrentUserId(),
      usr_nm: x1.user.getCurrentUser().usr_nm,
      success: (data) => {
        x1.download(data.file_url);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/rentOwner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseLocationList() {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(x1.isNull(values.wh_cd)){
      this.setState({listWhLoc:[]});
      return;
    }
    const params = {
      apiUrl: "/api/warehouseLocation/list",
      wh_cd: values.wh_cd,
      success: (data) => {
        this.setState({
          listWhLoc: data,
        });
      },
    };
    x1.request(params);
  };

  createInvoice(record) {
    const params = {
      apiUrl: "/api/rentInvoice/createInvoice",
      inv_mth: record.inv_mth,
      rent_ownr_id: record.rent_ownr_id,
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  deleteInvoice(record) {
    const params = {
      apiUrl: "/api/rentInvoice/delete",
      rent_inv_num: record.rent_inv_num,
      inv_mth: record.inv_mth,
      rent_ownr_id: record.rent_ownr_id,
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  columns = [
    {title: '月份', render: (text, record) => (
        <div>{x1.month2Text(record.inv_mth)}</div>
      ),
    },
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '电话', dataIndex: 'ownr_tel_num'},
    {title: '期间费用总计/元', dataIndex: 'tot_inv_amt'},
    {title: '仓储费总计/元', dataIndex: 'tot_rent_inv_amt'},
    {title: '其它费总计/元', dataIndex: 'tot_rent_oth_amt'},
    {title: '仓储费', render: (text, record) => (
        <div>
          {record.listRent.map((item)=>(
            <div>
              <div>{x1.date2Text(item.inv_from_dt) + ' ~ ' + x1.date2Text(item.inv_to_dt) + ': ' + item.inv_days + '天【 ' + item.rent_inv_amt + ' 元】'}</div>
              <div>{'账单天数 ' + item.inv_days + '天' + '， 实际天数 ' + item.rent_days + '天'}</div>
            </div>
          ))}
        </div>
      ),
    },
    {title: '其它费用', render: (text, record) => (
        <div>
          {record.listOth.map((item)=>(
            <div>
              {x1.date2Text(item.chrg_dt) + ': ' + item.rent_mth_chrg_memo_txt + '【' + item.rent_mth_oth_amt + ' 元】'}
            </div>
          ))}
        </div>
      ),
    },
    {title: '押金/元', dataIndex: 'tot_dep_amt'},
    {title: '账单状态', render: (text, record) => (
        <div>{record.exist_inv_flg=='Y'?(
          <Tag color="green">已生成</Tag>
        ):(
          <Tag color="red">未生成</Tag>
        )}</div>
      ),
    },
    // {title: '预付金额', dataIndex: 'pre_paid_amt'},
    {title: '收款日', render: (text, record) => (
        <div>{x1.date2Text(record.paid_dt)}</div>
      ),
    },
    {title: '收款金额', dataIndex: 'paid_amt'},
    // {title: '收款人', dataIndex: 'paid_entry_usr_nm'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          {record.exist_inv_flg != 'Y' &&
          <Popconfirm title={"确定要生成账单【" + record.ownr_nm + ' - ' + x1.month2Text(record.inv_mth) + "】"} onConfirm={() => this.createInvoice(record)}>
            <a style={{marginRight: '10px'}}>生成账单</a>
          </Popconfirm>
          }
          {x1.hasValue(record.rent_inv_num) && this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除已生成的账单【" + record.ownr_nm + ' - ' + x1.month2Text(record.inv_mth) + "】"} onConfirm={() => this.deleteInvoice(record)}>
            <a style={{marginRight: '10px'}}>删除账单</a>
          </Popconfirm>
          }
          {x1.hasValue(record.rent_inv_num) && record.tot_inv_amt > 0 &&
          <div>
            <a style={{marginRight: '10px'}} onClick={() => this.receivePayment(record)}>收款</a>
          </div>
          }
          {x1.hasValue(record.rent_inv_num) &&
          <div>
            <a style={{marginRight: '10px'}} onClick={() => this.downloadInvoice(record)}>下载账单</a>
          </div>
          }
        </Fragment>
      ),
    },
  ];

  onChangeDate(){
  }

  // ----------------------------------
  // 收款弹出框
  // ----------------------------------
  receivePayment(record) {
    this.state.selIndex = record.index;
    this.setState({isShowPopup: true});
  }

  onSubmitReceivePayment(input){
    this.setState({isShowPopup: false});
    const params = {
      loading: true,
      apiUrl: "/api/rentInvoice/receivePayment",
      rent_inv_num: input.rent_inv_num,
      paid_amt: input.paid_amt,
      paid_dt: input.paid_dt,
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleCancelPopup(){
    this.setState({isShowPopup: false});
  }

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} hideRequiredMark={true} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <div>
              <FormItem {...formItemLayout} label="账单月份">
                {form.getFieldDecorator('inv_from_mth', {
                  rules: [{
                    required: true,
                    message: '请输入开始月份',
                  }]
                })(
                  <MonthPicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="-" colon={false}>
              {form.getFieldDecorator('inv_to_mth', {
                rules: [{
                  required: true,
                  message: '请输入结束月份',
                }]
              })(
              <MonthPicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
              )}
              </FormItem>
              <FormItem {...formItemLayout} label="货主">
                {form.getFieldDecorator('rent_ownr_id')(
                  <Select style={{width: '150px'}} allowClear={true}
                          showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {this.state.listOwnr.map((item) => (
                      <Option key={item.rent_ownr_id}>{item.rent_ownr_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              {/*<FormItem {...formItemLayout} label="货主">*/}
                {/*{form.getFieldDecorator('ownr_nm')(*/}
                  {/*<Input style={{width: '100px'}}/>*/}
                {/*)}*/}
              {/*</FormItem>*/}
              {/*<FormItem {...formItemLayout} label="仓库">*/}
                {/*{form.getFieldDecorator('wh_cd')(*/}
                  {/*<Select style={{width: '100px'}} allowClear={true}>*/}
                    {/*{this.state.listWh.map((item) => (*/}
                      {/*<Option key={item.wh_cd}>{item.wh_nm}</Option>*/}
                    {/*))}*/}
                  {/*</Select>*/}
                {/*)}*/}
              {/*</FormItem>*/}
              <FormItem {...formItemLayout} label="账单生成状态">
                {form.getFieldDecorator('exist_inv_flg')(
                  <Select style={{width: '100px'}} allowClear={true}>
                    <Option key={'Y'}>{'已生成'}</Option>
                    <Option key={'N'}>{'未生成'}</Option>
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="收款状态">
                {form.getFieldDecorator('paid_flg')(
                  <Select style={{width: '100px'}} allowClear={true}>
                    <Option key={'Y'}>{'已收款'}</Option>
                    <Option key={'N'}>{'未收款'}</Option>
                  </Select>
                )}
              </FormItem>
            </div>
            <div style={{display:'flex', justifyContent:'flex-end'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              <span style={{paddingRight:'16px'}}></span>
              <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>
            </div>
          </Form>
          <Table
            // rowKey={'ownr_nm'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>

        {this.state.isShowPopup &&
        <Modal visible={true} footer={null} onCancel={()=>this.handleCancelPopup()}>
          <RentInvoicePaymentEntryPopup inv={this.state.listItem[this.state.selIndex]} onSubmit={(input)=>this.onSubmitReceivePayment(input)}/>
        </Modal>
        }

      </BasicLayout>

    );
  };
}

RentOrderInvoiceList = Form.create({})(RentOrderInvoiceList);
export default RentOrderInvoiceList;
