import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col, Popconfirm, ConfigProvider, Table} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class ShipFeeConfigDetail extends PureComponent {
  state = {
    loading: false,
    whInfo: {},
    listPrc:[],
  };

  componentDidMount() {
    const {form} = this.props;

    var item = x1.router.getParamIn();
    this.state.whInfo = item;
    this.setState({
      whInfo: item,
    });
    this.search();
  };

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      const params = {
        apiUrl: "/api/shipFeeConfig/updateByWarehouse",
        wh_cd: this.state.whInfo.wh_cd,
        listPrc: x1.mergeDeep(this.state.listPrc, fieldsValue.listPrc),
        success: (data) => {
          form.setFieldsValue(data);
          x1.showMessage('提交成功');
          this.search();
        },
      };
      x1.request(params);
    });
  };

  hasFunctionUpdate(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN)){
      return true;
    }
    return false;
  }

  search() {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/shipFeeConfig/list",
      wh_cd: this.state.whInfo.wh_cd,
      success: (data) => {
        let values = {listPrc: data};
        this.setState(values);
      },
    };
    x1.request(params);
  };

  removeLine(index){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listPrc = x1.mergeDeep(this.state.listPrc, formValues.listPrc);
    listPrc.splice(index, 1);
    this.setState({listPrc:listPrc});
  };

  newLine(){
    const { form } = this.props;
    let formValues = form.getFieldsValue();
    let listPrc = x1.mergeDeep(this.state.listPrc, formValues.listPrc);
    listPrc.push({});
    this.setState({listPrc:listPrc});
  };

  render() {
    const {form} = this.props;

    const rowInputStyle = {
      marginBottom: '0'
    };

    var customizeRenderEmpty = () => {
        return <div>没有数据</div>
      }
    ;

    // ------------------------
    // 站点
    // ------------------------
    const columnsStation = [
      {
        title: '开始重量KG', dataIndex: 'from_kg_amt',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listPrc[${index}].from_kg_amt`, {
              rules: [{required: true, message:'请输入开始重量'}],
              initialValue: text
            })(<Input/>)}</FormItem>
          );
        },
      },
      {
        title: '结束重量KG', dataIndex: 'to_kg_amt',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listPrc[${index}].to_kg_amt`, {
              rules: [{required: true, message:'请输入结束重量'}],
              initialValue: text
            })(<Input/>)}</FormItem>
          );
        },
      },
      {
        title: '区间单价',
        dataIndex: 'ship_unit_prc_amt',
        render: (text, record, index) => {
          return (
            <FormItem style={rowInputStyle}>{form.getFieldDecorator(`listPrc[${index}].ship_unit_prc_amt`, {
              rules: [{required: true, message:'请输入区间单价'}],
              initialValue: text
            })(<Input/>)}</FormItem>
          );
        },
      },
      {
        title: '操作',
        key: 'action',
        render: (text, record, index) => {
          return (
            <span>
              {this.hasFunctionUpdate() &&
              <Popconfirm title="是否要删除此行？" onConfirm={() => this.removeLine(index)}>
                <a>删除</a>
              </Popconfirm>
              }
            </span>
          );
        },
      },
    ];

    const formItemLayout = {
      labelCol: {span: 8},
      wrapperCol: {span: 16},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 8},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <div className='box_cc' style={{marginBottom:'16px'}}>
                <div>{'仓库：' + this.state.whInfo.wh_nm}</div>
              </div>

              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowKey={'ship_fee_conf'}
                  columns={columnsStation}
                  dataSource={this.state.listPrc}
                  pagination={false}
                  size={'middle'}
                />
                {this.hasFunctionUpdate() &&
                <Fragment>
                  <Button style={{ marginTop: 16, marginBottom: 8 }} icon="plus" onClick={()=>this.newLine()}>新增价格</Button>
                  <div style={{marginTop:'32px'}}>
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                  </div>
                </Fragment>
                }
              </ConfigProvider>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

ShipFeeConfigDetail = Form.create({})(ShipFeeConfigDetail);
export default ShipFeeConfigDetail;
