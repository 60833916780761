import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Radio, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import RentMonthChargeList from "./RentMonthChargeList";

const FormItem = Form.Item;
const {Option} = Select;

class RentWarehouseLocationList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],

    listWh: [],
    listWhLoc: [],
    listOwnr: [],

    loadingDownload: false,
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {rent_ownr_id, wh_cd, wh_loc_cd, actv_rent_loc_flg} = state;
        form.setFieldsValue({rent_ownr_id, wh_cd, wh_loc_cd, actv_rent_loc_flg});
        this.refreshPage();
      }
    }

    if(!isBack) {
      var paramIn = x1.router.getParamIn();
      form.setFieldsValue({
        actv_rent_loc_flg: 'Y',
        rent_ownr_id: paramIn.rent_ownr_id,
      });
      this.state.rent_ownr_id = paramIn.rent_ownr_id;
      this.initSearch();
      this.initWarehouseList();
      this.initOwnerList();
    }
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/rentOwner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,

        });
      },
    };
    x1.request(params);
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/rentWarehouseLocation/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    var param = {
      rent_ownr_id: this.state.rent_ownr_id,
    }
    x1.router.push("/RentWarehouseLocationDetail", param, x1.getStateSnapshort(this));
  };

  copyDate(record) {
    var param = {
      rent_ownr_id: this.state.rent_ownr_id,
      rent_from_dt: record.rent_from_dt,
      rent_to_dt: record.rent_to_dt,
      wh_cd: record.wh_cd,
    }
    x1.router.push("/RentWarehouseLocationDetail", param, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/RentWarehouseLocationDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/rentWarehouseLocation/delete",
      rent_wh_loc_pk: record.rent_wh_loc_pk,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  onChanageWarehouse(wh_cd){
    const {form} = this.props;
    form.setFieldsValue({'wh_cd': wh_cd});
    this.initWarehouseLocationList();
  };

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseLocationList() {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(x1.isNull(values.wh_cd)){
      this.setState({listWhLoc:[]});
      return;
    }
    const params = {
      apiUrl: "/api/warehouseLocation/list",
      wh_cd: values.wh_cd,
      success: (data) => {
        this.setState({
          listWhLoc: data,
        });
      },
    };
    x1.request(params);
  };

  columns = [
    {title: '货主', dataIndex: 'rent_ownr_nm'},
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '库位', dataIndex: 'wh_loc_nm'},
    {title: '租用开始日', render: (text, record) => (
        <div>{x1.date2Text(record.rent_from_dt)}</div>
      ),
    },
    {title: '租用结束日', render: (text, record) => (
        <div>{x1.date2Text(record.rent_to_dt)}</div>
      ),
    },
    {title: '更新时间', dataIndex: 'upd_ts'},
    {title: '更新人', dataIndex: 'upd_usr_nm'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          <a onClick={() => this.copyDate(record)} style={{marginRight: '10px'}}>复制日期</a>
          {this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除库房租用记录【" + record.rent_ownr_nm  + ', ' + record.wh_loc_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'} />
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="货主">
                {form.getFieldDecorator('rent_ownr_id')(
                  <Select style={{width: '150px'}} allowClear={true} disabled={x1.hasValue(this.state.rent_ownr_id)}
                          showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {this.state.listOwnr.map((item) => (
                      <Option key={item.rent_ownr_id}>{item.rent_ownr_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="仓库">
                {form.getFieldDecorator('wh_cd', {
                })(
                  <Select style={{width: '150px'}} allowClear={true} onChange={(value)=>this.onChanageWarehouse(value)}>
                    {this.state.listWh.map((item) => (
                      <Option key={item.wh_cd}>{item.wh_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="库房">
                {form.getFieldDecorator('wh_loc_cd', {
                })(
                  <Select style={{width: '150px'}} allowClear={true}>
                    {this.state.listWhLoc.map((item) => (
                      <Option key={item.wh_loc_cd}>{item.wh_loc_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </div>
            <div className={'box_vc'} style={{marginBottom: '16px'}}>
              <div className={'box_bd'}>
                <FormItem {...formItemLayout} label="状态">
                  {form.getFieldDecorator('actv_rent_loc_flg', {
                  })(
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="N">全部</Radio.Button>
                      <Radio.Button value="Y">当前</Radio.Button>
                    </Radio.Group>
                  )}
                </FormItem>
              </div>
              <div className={'box_ft'}>
                <Button type="primary" onClick={this.handleSearch}>查询</Button>
                <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
              </div>
            </div>
          </Form>
          <Table
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

RentWarehouseLocationList = Form.create({})(RentWarehouseLocationList);
export default RentWarehouseLocationList;
