import React, {Fragment, PureComponent} from 'react';
import {Modal, Tag, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import PrePaymentEntryPopup from "./PrePaymentEntryPopup";

const FormItem = Form.Item;
const {Option} = Select;

class ReportByOwnerStockInList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listOwnr: [],

    // 收款Popup
    isShowPopup: false,

    loadingDownload: false,
  };

  componentDidMount() {
    const {form} = this.props;
    var param = x1.router.getParamIn();
    this.state.ownr_id = param.ownr_id;
    form.setFieldsValue({...param});
    this.initSearch();
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/invoice/reportByOwnerStockIn",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      ownr_id: this.state.ownr_id,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
        // 计算合计
        var totInvAmt = 0;
        var totPrePaidAmt = 0;
        for(var i=0; i<data.length; i++){
          var item = data[i];
          totInvAmt += parseFloat(item.tot_inv_amt);
          totPrePaidAmt += parseFloat(x1.nvl(item.pre_paid_amt,"0"));
        }
        var totWaitPmtAmt = totInvAmt - totPrePaidAmt;
        form.setFieldsValue({
          tot_inv_amt: totInvAmt.toFixed(3),
          tot_pre_paid_amt: totPrePaidAmt.toFixed(3),
          tot_wait_pmt_amt: totWaitPmtAmt.toFixed(3),
        });
        this.setState({
          tot_wait_pmt_amt: totWaitPmtAmt.toFixed(3),
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/invoice/downloadReportByOwnerStockIn",
      ...values,
      ownr_id: this.state.ownr_id,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  onChangeInvoiceToDate(dt){
    setTimeout(()=>{
      this.initSearch();
    },1);
  }

  openStockInDetail(record) {
    x1.router.push('/StockInDetail', record);
  };

  openDetail(record) {
    x1.router.push('/InvoiceWaitDetail', record);
  };

  openPrePaymentDetail(record) {
    x1.router.push('/StockInPrePaymentDetail', record);
  };

  handlePrePaymentEntry = e => {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(x1.isNull(values.inv_to_dt)){
      x1.showError('请输入结算日期');
      return;
    }
    this.openPrePaymentEntry();
  };

  openPrePaymentEntry() {
    this.setState({isShowPopup: true});
  }

  onSubmitEntryPrePayment(input){
    const {form} = this.props;
    var values = form.getFieldsValue();
    this.setState({isShowPopup: false});
    const params = {
      loading: true,
      apiUrl: "/api/prePayment/update",
      ownr_id: this.state.ownr_id,
      inv_to_dt: values.inv_to_dt,
      paid_amt: input.paid_amt,
      paid_dt: input.paid_dt,
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
    };
    x1.request(params);
  };

  handleCancelPopup(){
    this.setState({isShowPopup: false});
  }

  columns = [
    {title: '账单类型', dataIndex: 'rpt_inv_tp_nm'},
    // {title: '商品', dataIndex: 'item_nm'},
    // {title: '单位重量', dataIndex: 'unit_kg_amt'},
    {title: '商品/单位重量',
      render: (text, record) => (
        <div>
          <div>{record.item_nm}</div>
          <div>{record.unit_kg_amt}</div>
        </div>
      ),
    },
    {title: '入库日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.stock_in_dt)}</div>
      ),
    },
    {title: '入库数量', dataIndex: 'stock_in_qty'},
    {title: <div><div>{'最后出库日期'}</div><div>{'/结算日'}</div></div>, dataIndex: 'last_stock_out_dt_txt'},
    {title: '出库数量', dataIndex: 'tot_actl_out_qty'},
    {title: '库存量', dataIndex: 'tot_actl_rmn_qty'},
    {title: '非自提数量', dataIndex: 'tot_ship_qty'},
    {title: '预冷费',
      render: (text, record) => (
        <div>
          <div>{record.pre_cool_amt}</div>
          <div>{'(单价' + record.pre_cool_unit_prc_amt + ')'}</div>
        </div>
      ),
    },
    {title: '冷藏费',
      render: (text, record) => (
        <div>
          <div>{record.store_amt}</div>
          <div>{'(单价' + record.store_unit_prc_amt + ')'}</div>
        </div>
      ),
    },
    {title: '装卸费',
      render: (text, record) => (
        <div>
          <div>{record.load_amt}</div>
          <div>{'(单价' + record.load_unit_prc_amt + ')'}</div>
        </div>
      ),
    },
    {title: '转场费',
      render: (text, record) => (
        <div>
          <div>{record.ship_amt}</div>
          <div>{'(单价' + record.ship_unit_prc_amt + ')'}</div>
        </div>
      ),
    },
    {title: '工本费',
      render: (text, record) => (
        <div>
          <div>{record.card_unit_amt}</div>
          <div>{'(单价' + record.card_unit_prc_amt + ')'}</div>
        </div>
      ),
    },
    {title: '其它费用', dataIndex: 'oth_amt'},
    {title: '费用总计', dataIndex: 'tot_inv_amt'},
    {title: '预付金额', dataIndex: 'pre_paid_amt'},
    {title: '应结金额', dataIndex: 'wait_pmt_amt'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <div>
            <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>账单详情</a>
          </div>
          <div>
            <a onClick={() => this.openPrePaymentDetail(record)} style={{marginRight: '10px'}}>预付款详情</a>
          </div>
          <div>
            <a onClick={() => this.openStockInDetail(record)} style={{marginRight: '10px'}}>入库单详情</a>
          </div>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} >
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="货主">
                {form.getFieldDecorator('ownr_nm', {
                })(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="额度">
                {form.getFieldDecorator('cr_limit_amt', {
                })(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="应付">
                {form.getFieldDecorator('ar_amt', {
                })(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="信用余额">
                {form.getFieldDecorator('cr_bal_amt', {
                })(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <Button type="primary" onClick={this.handleSearch}>刷新</Button>
            </div>

            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="结算日期">
                {form.getFieldDecorator('inv_to_dt', {
                })(
                  <DatePicker style={{width: '150px'}} onChange={(dt)=>this.onChangeInvoiceToDate(dt)} />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="结算金额">
                {form.getFieldDecorator('tot_inv_amt', {
                })(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="预付金额">
                {form.getFieldDecorator('tot_pre_paid_amt', {
                })(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="应结金额">
                {form.getFieldDecorator('tot_wait_pmt_amt', {
                })(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <Button type="primary" onClick={this.handlePrePaymentEntry}>指定结算日收款</Button>
              <div style={{width:'16px'}}></div>
              <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>
            </div>

          </Form>
          <Table
            rowKey={'stock_in_line_full_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>

        {this.state.isShowPopup &&
        <Modal visible={true} footer={null} onCancel={()=>this.handleCancelPopup()}>
          <PrePaymentEntryPopup inv={this.state} onSubmit={(input)=>this.onSubmitEntryPrePayment(input)}/>
        </Modal>
        }

      </BasicLayout>

    );
  };
}

ReportByOwnerStockInList = Form.create({})(ReportByOwnerStockInList);
export default ReportByOwnerStockInList;
