import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class XxPalletUsageDetail extends PureComponent {
  state = {
    loading: false,
    listUsrRole: [],
      xx_plt_usg_pk: '',
  };

  componentDidMount() {
    const {form} = this.props;

    var xx_plt_usg_pk = x1.router.getParamIn().xx_plt_usg_pk;
    if (xx_plt_usg_pk > '') {
      this.setState({xx_plt_usg_pk: xx_plt_usg_pk});
      form.setFieldsValue({xx_plt_usg_pk: xx_plt_usg_pk});
      this.search(xx_plt_usg_pk);
    } else {
    }
  };

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      const params = {
        apiUrl: this.state.xx_plt_usg_pk > '' ? "/api/xxPltUsg/update" : "/api/xxPltUsg/insert" ,
        ...fieldsValue,
          xx_plt_usg_pk: this.state.xx_plt_usg_pk,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(this.state.xx_plt_usg_pk == ''){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
      };
      x1.request(params);
    });
  };

  search(xx_plt_usg_pk) {
    const {form} = this.props;
    const params = {
      apiUrl: "/api/xxPltUsg/list",
      curPage: 0,
      pageSize: 1,
      xx_plt_usg_pk: xx_plt_usg_pk,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };


  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 3},
      wrapperCol: {span: 8},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 3},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>
                <FormItem {...formItemLayout} label="非标托盘数">
                    {form.getFieldDecorator('non_std_plt_cnt', {
                        rules: [{
                            required: true,
                            message: '请输入非标托盘数',
                        }]
                    })(
                        <Input style={{width: '150px'}}/>
                    )}
                </FormItem>
              <FormItem {...formItemLayout} label="标准托盘数">
                {form.getFieldDecorator('std_plt_cnt', {
                  rules: [{
                    required: true,
                    message: '请输入标准托盘数',
                  }]
                })(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
                <FormItem {...formItemLayout} label="数字标准托盘数">
                    {form.getFieldDecorator('digit_plt_cnt', {
                        rules: [{
                            required: true,
                            message: '请输入数字标准托盘数',
                        }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="标准托盘使用率">
                    {form.getFieldDecorator('std_plt_usg_rate', {
                        rules: [{
                            required: true,
                            message: '请输入标准托盘使用率',
                        }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                </FormItem>


              <FormItem {...submitItemLayout}>
                <Button type="primary" onClick={this.handleSubmit}>提交</Button>
              </FormItem>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

XxPalletUsageDetail = Form.create({})(XxPalletUsageDetail);
export default XxPalletUsageDetail;
