import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table} from 'antd';
import {message} from "antd/lib/index";
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class UserDataSecurityList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listCourt: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var paramIn = x1.router.getParamIn();
    var usr_id = paramIn.usr_id;
    var usr_nm = paramIn.usr_nm;
    this.state.usr_id = usr_id;
    this.state.usr_nm = usr_nm;
    form.setFieldsValue({usr_nm: usr_nm});
    this.initWarehouseList();
    this.initSearch();
  };

  hasFunctionUpdate(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listCourt: data,
        });
      },
    };
    x1.request(params);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/userDataSecurity/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      usr_id: this.state.usr_id,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    var param = {
      usr_id: this.state.usr_id,
      usr_nm: this.state.usr_nm,
    }
    x1.router.push("/UserDataSecurityDetail", param);
  };

  openDetail(record) {
    x1.router.push('/UserDataSecurityDetail', record);
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/userDataSecurity/delete",
      wh_cd: record.wh_cd,
      usr_id: record.usr_id,
      success: (data) => {
        this.initSearch();
        message.success('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  columns = [
    {title: '姓名', dataIndex: 'usr_nm'},
    {title: '手机', dataIndex: 'phone_num'},
    {title: '仓库', dataIndex: 'wh_nm'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          {/*<a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>*/}
          {this.hasFunctionUpdate() &&
            <Popconfirm title={"确定要删除授权【" + record.wh_nm + "】"} onConfirm={() => this.deleteLine(record)}>
              <a style={{marginRight: '10px'}}>删除</a>
            </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="姓名">
              {form.getFieldDecorator('usr_nm')(
                <Input style={{width: '150px'}} disabled={true}/>
              )}
            </FormItem>
            <FormItem label="仓库">
              {form.getFieldDecorator('wh_cd')(
                <Select style={{width: '150px'}} allowClear={true}
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {this.state.listCourt.map((item) => (
                    <Option key={item.wh_cd}>{item.wh_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            {this.hasFunctionUpdate() &&
              <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft: '16px'}}>新增</Button>
            }
          </Form>
          <Table
            rowKey={'id'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>
    );
  };
}

UserDataSecurityList = Form.create({})(UserDataSecurityList);
export default UserDataSecurityList;
