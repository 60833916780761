import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class ItemUnitDetail extends PureComponent {
  state = {
    loading: false,
  };

  componentDidMount() {
    const {form} = this.props;

    var item_unit_cd = x1.router.getParamIn().item_unit_cd;
    if (item_unit_cd > '') {
      // 更新
      form.setFieldsValue({item_unit_cd: item_unit_cd});
      this.search(item_unit_cd);
    } else {
      // 新增
    }
  };

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/itemUnit/update",
        ...fieldsValue,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.item_unit_cd)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
      };
      x1.request(params);
    });
  };

  search(item_unit_cd) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/itemUnit/list",
      item_unit_cd: item_unit_cd,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="单位编码">
                    {form.getFieldDecorator('item_unit_cd')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="单位名称">
                    {form.getFieldDecorator('item_unit_nm', {
                      rules: [{
                        required: true,
                        message: '请输入单位名称',
                      }]
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="状态">
                    {form.getFieldDecorator('item_unit_sts_cd', {
                    })(
                      <Radio.Group buttonStyle="solid" disabled={true}>
                        <Radio.Button value="AC">可用</Radio.Button>
                        <Radio.Button value="IN">不可用</Radio.Button>
                      </Radio.Group>
                    )}
                  </FormItem>

                  <FormItem {...submitItemLayout}>
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                  </FormItem>
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

ItemUnitDetail = Form.create({})(ItemUnitDetail);
export default ItemUnitDetail;
