import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class OwnerPriceList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    ownr_id: '',
    prod_cd: '',
    listItem: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var paramIn = x1.router.getParamIn();
    this.state.ownr_id = paramIn.ownr_id;
    this.state.ownr_nm = paramIn.ownr_nm;
    this.state.prod_cd = paramIn.prod_cd;
    this.state.prod_nm = paramIn.prod_nm;
    form.setFieldsValue({
      ownr_nm: paramIn.ownr_nm,
      prod_nm: paramIn.prod_nm,
    });

    this.initSearch();
  };

  hasFunctionEdit(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH) || x1.user.hasRole(x1.ROLE.GEN_MGR)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/ownerPrice/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      ownr_id: this.state.ownr_id,
      prod_cd: this.state.prod_cd,
      get_prc_list_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    var record = {...this.getItemInfo()};
    x1.router.push("/OwnerPriceDetail", record);
  };

  openDetail(record) {
    var record = {...record, ...this.getItemInfo()};
    x1.router.push('/OwnerPriceDetail', record);
  };

  openPriceRange(record) {
    var record = {...record, ...this.getItemInfo()};
    x1.router.push('/OwnerPriceRangeDetail', record);
  };

  getItemInfo(){
    var param = {};
    param.ownr_id = this.state.ownr_id;
    param.ownr_nm = this.state.ownr_nm;
    param.prod_cd = this.state.prod_cd;
    param.prod_nm = this.state.prod_nm;
    return param;
  }

  deleteLine(record) {
    const params = {
      apiUrl: "/api/ownerPrice/delete",
      ownr_prc_pk: record.ownr_prc_pk,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  columns = [
    {title: '价格开始日期',  render: (text, record) => (
        <div>
          {x1.date2Text(record.from_dt)}
        </div>
      ),
    },
    {title: '价格结束日期',  render: (text, record) => (
        <div>
          {x1.date2Text(record.to_dt)}
        </div>
      ),
    },
    {title: '预冷费', dataIndex: 'pre_cool_unit_prc_amt'},
    {title: '冷藏费', dataIndex: 'store_unit_prc_amt'},
    {title: '搬运费', dataIndex: 'load_unit_prc_amt'},
    {title: '工本费', dataIndex: 'card_unit_prc_amt'},
    {title: '时间带价格', render: (text, record) => (
        <div>
          {record.listPrc.map((prc)=>
            <div>{x1.time2Text(prc.from_tm) + " - " + x1.time2Text(prc.to_tm) + '：' + prc.load_unit_prc_amt + '元'}</div>
          )}
        </div>
      ),
    },
    {title: '更新人', dataIndex: 'upd_usr_nm'},
    {title: '更新时间', dataIndex: 'upd_ts'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          <a onClick={() => this.openPriceRange(record)} style={{marginRight: '10px'}}>时间区间价格</a>
          <Popconfirm title={"确定要删除货主价格【" + record.ownr_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="货主">
              {form.getFieldDecorator('ownr_nm')(
                <Input style={{width: '100px'}} disabled={true}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="品类">
              {form.getFieldDecorator('prod_nm')(
                <Input style={{width: '100px'}} disabled={true}/>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>刷新</Button>
            {this.hasFunctionEdit &&
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
            }
          </Form>
          <Table
            rowKey={'ownr_prc_pk'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

OwnerPriceList = Form.create({})(OwnerPriceList);
export default OwnerPriceList;
