import React, {Fragment, PureComponent} from 'react';
import {TimePicker, Button, Card, Form, Input, Popconfirm, Select, Table} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class WarehouseMarketEntryPriceList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    wh_cd: '',
    listItem: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var paramIn = x1.router.getParamIn();
    this.state.wh_cd = paramIn.wh_cd;
    this.state.wh_nm = paramIn.wh_nm;
    form.setFieldsValue({
      wh_nm: paramIn.wh_nm,
    });

    this.initSearch();
  };

  hasFunctionEdit(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH) || x1.user.hasRole(x1.ROLE.GEN_MGR)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/warehouseMarketEntryPrice/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      wh_cd: this.state.wh_cd,
      get_prc_list_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    var record = {...this.getItemInfo()};
    x1.router.push("/WarehouseMarketEntryPriceDetail", record);
  };

  openDetail(record) {
    var record = {...record, ...this.getItemInfo()};
    x1.router.push('/WarehouseMarketEntryPriceDetail', record);
  };

  openPriceRange(record) {
    var record = {...record, ...this.getItemInfo()};
    x1.router.push('/WarehouseMarketEntryPriceRangeXDetail', record);
  };

  getItemInfo(){
    var param = {};
    param.wh_cd = this.state.wh_cd;
    param.wh_nm = this.state.wh_nm;
    return param;
  }

  deleteLine(record) {
    const params = {
      apiUrl: "/api/warehouseMarketEntryPrice/delete",
      wh_mkt_entry_prc_pk: record.wh_mkt_entry_prc_pk,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  columns = [
    {title: '价格开始日期',  render: (text, record) => (
        <div>
          {x1.date2Text(record.from_dt)}
        </div>
      ),
    },
    {title: '价格结束日期',  render: (text, record) => (
        <div>
          {x1.date2Text(record.to_dt)}
        </div>
      ),
    },
    {title: '进场费/吨', dataIndex: 'entry_mkt_amt'},
    {title: '更新人', dataIndex: 'upd_usr_nm'},
    {title: '更新时间', dataIndex: 'upd_ts'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          {this.hasFunctionEdit() &&
          <Popconfirm title={"确定要删除仓库价格【" + record.wh_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="仓库名称">
              {form.getFieldDecorator('wh_nm')(
                <Input style={{width: '300px'}} disabled={true}/>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>刷新</Button>
            {this.hasFunctionEdit() &&
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft: '16px'}}>新增</Button>
            }
          </Form>
          <Table
            rowKey={'wh_mkt_entry_prc_pk'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

WarehouseMarketEntryPriceList = Form.create({})(WarehouseMarketEntryPriceList);
export default WarehouseMarketEntryPriceList;
