import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class ShipFeeConfigList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listWh: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {wh_cd} = state;
        form.setFieldsValue({wh_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initWarehouseList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/warehouse/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      ship_fee_list_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  openDetail(record) {
    x1.router.push('/ShipFeeConfigDetail', record, x1.getStateSnapshort(this));
  };

  openEntryMarketFee(record) {
    x1.router.push('/EntryMarketFee', record, x1.getStateSnapshort(this));
  };

  columns = [
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '区间价格', render: (text, record) => (
        <div>
          {record.listPrc.map((prc)=>
            <div>{prc.from_kg_amt + "KG - " + prc.to_kg_amt + 'KG : ' + prc.ship_unit_prc_amt + '元'}</div>
          )}
        </div>
      ),
    },
    // {
    //   title: '进场费/吨',
    //   render: (text, record) => (
    //     <div>{x1.hasValue(record.entry_mkt_amt)?(record.entry_mkt_amt+'元'):''}</div>
    //   ),
    // },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>更新区间价格</a>
          {/*<a onClick={() => this.openEntryMarketFee(record)} style={{marginRight: '10px'}}>更新进场费</a>*/}
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="仓库">
              {form.getFieldDecorator('wh_cd')(
                <Select style={{width: '200px'}} allowClear={true}>
                  {this.state.listWh.map((item) => (
                    <Option key={item.wh_cd}>{item.wh_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>刷新</Button>
          </Form>
          <Table
            rowKey={'wh_cd'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

ShipFeeConfigList = Form.create({})(ShipFeeConfigList);
export default ShipFeeConfigList;
