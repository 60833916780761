import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, DatePicker, Radio} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class ReportInventoryByProduct extends PureComponent {
  state = {
    loading: false,
    loadingDownload: false,
    pagination: {},
    listItem: [],
    smryData: {},
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {from_rpt_dt, to_rpt_dt, prod_nm} = state;
        form.setFieldsValue({from_rpt_dt, to_rpt_dt, prod_nm});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initFromNoneBack();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initFromNoneBack(){
    const {form} = this.props;
    if(x1.report.hasConditionDate()){
      // 取上个画面的区间
      x1.report.loadConditionDate(this);
    } else {
      // 默认期间3个月
      var toDt = x1.getCurrentMoment();
      var fromDt = x1.getCurrentMoment().subtract(3, 'months');
      form.setFieldsValue({
        from_rpt_dt: fromDt,
        to_rpt_dt: toDt,
      });
    }
    this.initSearch();
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 50);
    this.searchSummary();
  };

  searchSummary() {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/reportByProductSummary",
      ...values,
      success: (data) => {
        if(data.length > 0) {
          this.setState({
              smryData: data[0],
          });
        }
      },
      complete: () => {
        this.setState({loadingSummary: false});
      }
    };
    this.setState({loadingSummary: true});
    x1.request(params);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/reportByProduct",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/downloadReportByProduct",
      ...values,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  onChangeDate(){
    setTimeout(()=>{
      const {form} = this.props;
      var values = form.getFieldsValue();
      x1.report.saveConditionDate(values.from_rpt_dt, values.to_rpt_dt);
    },1);
  }

  columns = [
    {title: '品类', dataIndex: 'prod_nm'},
    {title: '报表天数', dataIndex: 'rpt_days'},
    {title: '期初数', dataIndex: 'beg_invty_qty'},
    {title: '期末数', dataIndex: 'end_invty_qty'},
    {title: '入库数', dataIndex: 'stock_in_qty'},
    {title: '出库数', dataIndex: 'stock_out_qty'},
    {title: '吞吐量', dataIndex: 'in_out_qty'},
    {title: '期间总库存量', dataIndex: 'tot_invty_qty'},
    {title: '日均库存量', dataIndex: 'avg_invty_qty'},
    {title: '货物周转周期', dataIndex: 'turn_over_per'},
    {title: '货物周转率', dataIndex: 'turn_over_rate'},
    {title: '平均在库时间', dataIndex: 'avg_invty_days'},
    {title: '搬运费单价', dataIndex: 'load_unit_prc_amt'},
    {title: '搬运费', dataIndex: 'load_amt'},

    // {title: '空间使用率', dataIndex: 'pay_cash_amt'},
    // {title: '已收金额', dataIndex: 'pay_oil_amt'},
    // {title: '待收金额', dataIndex: 'pay_toll_amt'},
    // {title: '产生总效益', dataIndex: 'pay_hotel_amt'},
    // {title: '资金周转率', dataIndex: 'incm_amt'},
    // {title: '人均仓储收入', dataIndex: 'tax_rtn_amt'},
    // {title: '人均拣货量', dataIndex: 'tax_rtn_amt'},
    // {title: '平均耗电量', dataIndex: 'tax_rtn_amt'},
    // {title: '毛利率', dataIndex: 'tax_rtn_amt'},
    // {title: '利润率', dataIndex: 'tax_rtn_amt'},
    // {title: '净资产收益率', dataIndex: 'tax_rtn_amt'},
    // {title: '平均每平米收入', dataIndex: 'tax_rtn_amt'},
    // {title: '平均每平米支出', dataIndex: 'tax_rtn_amt'},
    // {title: '成本价1', dataIndex: 'tax_rtn_amt'},
    // {title: '成本价2', dataIndex: 'tax_rtn_amt'},

    // {
    //   title: '操作',
    //   render: (text, record) => (
    //     <Fragment>
    //       <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>车次详情</a>
    //     </Fragment>
    //   ),
    // },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{marginBottom: '16px'}}>
            <div>
              <FormItem {...formItemLayout} label="开始日期">
                {form.getFieldDecorator('from_rpt_dt')(
                  <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="-" colon={false}>
                {form.getFieldDecorator('to_rpt_dt')(
                  <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="品类">
                {form.getFieldDecorator('prod_nm')(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
            </div>
            <div style={{display:'flex', justifyContent:'flex-end', marginTop:'8px'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              <span style={{paddingRight:'16px'}}></span>
              <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>
            </div>
          </Form>

          <div className='box_cc' style={{background:'#FFFFB8', padding:'8px'}}>
            <div style={{marginRight:'32px'}}>{'报表天数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.rpt_days,'')}</div></div>
            <div style={{marginRight:'32px'}}>{'期初数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.beg_invty_qty,'')}</div></div>
            <div style={{marginRight:'32px'}}>{'期末数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.end_invty_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'入库数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.stock_in_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'出库数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.stock_out_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'吞吐量：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.in_out_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'期间总库存量：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.tot_invty_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'日均库存量：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.avg_invty_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'货物周转周期：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.turn_over_per,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'货物周转率：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.turn_over_rate,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'平均在库时间：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.avg_invty_days,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'搬运费：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.load_amt,0)}</div></div>
          </div>

          <Table
            rowKey={'prod_cd'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

ReportInventoryByProduct = Form.create({})(ReportInventoryByProduct);
export default ReportInventoryByProduct;
