import React, {PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';

const {MonthPicker} = DatePicker;
const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class MonthExpenseDetail extends PureComponent {
  state = {
    loading: false,
    mth_exp_pk: '',
    listWh: [],
  };

  componentDidMount() {
    const {form} = this.props;

    var paramIn = x1.router.getParamIn();
    this.state.mth_exp_pk = paramIn.mth_exp_pk;
    form.setFieldsValue(paramIn);
    this.initItemList();
  };

  initItemList(){
    const { form } = this.props;
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data)=>{
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/monthExpense/update",
        ...fieldsValue,
        mth_exp_pk: this.state.mth_exp_pk,
        upd_usr_id: x1.user.getCurrentUserId(),
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          // if(x1.isNull(this.state.mth_exp_pk)){
            x1.router.back();
          // }
        },
      };
      x1.request(params);
    });
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="月份">
                    {form.getFieldDecorator('exp_mth', {
                      rules: [{
                        required: true,
                        message: '请输入月份',
                      }]
                    })(<MonthPicker style={{width: '150px'}} locale={locale}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="仓库">
                    {form.getFieldDecorator('wh_cd', {
                      rules: [{
                        required: true,
                        message: '请输入仓库',
                      }]
                    })(
                      <Select style={{minWidth:'100px'}} allowClear={true} showSearch={true}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {this.state.listWh.map((item) => (<Option key={item.wh_cd}>{item.wh_nm}</Option>))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="员工人数">
                    {form.getFieldDecorator('mth_emp_cnt', {
                      rules: [{
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="搬运工人数">
                    {form.getFieldDecorator('mth_porter_cnt', {
                      rules: [{
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="员工工资">
                    {form.getFieldDecorator('mth_wage_amt', {
                      rules: [{
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="搬运工工资">
                    {form.getFieldDecorator('mth_porter_wage_amt', {
                      rules: [{
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="电费支出">
                    {form.getFieldDecorator('mth_power_amt', {
                      rules: [{
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="场地支出">
                    {form.getFieldDecorator('mth_rent_amt', {
                      rules: [{
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="伙食费">
                    {form.getFieldDecorator('mth_meal_amt', {
                      rules: [{
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="杂项支出">
                    {form.getFieldDecorator('mth_oth_amt', {
                      rules: [{
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="其它收入">
                    {form.getFieldDecorator('mth_oth_incm_amt', {
                      rules: [{
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="输入时间">
                    {form.getFieldDecorator('upd_ts', {
                    })(<Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="输入人">
                    {form.getFieldDecorator('upd_usr_nm', {
                    })(<Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  }
                  <FormItem {...submitItemLayout}>
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                  </FormItem>
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

MonthExpenseDetail = Form.create({})(MonthExpenseDetail);
export default MonthExpenseDetail;
