import React, {Fragment, PureComponent} from 'react';
import {Radio, Tag, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import ReportByOwnerStockInList from "./ReportByOwnerStockInList";

const FormItem = Form.Item;
const {Option} = Select;

class ReportByOwnerList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listOwnr: [],
  };


  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {ownr_id, excd_cr_limit_flg} = state;
        form.setFieldsValue({ownr_id, excd_cr_limit_flg});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initOwnerList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/invoice/reportByOwner",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/invoice/downloadReportByOwner",
      ...values,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  openDetail(record) {
    x1.router.push('/ReportByOwnerStockInList', record, x1.getStateSnapshort(this));
  };

  columns = [
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '额度', dataIndex: 'cr_limit_amt'},
    {title: '有库存', dataIndex: 'with_invty_inv_amt'},
    {title: '未打单', dataIndex: 'wo_invty_inv_amt'},
    {title: '已打单', dataIndex: 'with_inv_inv_amt'},
    {title: '总应付', dataIndex: 'ar_amt'},
    {title: '信用余额', render: (text, record) => (
        <div style={{color:record.cr_bal_amt<0?'red':''}}>{record.cr_bal_amt}</div>
      ),
    },
    {title: '超额', render: (text, record) => (
          <div>
            {record.cr_bal_amt < 0 &&
            <Tag color="red">超额</Tag>
            }
          </div>
      ),
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <div>
            <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>账单详情</a>
          </div>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="货主">
              {form.getFieldDecorator('ownr_id')(
                <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.state.listOwnr.map((item) => (
                    <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="超额">{form.getFieldDecorator(`excd_cr_limit_flg`, {initialValue:'*', rules: [{}]})(
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="*">全部</Radio.Button>
                <Radio.Button value="Y">超额</Radio.Button>
              </Radio.Group>
            )}</FormItem>
            <FormItem {...formItemLayout} label="是否有应付">
              {form.getFieldDecorator('has_ar_flg')(
                <Select style={{width: '100px'}} allowClear={true}>
                  <Option key={'Y'}>{'有应付'}</Option>
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <span style={{paddingRight:'16px'}}></span>
            <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>
          </Form>
          <Table
            // rowKey={'stock_in_line_full_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

ReportByOwnerList = Form.create({})(ReportByOwnerList);
export default ReportByOwnerList;
