import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class WarehouseRoomDetail extends PureComponent {
  state = {
    loading: false,
    listWh: [],
  };

  componentDidMount() {
    const {form} = this.props;

    var wh_room_cd = x1.router.getParamIn().wh_room_cd;
    if (wh_room_cd > '') {
      // 更新
      form.setFieldsValue({wh_room_cd: wh_room_cd});
      this.search(wh_room_cd);
    } else {
      // 新增
    }
    this.initWarehouseList();
  };

  hasFunctionUpdate(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/warehouseRoom/update",
        ...fieldsValue,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.wh_room_cd)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
      };
      x1.request(params);
    });
  };

  search(wh_room_cd) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/warehouseRoom/list",
      wh_room_cd: wh_room_cd,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="库房编码">
                    {form.getFieldDecorator('wh_room_cd')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="库房名称">
                    {form.getFieldDecorator('wh_room_nm', {
                      rules: [{
                        required: true,
                        message: '请输入库房名称',
                      }]
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="仓库">
                    {form.getFieldDecorator('wh_cd')(
                      <Select style={{width: '200px'}} allowClear={true}>
                        {this.state.listWh.map((item) => (
                          <Option key={item.wh_cd}>{item.wh_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="面积(m2)">
                    {form.getFieldDecorator('wh_room_area_amt', {
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="状态">
                    {form.getFieldDecorator('wh_room_sts_cd', {
                    })(
                      <Radio.Group buttonStyle="solid" disabled={true}>
                        <Radio.Button value="AC">可用</Radio.Button>
                        <Radio.Button value="IN">不可用</Radio.Button>
                      </Radio.Group>
                    )}
                  </FormItem>

                  {this.hasFunctionUpdate() &&
                  <FormItem {...submitItemLayout}>
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                  </FormItem>
                  }
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

WarehouseRoomDetail = Form.create({})(WarehouseRoomDetail);
export default WarehouseRoomDetail;
