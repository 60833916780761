import React, {Fragment, PureComponent} from 'react';
import {Button, Card, DatePicker, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {message} from "antd/lib/index";
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const {MonthPicker} = DatePicker;

class TemporaryEmployeeAssignByEmployee extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {temp_emp_asg_dt} = state;
        form.setFieldsValue({temp_emp_asg_dt});
        this.refreshPage();
      }
    }

    if(!isBack) {
      var paramIn = x1.router.getParamIn();
      form.setFieldsValue({
        temp_emp_asg_mth: paramIn.temp_emp_asg_mth,
        temp_emp_id: paramIn.temp_emp_id,
        temp_emp_nm: paramIn.temp_emp_nm,
      });
      this.initSearch();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/tempEmpAsg/listAsg",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  columns = [
    {title: '日期',
      render: (text, record) => (
        <div>
          {x1.date2Text(record.temp_emp_asg_dt)}
        </div>
      ),
    },
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '上班(上午)', dataIndex: 'dt_am_flg'},
    {title: '上班(下午)', dataIndex: 'dt_pm_flg'},
    {title: '上班(晚上)', dataIndex: 'dt_ng_flg'},
    // {
    //   title: '操作',
    //   render: (text, record) => (
    //     <Fragment>
    //       <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
    //     </Fragment>
    //   ),
    // },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="月份">
              {form.getFieldDecorator('temp_emp_asg_mth', {
              })(
                <MonthPicker style={{width: '150px'}} disabled={true} />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="员工ID">
              {form.getFieldDecorator('temp_emp_id', {
              })(
                <Input style={{width: '150px'}} disabled={true} />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="员工姓名">
              {form.getFieldDecorator('temp_emp_nm', {
              })(
                  <Input style={{width: '150px'}} disabled={true} />
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
          </Form>
          <Table
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

TemporaryEmployeeAssignByEmployee = Form.create({})(TemporaryEmployeeAssignByEmployee);
export default TemporaryEmployeeAssignByEmployee;
