import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col, DatePicker} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class OwnerPriceDetail extends PureComponent {
  state = {
    loading: false,
    ownr_id: '',
    ownr_nm: '',
  };

  componentDidMount() {
    const {form} = this.props;

    this.state.ownr_id = x1.router.getParamIn().ownr_id;
    this.state.prod_cd = x1.router.getParamIn().prod_cd;
    var ownr_prc_pk = x1.router.getParamIn().ownr_prc_pk;

    this.setState({
      ownr_nm : x1.router.getParamIn().ownr_nm,
      prod_nm : x1.router.getParamIn().prod_nm,
    });

    if (ownr_prc_pk > '') {
      // 更新
      form.setFieldsValue({ownr_prc_pk: ownr_prc_pk});
      this.search(ownr_prc_pk);
    } else {
      // 新增
      this.setState({});
      form.setFieldsValue({
        to_dt: moment('9999-12-31')
      });
    }
  };

  hasFunctionEdit(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH) || x1.user.hasRole(x1.ROLE.GEN_MGR)){
      return true;
    }
    return false;
  }

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/ownerPrice/update",
        ...fieldsValue,
        ownr_id: this.state.ownr_id,
        prod_cd: this.state.prod_cd,
        usr_id: x1.user.getCurrentUserId(),
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.ownr_id)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
      };
      x1.request(params);
    });
  };

  search(ownr_prc_pk) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/ownerPrice/list",
      ownr_prc_pk: ownr_prc_pk,
      success: (data) => {
        setTimeout(()=>form.setFieldsValue(data[0]), 1);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <div className='box_cc' style={{marginBottom:'16px'}}>
                <div>{'货主：' + this.state.ownr_nm}</div>
                <div style={{width:'50px'}}></div>
                <div>{'品类：' + this.state.prod_nm}</div>
              </div>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="价格编码">
                    {form.getFieldDecorator('ownr_prc_pk')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="开始日期">
                    {form.getFieldDecorator('from_dt', {
                      rules: [{
                        required: true,
                        message: '请输入开始日期',
                      }]
                    })(
                      <DatePicker style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="结束日期">
                    {form.getFieldDecorator('to_dt', {
                      rules: [{
                        required: true,
                        message: '请输入结束日期',
                      }]
                    })(
                      <DatePicker style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="预冷费">
                    {form.getFieldDecorator('pre_cool_unit_prc_amt', {
                      rules: [{
                        required: true,
                        message: '请输入预冷费',
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="冷藏费">
                    {form.getFieldDecorator('store_unit_prc_amt', {
                      rules: [{
                        required: true,
                        message: '请输入冷藏费',
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="搬运费">
                    {form.getFieldDecorator('load_unit_prc_amt', {
                      rules: [{
                        required: true,
                        message: '请输入搬运费',
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="工本费">
                    {form.getFieldDecorator('card_unit_prc_amt', {
                      rules: [{
                        required: true,
                        message: '请输入工本费',
                      }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...submitItemLayout}>
                    {this.hasFunctionEdit() &&
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                    }
                  </FormItem>
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

OwnerPriceDetail = Form.create({})(OwnerPriceDetail);
export default OwnerPriceDetail;
