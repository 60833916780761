import React, {Fragment, PureComponent} from 'react';
import {Radio, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class StockOutList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listWh: [],
    listOwnr: [],
    listSts: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {wh_cd, ownr_id, stock_out_dt, item_nm, stock_in_dt, stock_out_sts_cd} = state;
        form.setFieldsValue({wh_cd, ownr_id, stock_out_dt, item_nm, stock_in_dt, stock_out_sts_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initWarehouseList();
      this.initOwnerList();
      this.initStatusList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionNew(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.MGR) || x1.user.hasRole(x1.ROLE.ORD)){
      return true;
    }
    return false;
  }

  hasFunctionDeleteAfterSubmit(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  hasFunctionDeleteAfterConfirm(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initStatusList() {
    const params = {
      apiUrl: "/api/stockOutStatus/list",
      success: (data) => {
        this.setState({
          listSts: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/stockOut/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      item_list_desc_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/StockOutDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/StockOutDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/stockOut/delete",
      stock_out_num: record.stock_out_num,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  deleteLineAfterStockOut(record) {
    const params = {
      apiUrl: "/api/stockOut/deleteAfterStockOut",
      stock_out_num: record.stock_out_num,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  columns = [
    {title: '出库单号', dataIndex: 'stock_out_num'},
    {title: '出库日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.stock_out_dt)}</div>
      )
    },
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '商品', dataIndex: 'item_list_desc', width:500},
    {title: '货主备注', dataIndex: 'ownr_memo_txt'},
    {title: '内部备注', dataIndex: 'stock_out_memo_txt'},
    {title: '吨位', dataIndex: 'stock_out_tnng_amt'},
    {title: '更新人', dataIndex: 'upd_usr_nm'},
    {title: '更新时间', dataIndex: 'upd_ts'},
    {title: '出库单状态',
      render: (text, record) => (
        <div>
          <div>{record.stock_out_sts_nm}</div>
          <div>{record.stock_out_sts_cd=='CP' ? ('出库时间 '+x1.time2Text(record.stock_out_tm)) : ''}</div>
        </div>
      )
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          {(record.stock_out_sts_cd == 'CR' || x1.isNull(record.stock_out_sts_cd)) && this.hasFunctionNew() &&
          <Popconfirm title={"确定要删除出库单【" + record.stock_out_num + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'}/>
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
          {((record.stock_out_sts_cd == 'CP' && this.hasFunctionDeleteAfterSubmit())
            || (record.stock_out_sts_cd == 'SU' && this.hasFunctionDeleteAfterConfirm())) &&
            <Popconfirm title={"改单据已出库，确定要删除出库单【" + record.stock_out_num + "】"} onConfirm={() => this.deleteLineAfterStockOut(record)}>
              <Divider type={'vertical'}/>
              <a style={{marginRight: '10px'}}>删除出库</a>
            </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="仓库">
                {form.getFieldDecorator('wh_cd')(
                  <Select style={{width: '100px'}} allowClear={true}>
                    {this.state.listWh.map((item) => (
                      <Option key={item.wh_cd}>{item.wh_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="货主">
                {form.getFieldDecorator('ownr_id')(
                  <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {this.state.listOwnr.map((item) => (
                      <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              {/*<FormItem {...formItemLayout} label="状态">*/}
                {/*{form.getFieldDecorator('stock_out_sts_cd')(*/}
                  {/*<Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>*/}
                    {/*{this.state.listSts.map((item) => (*/}
                      {/*<Option key={item.stock_out_sts_cd}>{item.stock_out_sts_nm}</Option>*/}
                    {/*))}*/}
                  {/*</Select>*/}
                {/*)}*/}
              {/*</FormItem>*/}
              <FormItem {...formItemLayout} label="出库日期">
                {form.getFieldDecorator('stock_out_dt', {
                })(
                  <DatePicker style={{width: '130px'}} allowClear={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="商品">
                {form.getFieldDecorator('item_nm', {
                })(
                  <Input style={{width: '100px'}} allowClear={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="入库日期">
                {form.getFieldDecorator('stock_in_dt', {
                })(
                  <Input style={{width: '100px'}} allowClear={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="状态">{form.getFieldDecorator(`stock_out_sts_cd`, {initialValue:'*', rules: [{}]})(
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="*">全部</Radio.Button>
                  <Radio.Button value="CR">已保存</Radio.Button>
                  <Radio.Button value="SU">已提交</Radio.Button>
                  <Radio.Button value="CP">已确认</Radio.Button>
                </Radio.Group>
              )}</FormItem>
            </div>
            <div style={{marginBottom:'16px', display:'flex', justifyContent:'flex-end'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              {this.hasFunctionNew() &&
              <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
              }
            </div>
          </Form>
          <Table
            rowKey={'stock_out_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

StockOutList = Form.create({})(StockOutList);
export default StockOutList;
