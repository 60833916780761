import axios from "axios/index";
import {message} from "antd";
import moment from 'moment';
// import immutable from 'immutable';

export const x1 = {

  generateListIndex(list){
    for(var i=0; i<list.length; i++){
      var item = list[i];
      item.index = i;
    }
  },

  ROLE: {
    ADMIN: 'R001', //管理员
    FIN: 'R002', //财务
    MGR: 'R003',  // 经理
    BIZ: 'R004',  // 保管员，仓管
    CASH: 'R005', //出纳
    ORD: 'R006',  // 打单员，信息员
    GEN_MGR: 'R007',  // 主管

    GUEST: 'R010',  // 游客
  },

  // usr_role_cd	usr_role_nm
  // R001	管理员
  // R002	财务
  // R004	业务员
  // R005	出纳
  // R006	打单员
  // R003	经理
  // R007	主管

  report: {
    fromDt:'',
    toDt:'',

    saveConditionDate: function(fromDt, toDt){
      this.fromDt = fromDt;
      this.toDt = toDt;
    },

    loadConditionDate: function(page){
      const {form} = page.props;
      form.setFieldsValue({from_rpt_dt:this.fromDt, to_rpt_dt:this.toDt});
    },

    hasConditionDate: function(){
      if(this.fromDt > '' || this.toDt > ''){
        return true;
      }
      return false;
    }
  },

  /**
   * 保存当前画面的输入状态
   */
  getStateSnapshort(page) {
    const {form} = page.props;
    var values = form.getFieldsValue();
    return {...page.state, ...values};
  },

  nvl: function(value, value2){
    if(x1.isNull(value)){
      if(x1.isNull(value2)){
        return '';
      }
      return value2;
    }
    return value;
  },

  boolean2flag: function(value){
    if(value){
      return 'Y'
    }
    return '';
  },

  flag2boolean: function(value){
    if(value == 'Y'){
      return true;
    }
    return false;
  },

  isNull: function(value){
    if(value == null || value == ''){
      return true;
    }
    return false;
  },

  hasValue: function(value){
    return !x1.isNull(value);
  },

  isEnvProduction: function(){
    if(process.env.NODE_ENV == 'production'){
      return true;
    }
    return false;
  },

  // ----------------------------------------
  // ant design hack
  // ----------------------------------------
  getCurrentMoment(){
    return new moment();
  },

  getSystemDate: function(){
    return moment(new Date().toJSON().substr(0, 10));
  },

  getSystemTime: function () {
    return moment(new Date().toJSON().substr(11, 19));
  },

  getTimestamp: function(){
    return new Date().getTime();
  },

  getTimestampFormatString: function(){
    return moment(new Date()).format('YYYY-MM-DD hh:mm:ss');
  },

  mergeStateAndFormList(listSt, listForm){
    if(listForm == null){
      return [];
    }
    var arr = [];
    for(var i=0; i<listForm.length; i++){
      var st = {}
      if(i < listSt.length){
        st = listSt[i];
      }
      var form = listForm[i];
      var item = {...st, ...form};
      arr.push(item);
    }
    return arr;
  },

  mergeDeep: function(obj1, obj2){
    // return immutable.mergeDeep(obj1, obj2);
    if(obj1 instanceof Array){
      var arr = [];
      for(var i=0; i<obj1.length; i++){
        arr[i] = x1.mergeDeep(obj1[i], obj2[i]);
      }
      return arr;
    } else {
      return {...obj1, ...obj2};
    }
  },

  // ----------------------------------------
  // 画面信息提示
  // ----------------------------------------
  showMessage: function(msg){
    message.success(msg);
  },
  showError: function(msg){
    message.error(msg);
  },
  showWarning: function(msg){
    message.warning(msg);
  },

  // ----------------------------------------
  // 下载文件
  // ----------------------------------------
  download: function(url){
    window.location.href = url;
  },

  // ----------------------------------------
  // 数据库日期字段的文字转-》日期
  // ----------------------------------------
  convertText2Date(item){
    for(var key in item){
      var value = item[key];
      if(key.endsWith("_dt")) {
        if (value > '') {
          item[key] = moment(value);
        }

      // } else if(key.endsWith("_ts")){
      //   if(value > ''){
      //     item[key] = moment(value);
      //   }

      } else if(key.endsWith("_mth")){
        if(value > ''){
          item[key] = moment(value);
        }

      } else if(key.endsWith("_tm")){
        if(value > '') {
          item[key] = moment(value, 'HH:mm:ss');
        }
      } else {
        if(value instanceof Array || value instanceof Object){
          x1.convertText2Date(value);
        }
      }
    }
  },

  convertDate2Text(item){
    for(var key in item){
      var value = item[key];
      if(key.endsWith("_dt") && typeof(value) == 'object' && value != null) {
        item[key] = value.format('YYYY-MM-DD');

      // } else if(key.endsWith("_ts") && typeof(value) == 'object' && value != null){
      //   item[key] = value.format('YYYY-MM-DD');

      } else if(key.endsWith("_mth") && typeof(value) == 'object' && value != null){
        item[key] = value.format('YYYY-MM');

      } else if(key.endsWith("_tm") && typeof(value) == 'object' && value != null){
        item[key] = value.format('HH:mm:ss');

      } else {
        if(value instanceof Array || value instanceof Object){
          x1.convertDate2Text(value);
        }
      }
    }
  },

  convertNumberBlankInputToNull(item){
    for(var key in item){
      var value = item[key];
      if((key.endsWith("_amt") || key.endsWith("_qty")) && value == ''){
        item[key] = null;

      } else {
        if(value instanceof Array || value instanceof Object){
          x1.convertNumberBlankInputToNull(value);
        }
      }
    }
  },

  // moment object to text
  date2Text(dt){
    if(dt != null && dt.format != null && dt != ''){
      return dt.format('YYYY-MM-DD');
    }
    return "";
  },

  month2Text(dt){
    if(dt != null && dt.format != null && dt != ''){
      return dt.format('YYYY-MM');
    }
    return "";
  },

  time2Text(tm){
    if(tm != null && tm.format != null && tm != ''){
      return tm.format('HH:mm:ss');
    }
    return "";
  },

  timeStamp2Text(dt){
      if(dt != null && dt.format != null && dt != ''){
          return dt.format('YYYY-MM-DD HH:mm:ss');
      }
      return "";
  },

  // ----------------------------------------
  // 本地存储
  // ----------------------------------------
  storage: {

    setStorage(key, data){
      var storage = window.localStorage;
      if(storage == null){
        return;
      }
      var objectStr = JSON.stringify(data);
      storage.setItem(key, objectStr);
    },

    getStorage(key){
      var storage = window.localStorage;
      if(storage == null){
        return;
      }
      var valueStr = storage.getItem(key);
      if(valueStr == null){
        return null;
      }
      return JSON.parse(valueStr);
    },

    removeStorage(key){
      var storage = window.localStorage;
      if(storage == null){
        return;
      }
      storage.removeItem(key);
    }
  },

  // ----------------------------------------
  // 用户信息
  // ----------------------------------------
  user: {

    currentUser: {
      usr_id: '',
      // name: '',
      // avatar: '',
      usr_nm: "",
      head_img_url: "https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png",
      phone_num: '',
      pw_hash_txt: '',
      head_img_load_ts: '',
    },
    keepLogin: false,

    login(info, keepLogin){
      this.keepLogin = keepLogin;
      this.currentUser = {...info};
      this.updateHeadImageLoadTimestamp();
      if(keepLogin){
        x1.storage.setStorage("user", info);
      } else {
        x1.storage.removeStorage("user")
      }
    },

    updatePassword(pw){
      this.currentUser.pw_hash_txt = pw;
      if(this.keepLogin){
        x1.storage.setStorage("user", this.currentUser);
      }
    },

    getLocalSavedUserInfo(){
      var info = x1.storage.getStorage("user");
      return info;
    },

    logout(){
      this.currentUser = {};
      x1.storage.removeStorage("user")
    },

    hasAnyRole(listRoleCd) {
      for(var i=0; i<listRoleCd.length; i++){
        var roleCd = listRoleCd[i];
        if(this.hasRole(roleCd)){
          return true;
        }
      }
      return false;
    },

    hasRole(roleCd){
      // xxx
      // if(roleCd > ''){
      //   return true;
      // }
      var roles = this.currentUser.listRole;
      if(roles != null) {
        for (var i = 0; i < roles.length; i++) {
          var role = roles[i];
          if (role.usr_role_cd == roleCd) {
            return true;
          }
        }
      }
      return false;
    },

    getCurrentUser(){
      return this.currentUser;
    },

    getCurrentUserId() {
      return this.currentUser.usr_id;
    },

    updateHeadImageLoadTimestamp(){
      this.currentUser.head_img_load_ts = x1.getTimestamp();
    },

    getCurrentUserHeadImageUrl() {
      // // 加上个时间戳，当客户端上传新的头像时，强制刷新头像
      // var url = this.currentUser.head_img_url;
      // if(url > ''){
      //   url = url + "&_=" + this.currentUser.head_img_load_ts;
      //   return url;
      // }
      // return null;
      return "https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png";
    },

    setCurrentUserHeadImageUrl(url){
      this.currentUser.head_img_url = url;
      this.updateHeadImageLoadTimestamp();
    },

    hasUserInfo(){
      if(this.currentUser.usr_id > ''){
        return true;
      }
      return false;
    },
  },

  // ----------------------------------------
  // 路由
  // ----------------------------------------
  router : {
    listPageDef: [],
    pages: [],
    paramIn: {},
    paramOut: {},
    history: null,
    routeChangedListener: null,

    initRouter: function(listPageDefinition){
      this.pushWindowHistory();
      this.listPageDef = listPageDefinition;
    },

    getPageDefinition: function(path){
      for(var i=0; i<this.listPageDef.length; i++){
        var def = this.listPageDef[i];
        if(def.path == path){
          return def;
        }
      }
      return null;
    },

    getLeftMenu: function(){
      // 根据Page的定义生成菜单
      var map = {};
      var menu = [];
      for(var i in this.listPageDef){
        var pageDef = this.listPageDef[i];
        if(pageDef.menuId > ''){
          var menuId = pageDef.menuId;
          var menuIds = menuId.split(".");
          var menuGrp = menuIds[0];
          var menuItem = menuIds[1];
          var subMenu = map[menuGrp];
          if(subMenu == null && (pageDef.roles==null || x1.user.hasAnyRole(pageDef.roles))){
            subMenu = {name:menuGrp, id:menuGrp, items:[]};
            map[menuGrp] = subMenu;
            menu.push(subMenu)
          }
          if(subMenu != null && (pageDef.roles==null || x1.user.hasAnyRole(pageDef.roles))){
            subMenu.items.push({name:menuItem, id:menuId, path: pageDef.path});
          }
        }
      }
      return menu;
    },

    setRouteChangedListener: function(callback){
      this.routeChangedListener = callback;
    },

    getPageCount: function(){
      return this.pages.length;
    },

    initPage: function(url, param){
      var newPage = {
        path: url,
        paramIn: param,
      }
      this.pages.push(newPage);
    },

    init: function (history) {
      this.history = history;
    },

    isPageBack(){
      var page = this.pages[this.pages.length-1];
      return page.isBack;
    },

    getPageState(){
      var page = this.pages[this.pages.length-1];
      return page.state;
    },

    getPages: function(){
      return this.pages;
    },

    getTopPage: function(){
      if(this.pages.length > 0){
        return this.pages[this.pages.length-1];
      }
      return null;
    },

    hasPage: function(){
      return this.pages.length > 0;
    },

    hasMultiplePage: function(){
      return this.pages.length > 1;
    },

    push: function (url, param, prePageState) {
      this.paramIn = param;
      var newPage = {
        path: url,
        paramIn: param,
      }
      // 保存上个页面的状态
      var prePage = this.pages[this.pages.length-1];
      prePage.state = prePageState;

      this.pages.push(newPage);
      this.fireRouteChangeEvent();
    },

    back: function (param) {
      param = param?param:{};
      this.paramOut = param;
      var delta = param.delta?param.delta:1;
      for(var i=0; i<delta; i++){
        if (this.pages.length > 0) {
          this.pages.pop();
        }
      }

      var page = this.pages[this.pages.length-1];
      page.isBack = true;

      this.fireRouteChangeEvent();
    },

    replace: function (url, param) {
      if (this.pages.length > 0) {
        let prePage = this.pages.pop();
      }
      var newPage = {
        path: url,
        paramIn: param,
      }
      this.pages.push(newPage);
      // this.history.push(url);
      this.fireRouteChangeEvent();
    },

    relaunch: function (url, param) {
      this.pages = [];
      if(url > ''){
        var newPage = {
          path: url,
          paramIn: param,
        }
        this.pages.push(newPage);
      }
      this.fireRouteChangeEvent();
    },

    refresh: function(){
      this.fireRouteChangeEvent();
    },

    fireRouteChangeEvent: function(){
      if(this.routeChangedListener != null){
        this.routeChangedListener();
      }
    },

    getParamIn: function () {
      if (this.pages.length > 0) {
        let curPage = this.pages[this.pages.length-1];
        let param = curPage.paramIn;
        if (param != null) {
          return param;
        }
      }
      return {};
    },

    getParamOut: function () {
      if (this.pages.length > 0) {
        let curPage = this.pages.top();
        let param = curPage.paramOut;
        if (param != null) {
          return param;
        }
      }
      return {};
    },

    // 浏览器返回键处理
    pushWindowHistory: function () {
      var that = this;
      var win = window;
      var historyState = new Object();
      var title = win.document.title;
      var url = win.document.URL;
      url = win.location.protocol + "//" + win.location.host + "/"

      if(x1.isEnvProduction()){
        url = url + "app"
      }

      win.history.pushState(historyState, title, url);
      win.onpopstate = function (event) {
        that.pushWindowHistory();
        if (that.pages.length == 0) {
          // do nothing
        } else if (that.pages.length == 1) {
          // do nothing
        } else {
          that.back();
        }
      }
    },

  },

  // ----------------------------------------
  // 服务器请求
  // ----------------------------------------
  request: function(params) {

    var url = params.apiUrl;
    url = url.replace("/api", "");
    if(x1.isEnvProduction()){
      url = window.location.origin + url;
    } else {
    url = "http://localhost:8080" + url;
    }

    // DEBUG
    // console.log("======== request BEG =========")
    // console.log(JSON.stringify(params));
    // console.log("======== request END =========")

    x1.convertDate2Text(params);
    // 把数字空白的输入转换为null
    x1.convertNumberBlankInputToNull(params);

    // 用户数据权限用
    params.se_usr_id = x1.user.getCurrentUserId();
    params.x1_usr_id = x1.user.getCurrentUserId();

    var apiParam = params;
    var conf = {};
    var startTime = x1.getTimestamp();
    axios.post(url, apiParam, conf)
      .then((response) => {

        var endTime = x1.getTimestamp();
        // console.log(">>>>> performance: [" + params.apiUrl + "][" + (endTime - startTime) + "s]");

        // console.log("++++++++ response BEG ++++++++")
        // console.log(JSON.stringify(response));
        // console.log("++++++++ response END ++++++++")

        // 服务器错误
        if (response == null) {
          message.error("服务器发生错误，请刷新重试")
          return;
        }
        response = response.data;

        // 默认抱错的处理
        if (response.errorCode > '') {
          // 接口调用失败的回调函数，或者按默认报错方式来处理
          if (params.fail) {
            params.fail();
          } else {
            message.error(response.errorMessage);
          }
        } else {
          // 接口调用成功的回调函数
          if (params.success) {
            var data = response.data;
            x1.convertText2Date(data);
            params.success(data);
          }
        }
        // 接口调用结束的回调函数（调用成功、失败都会执行）
        if (params.complete) {
          params.complete();
        }
      });
  }

};

