import React, {Fragment, PureComponent} from 'react';
import {Radio, Modal, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import InvoicePaymentEntryPopup from "./InvoicePaymentEntryPopup";

const FormItem = Form.Item;
const {Option} = Select;

class InvoiceList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listInv: [],
    listWh: [],
    listOwnr: [],

    isShowPopup: false,
    selIndex: -1,
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {wh_cd, ownr_id, item_nm, stock_in_dt, wait_rcv_pmt_flg} = state;
        form.setFieldsValue({wh_cd, ownr_id, item_nm, stock_in_dt, wait_rcv_pmt_flg});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initWarehouseList();
      this.initOwnerList();
    }
  };

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/invoice/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      order_by_stock_in_flg: 'Y',
      success: (data) => {
        x1.generateListIndex(data);
        this.setState({
          listInv: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  openDetail(record) {
    x1.router.push('/InvoiceDetail', record, x1.getStateSnapshort(this));
  };

  // ----------------------------------
  // 收款弹出框
  // ----------------------------------
  receivePayment(record) {
    this.state.selIndex = record.index;
    this.setState({isShowPopup: true});
  }

  onSubmitReceivePayment(input){
    this.setState({isShowPopup: false});
    const params = {
      loading: true,
      apiUrl: "/api/invoice/receivePayment",
      inv_num: input.inv_num,
      rcv_pmt_amt: input.rcv_pmt_amt,
      rcv_pmt_dt: input.rcv_pmt_dt,
      usr_id: x1.user.getCurrentUserId(),
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleCancelPopup(){
    this.setState({isShowPopup: false});
  }

  deleteLine(record) {
    const params = {
      apiUrl: "/api/invoice/delete",
      inv_num: record.inv_num,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  cancelReceivePayment(record) {
    const params = {
      apiUrl: "/api/invoice/cancelReceivePayment",
      inv_num: record.inv_num,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  columns = [
    {title: '仓库/单号', dataIndex: 'wh_nm',
      render: (text, record, index) => (
        <div>
          <div>{record.wh_nm}</div>
          <div>{record.inv_num}</div>
        </div>
      ),
    },
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '商品', dataIndex: 'item_nm'},
    {title: '入库日期', dataIndex: 'stock_in_dt_txt'},
    {title: '入库数量', dataIndex: 'stock_in_qty'},
    {title: '最后出库日期', dataIndex: 'last_stock_out_dt_txt'},
    {title: '自提数量', dataIndex: 'tot_pick_up_qty'},
    {title: '预冷费', dataIndex: 'pre_cool_amt'},
    {title: '冷藏费', dataIndex: 'store_amt'},
    {title: '装卸费', dataIndex: 'load_amt'},
    {title: '转场费', dataIndex: 'ship_amt'},
    {title: '工本费', dataIndex: 'card_unit_amt'},
    {title: '其它费用', dataIndex: 'oth_amt'},
    {title: '费用总计', dataIndex: 'tot_inv_amt'},
    {title: '预收款', dataIndex: 'pre_paid_amt'},
    {title: '实收款', dataIndex: 'rcv_pmt_amt'},
    {title: '差额', dataIndex: 'inv_pmt_diff_amt'},
    {
      title: '操作',
      render: (text, record, index) => (
        <Fragment>
          <div>
            <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>账单详情</a>
            {x1.nvl(record.rcv_pmt_amt,0) <= 0 && record.tot_inv_amt > 0 &&
            <div>
              <a style={{marginRight: '10px'}} onClick={() => this.receivePayment(record)}>收款</a>
            </div>
            }
            {this.hasFunctionDelete() &&
            <div>
              <Popconfirm title={"确定要删除账单【" + record.inv_num + "】"} onConfirm={() => this.deleteLine(record)}>
                <Divider type={'vertical'} />
                <a style={{marginRight: '10px'}}>删除</a>
              </Popconfirm>
              {x1.nvl(record.rcv_pmt_amt, 0) > 0 &&
              <Popconfirm title={"确定要取消收款【" + record.inv_num + "】"} onConfirm={() => this.cancelReceivePayment(record)}>
                <Divider type={'vertical'}/>
                <a style={{marginRight: '10px'}}>取消收款</a>
              </Popconfirm>
              }
            </div>
            }
          </div>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="仓库">
              {form.getFieldDecorator('wh_cd')(
                <Select style={{width: '100px'}} allowClear={true}>
                  {this.state.listWh.map((item) => (
                    <Option key={item.wh_cd}>{item.wh_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="货主">
              {form.getFieldDecorator('ownr_id')(
                <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.state.listOwnr.map((item) => (
                    <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="商品">
              {form.getFieldDecorator('item_nm', {
              })(
                <Input style={{width: '130px'}} allowClear={true}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="入库日期">
              {form.getFieldDecorator('stock_in_dt', {
              })(
                <DatePicker style={{width: '130px'}} allowClear={true}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="收款">{form.getFieldDecorator(`wait_rcv_pmt_flg`, {initialValue:'*', rules: [{}]})(
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="*">全部</Radio.Button>
                <Radio.Button value="Y">待收款</Radio.Button>
                <Radio.Button value="N">已收款</Radio.Button>
              </Radio.Group>
            )}</FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
          </Form>
          <Table
            // rowKey={'stock_in_line_full_num'}
            loading={this.state.loading}
            dataSource={this.state.listInv}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>

        {this.state.isShowPopup &&
        <Modal visible={true} footer={null} onCancel={()=>this.handleCancelPopup()}>
          <InvoicePaymentEntryPopup inv={this.state.listInv[this.state.selIndex]} onSubmit={(input)=>this.onSubmitReceivePayment(input)}/>
        </Modal>
        }
        
      </BasicLayout>

    );
  };
}

InvoiceList = Form.create({})(InvoiceList);
export default InvoiceList;
