import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class ProductDetail extends PureComponent {
  state = {
    loading: false,
    listItemGrp: [],
  };

  componentDidMount() {
    const {form} = this.props;

    var prod_cd = x1.router.getParamIn().prod_cd;
    if (prod_cd > '') {
      // 更新
      form.setFieldsValue({prod_cd: prod_cd});
      this.search(prod_cd);
    } else {
      // 新增
    }
    this.initItemGroupList();
  };

  initItemGroupList() {
    const params = {
      apiUrl: "/api/itemGroup/list",
      success: (data) => {
        this.setState({
          listItemGrp: data,
        });
      },
    };
    x1.request(params);
  };

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/product/update",
        ...fieldsValue,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.prod_cd)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
      };
      x1.request(params);
    });
  };

  search(prod_cd) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/product/list",
      prod_cd: prod_cd,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="品类编码">
                    {form.getFieldDecorator('prod_cd')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="品类名称">
                    {form.getFieldDecorator('prod_nm', {
                      rules: [{
                        required: true,
                        message: '请输入品类名称',
                      }]
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="商品分类">
                    {form.getFieldDecorator('item_grp_cd')(
                      <Select style={{width: '200px'}} allowClear={true}>
                        {this.state.listItemGrp.map((item) => (
                          <Option key={item.item_grp_cd}>{item.item_grp_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  {/*<FormItem {...formItemLayout} label="预冷费">*/}
                    {/*{form.getFieldDecorator('pre_cool_unit_prc_amt', {*/}
                    {/*})(*/}
                      {/*<Input style={{width: '150px'}}/>*/}
                    {/*)}*/}
                  {/*</FormItem>*/}
                  {/*<FormItem {...formItemLayout} label="冷藏费">*/}
                    {/*{form.getFieldDecorator('store_unit_prc_amt', {*/}
                    {/*})(*/}
                      {/*<Input style={{width: '150px'}}/>*/}
                    {/*)}*/}
                  {/*</FormItem>*/}
                  {/*<FormItem {...formItemLayout} label="装卸费">*/}
                    {/*{form.getFieldDecorator('load_unit_prc_amt', {*/}
                    {/*})(*/}
                      {/*<Input style={{width: '150px'}}/>*/}
                    {/*)}*/}
                  {/*</FormItem>*/}
                  <FormItem {...formItemLayout} label="状态">
                    {form.getFieldDecorator('prod_sts_cd', {
                    })(
                      <Radio.Group buttonStyle="solid" disabled={true}>
                        <Radio.Button value="AC">可用</Radio.Button>
                        <Radio.Button value="IN">不可用</Radio.Button>
                      </Radio.Group>
                    )}
                  </FormItem>

                  <FormItem {...submitItemLayout}>
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                  </FormItem>
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

ProductDetail = Form.create({})(ProductDetail);
export default ProductDetail;
