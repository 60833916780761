import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider, Radio} from 'antd';
import {message} from "antd/lib/index";
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;


class RentInvoicePaymentEntryPopup extends PureComponent {
  state = {
  };

  componentDidMount() {
    const {form} = this.props;
    form.setFieldsValue({
      paid_amt: this.props.inv.tot_inv_amt,
      paid_dt: x1.getSystemDate(),
    });
  };

  handleSubmit= e  => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      var param = {
        rent_inv_num: this.props.inv.rent_inv_num,
        paid_amt: fieldsValue.paid_amt,
        paid_dt: fieldsValue.paid_dt,
      }
      this.props.onSubmit(param);
    });
  }

  render() {
    const {form} = this.props;
    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };
    return (
      <div>
        <Form style={{marginBottom: '16px'}}>
          <FormItem {...formItemLayout} label="支付日期">
            {form.getFieldDecorator('paid_dt', {
              rules: [{
                required: true,
                message: '请输入支付日期',
              }]
            })(
              <DatePicker style={{width: '150px'}}/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="支付金额">
            {form.getFieldDecorator('paid_amt', {
              rules: [{
                required: true,
                message: '请输入支付金额',
              }]
            })(
              <Input style={{width: '150px'}}/>
            )}
          </FormItem>
          <FormItem {...submitItemLayout}>
            <Button type="primary" onClick={this.handleSubmit}>提交</Button>
          </FormItem>
        </Form>
      </div>
    );
  };
}

RentInvoicePaymentEntryPopup = Form.create({})(RentInvoicePaymentEntryPopup);
export default RentInvoicePaymentEntryPopup;
