import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, Upload, Modal, Icon} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;


class UserProfileChangePassword extends PureComponent {

  handleChangePasswordSubmit = e => {
    var that = this;
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      const params = {
        apiUrl: "/api/user/changePassword",
        ...fieldsValue,
        usr_id: x1.user.getCurrentUserId(),
        success: (data) => {
          x1.showMessage('提交成功');
          x1.user.updatePassword(fieldsValue.new_pw_hash_txt);
          that.props.closePopup();
        },
      };
      x1.request(params);
    });
  };

  checkConfirmPassword = (rule, value, callback) => {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(values.new_pw_hash_txt > '' && values.conf_pw_hash_txt > '' && values.conf_pw_hash_txt != values.new_pw_hash_txt){
      callback("确认密码不正确");
      return;
    }
    callback();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form style={{width:'150px'}}>
          <Form.Item>
            {getFieldDecorator('cur_pw_hash_txt', {
              rules: [{ required: true, message: '请输入当前密码' }],
            })(
              <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="当前密码" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('new_pw_hash_txt', {
              rules: [{ required: true, message: '请输入新密码' }],
            })(
              <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="新密码" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('conf_pw_hash_txt', {
              rules: [
                { required: true, message: '请确认新密码' },
                { validator: this.checkConfirmPassword },
              ],
            })(
              <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="确认新密码" />
            )}
          </Form.Item>
          <Button type="primary" onClick={this.handleChangePasswordSubmit}>变更密码</Button>
        </Form>
      </div>
    );
  }
}

class UserProfile extends PureComponent {
  state = {
    loading: false,
    showPopupChngPw: false,
  };

  componentDidMount() {
    const {form} = this.props;

    var usrId = x1.user.getCurrentUserId();
    if (usrId > '') {
      this.search(usrId);
    }
  };

  handleChangePassword = e => {
    this.setState({showPopupChngPw: true});
  };

  search(usrId) {
    const {form} = this.props;
    const params = {
      apiUrl: "/api/user/list",
      curPage: 0,
      pageSize: 1,
      usr_id: usrId,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 3},
      wrapperCol: {span: 8},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 3},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>
              <FormItem {...formItemLayout} label="姓名">
                {form.getFieldDecorator('usr_nm', {
                  rules: [{
                  }]
                })(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="手机">
                {form.getFieldDecorator('phone_num', {
                  rules: [{
                  }]
                })(<Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <FormItem {...submitItemLayout}>
                <Button type="primary" onClick={this.handleChangePassword}>变更密码</Button>
              </FormItem>
            </Form>
          </Spin>
        </Card>
        <Modal
          title="更改密码"
          visible={this.state.showPopupChngPw}
          footer={null}
          onCancel={()=>this.setState({showPopupChngPw:false})}
        >
          <UserProfileChangePassword
            closePopup={()=>this.setState({showPopupChngPw:false})}
          />
        </Modal>
      </BasicLayout>
    );
  };
}

UserProfileChangePassword = Form.create({})(UserProfileChangePassword);
UserProfile = Form.create({})(UserProfile);
export default UserProfile;
