import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, DatePicker, Radio} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class ReportInventoryByDate extends PureComponent {
  state = {
    loading: false,
    loadingDownload: false,
    pagination: {},
    listItem: [],
    listOwnr: [],
    smryData: {},
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {from_rpt_dt, to_rpt_dt, ownr_nm} = state;
        form.setFieldsValue({from_rpt_dt, to_rpt_dt, ownr_nm});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initFromNoneBack();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initFromNoneBack(){
    const {form} = this.props;
    if(x1.report.hasConditionDate()){
      // 取上个画面的区间
      x1.report.loadConditionDate(this);
    } else {
      // 默认期间3个月
      var toDt = x1.getCurrentMoment();
      var fromDt = x1.getCurrentMoment().subtract(3, 'months');
      form.setFieldsValue({
        from_rpt_dt: fromDt,
        to_rpt_dt: toDt,
      });
    }
    // this.initSearch();
    this.initOwnerList();
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(x1.isNull(fieldsValue.ownr_id)){
        x1.showError('请输入货主');
        return;
      }

      this.searchList(0, 50);
      this.searchSummary();
    });
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      inv_tp_cd: 'M',
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  searchSummary() {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/reportByDateSummary",
      ...values,
      success: (data) => {
        if(data.length > 0) {
          this.setState({
              smryData: data[0],
          });
        }
      },
      complete: () => {
        this.setState({loadingSummary: false});
      }
    };
    this.setState({loadingSummary: true});
    x1.request(params);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/reportByDate",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/report/downloadReportByDate",
      ...values,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  onChangeDate(){
    setTimeout(()=>{
      const {form} = this.props;
      var values = form.getFieldsValue();
      x1.report.saveConditionDate(values.from_rpt_dt, values.to_rpt_dt);
    },1);
  }

  columns = [
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '报表天数', dataIndex: 'rpt_days'},
    {title: '日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.invty_ss_dt)}</div>
      )
    },
    {title: '期初数', dataIndex: 'beg_invty_qty'},
    {title: '期末数', dataIndex: 'end_invty_qty'},
    {title: '入库数/吨', dataIndex: 'stock_in_qty'},
    {title: '出库数/吨', dataIndex: 'stock_out_qty'},
    {title: '吞吐量/吨', dataIndex: 'in_out_qty'},
    {title: '入库数/件', dataIndex: 'by_qty_stock_in_qty'},
    {title: '出库数/件', dataIndex: 'by_qty_stock_out_qty'},
    {title: '吞吐量/件', dataIndex: 'by_qty_in_out_qty'},
    {title: '预冷费', dataIndex: 'pre_cool_fee_amt'},
    {title: '冷藏费', dataIndex: 'store_fee_amt'},
    {title: '搬运费', dataIndex: 'load_amt'},
    {title: '转场费', dataIndex: 'ship_fee_amt'},
    {title: '工本费', dataIndex: 'card_fee_amt'},
    {title: '其它费用', dataIndex: 'oth_amt'},
    {title: '总计', dataIndex: 'tot_fee_amt'},
    {title: '非自提数', dataIndex: 'stock_out_ship_qty'},
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{marginBottom: '16px'}}>
            <div>
              <FormItem {...formItemLayout} label="开始日期">
                {form.getFieldDecorator('from_rpt_dt')(
                  <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="-" colon={false}>
                {form.getFieldDecorator('to_rpt_dt')(
                  <DatePicker style={{width: '150px'}} onChange={(date, dateStr)=>this.onChangeDate()}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="货主">
                {form.getFieldDecorator('ownr_id')(
                  <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {this.state.listOwnr.map((item) => (
                      <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </div>
            <div style={{display:'flex', justifyContent:'flex-end', marginTop:'8px'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              <span style={{paddingRight:'16px'}}></span>
              <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>
            </div>
          </Form>

          <div className='box_cc' style={{background:'#FFFFB8', padding:'8px'}}>
            <div style={{marginRight:'32px'}}>{'报表天数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.rpt_days,'')}</div></div>
            <div style={{marginRight:'32px'}}>{'期初数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.beg_invty_qty,'')}</div></div>
            <div style={{marginRight:'32px'}}>{'期末数：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.end_invty_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'入库数/吨：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.stock_in_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'出库数/吨：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.stock_out_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'吞吐量/吨：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.in_out_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'入库数/件：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.by_qty_stock_in_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'出库数/件：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.by_qty_stock_out_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'吞吐量/件：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.by_qty_in_out_qty,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'预冷费：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.pre_cool_fee_amt,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'冷藏费：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.store_fee_amt,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'搬运费：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.load_amt,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'转场费：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.ship_fee_amt,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'工本费：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.card_fee_amt,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'其它费用：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.oth_amt,0)}</div></div>
            <div style={{marginRight:'32px'}}>{'总计：'}<div style={{color:'blue'}}>{x1.nvl(this.state.smryData.tot_fee_amt,0)}</div></div>
          </div>

          <Table
            // rowKey={'ownr_id'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

ReportInventoryByDate = Form.create({})(ReportInventoryByDate);
export default ReportInventoryByDate;
