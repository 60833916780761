import React, {PureComponent} from 'react';
import {Card, Form, Select, Table} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class OwnerPrePaymentDetail extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    head: {},
    listItem: [],
  };

  componentDidMount() {
    var head = x1.router.getParamIn();
    this.state.head = head;
    this.setState({head: head});
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    var head = this.state.head;

    const params = {
      apiUrl: "/api/prePayment/listLine",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      pre_pmt_pk: head.pre_pmt_pk,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  columns = [
    {title: '结算日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.inv_to_dt)}</div>
      ),
    },
    {title: '结算金额', dataIndex: 'inv_to_dt_amt'},
    {title: '收款日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.paid_dt)}</div>
      ),
    },
    {title: '收款金额', dataIndex: 'paid_amt'},
    {title: '商品', dataIndex: 'item_nm'},
    {title: '单位重量', dataIndex: 'unit_kg_amt'},
    {title: '入库日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.stock_in_dt)}</div>
      ),
    },
    {title: '入库数量', dataIndex: 'stock_in_qty'},
  ];

  render() {

    var styleItem = {
      width:'200px',
      height: '40px',
      display: 'flex',
    };
    var styleItemTitle = {
      width:'80px',
    };
    var styleItemValue = {
      flex: 1,
    };

    var head = this.state.head;

    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'flex-start', marginBottom: '16px'}}>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'货主：'}</div>
                <div style={styleItemValue}>{head.ownr_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'结算日期：'}</div>
                <div style={styleItemValue}>{x1.date2Text(head.paid_dt)}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'结算金额：'}</div>
                <div style={styleItemValue}>{head.paid_amt}</div>
              </div>
            </div>
          </Form>
          <Table
            // rowKey={'stock_out_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>

      </BasicLayout>

    );
  };
}

OwnerPrePaymentDetail = Form.create({})(OwnerPrePaymentDetail);
export default OwnerPrePaymentDetail;
