import React, {Component} from 'react';
import {Avatar, Breadcrumb, Dropdown, Icon, Layout, Menu, Button} from 'antd';
import {x1} from '../x1';
import logo from '../assets/logo.png';

const { Header, Footer, Sider, Content } = Layout;
const SubMenu = Menu.SubMenu;

class BasicLayout extends Component {

  handleRightTopMenuClick = ({ key }) => {
    if (key === 'userinfo') {
      x1.router.relaunch("/UserProfile");
      return;
    }
    if (key === 'logout') {
      x1.user.logout();
      x1.router.relaunch();
      return;
    }
  };

  handleBack = e => {
    x1.router.back();
  };

  render() {
    // 右上方头像的菜单
    const menuTopRight = (
      <Menu selectedKeys={[]} onClick={this.handleRightTopMenuClick} style={{margin:'4px 16px', padding:'4px 8px'}}>
        <Menu.Item key="userinfo">
          <Icon type="setting" />个人设置
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout">
          <Icon type="logout" />退出登录
        </Menu.Item>
      </Menu>
    );

    let menuLeft = x1.router.getLeftMenu();
    if(menuLeft == null){
      menuLeft = [];
    }

    let getMenuLeftOpenKeys = function(){
      // 从栈的顶部向底部查找，找到在Menu里有注册的就返回
      var pages = x1.router.getPages();
      for(var k=pages.length-1; k>=0; k--){
        var page = pages[k];
        for(var i=0; i<menuLeft.length; i++){
          var subMenu = menuLeft[i];
          for(var j=0; j<subMenu.items.length; j++){
            var item = subMenu.items[j];
            if(item.path == page.path){
              return [subMenu.id]
            }
          }
        }
      }
      return [];
    }

    let getMenuLeftSelectedKeys = function(){
      var pages = x1.router.getPages();
      for(var k=pages.length-1; k>=0; k--) {
        var page = pages[k];
        for (var i = 0; i < menuLeft.length; i++) {
          var subMenu = menuLeft[i];
          for (var j = 0; j < subMenu.items.length; j++) {
            var item = subMenu.items[j];
            if (item.path == page.path) {
              return [item.id]
            }
          }
        }
      }
      return [];
    };

    let getPageStack = function(){
      var arr = [];
      var pages = x1.router.getPages();
      for(var i=0; i<pages.length; i++){
        var page = pages[i];
        var item = {...page};
        var def = x1.router.getPageDefinition(item.path);
        if(def != null){
          item = {...item, ...def};
        }
        item.last = (i==pages.length-1);
        item.backCount = pages.length - i - 1;
        arr.push(item);
      }
      return arr;
    };
    let pageStack = getPageStack();

    const currentUser = x1.user.getCurrentUser();

    return (
      <Layout>
        {/*-- 左边 --*/}
        <Sider width={180} style={{ minHeight: '100vh', boxShadow:'2px 0 6px rgba(0,21,41,.35)'}}>
          {/*-- LOGO及公司名称 --*/}
          <div style={{ height: '64px', background: 'rgba(255,255,255,.2)', padding:'0 24px'}} className={'box_cc'}>
            {/*<img src={logo} style={{width:'28px', height:'28px', marginRight:'16px'}}/>*/}
            <div className={'box_bd'} style={{color:'#FFF', fontWeight:'600', fontSize:'18px'}}>冷库管理</div>
          </div>
          {/*-- 主菜单 --*/}
          <Menu theme="dark" mode="inline" defaultOpenKeys={getMenuLeftOpenKeys()} defaultSelectedKeys={getMenuLeftSelectedKeys()} style={{fontWeight:'600'}}>
            {menuLeft.map((subMenu) => (
              <SubMenu key={subMenu.id} title={<span><Icon type="dashboard" /><span>{subMenu.name}</span></span>}>
                {subMenu.items.map((menuItem) => (
                  <Menu.Item key={menuItem.id} onClick={()=>x1.router.relaunch(menuItem.path)}>{menuItem.name}</Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        </Sider>

        {/*-- 右边 --*/}
        <Layout >
          {/*-- 页头 --*/}
          <Header style={{ background: '#fff', textAlign: 'center', padding: 0 }}>
            <div className="box_cc">
              {/*-- 页面面包屑 --*/}
              <div className="box_hd box_cc">

                {/*返回按钮*/}
                {pageStack.length > 1 &&
                <Button icon={'arrow-left'} style={{marginLeft: '24px'}} onClick={this.handleBack}>返回</Button>
                }

                <Breadcrumb style={{padding:'0 24px'}}>
                  {pageStack.map((page) => (
                    <Breadcrumb.Item key={page.path}>
                      {page.last?
                        <span>{page.name}</span>:
                        <a onClick={page.last?'':()=>x1.router.back({delta:page.backCount})}>{page.name}</a>
                      }
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
                {/*<Button shape="circle" icon="reload" size={'small'} />*/}
              </div>
              <div className="box_bd"></div>
              {/*用户信息*/}
              <div className="box_ft">
                <Dropdown overlay={menuTopRight}>
                  <span className="box_cc">
                    <Avatar size="small" src={x1.user.getCurrentUserHeadImageUrl()}/>
                    <span style={{padding:'0 24px 0 16px'}}>{currentUser.usr_nm}</span>
                  </span>
                </Dropdown>
              </div>
            </div>
          </Header>

          {/*-- 主内容区 --*/}
          <Content style={{margin:'24px 24px 0 24px'}}>
              {this.props.children}
          </Content>

        </Layout>
      </Layout>
    );
  }
}

export default BasicLayout;
