import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class OwnerDetail extends PureComponent {
  state = {
    loading: false,
  };

  componentDidMount() {
    const {form} = this.props;

    var ownr_id = x1.router.getParamIn().ownr_id;
    if (ownr_id > '') {
      // 更新
      form.setFieldsValue({ownr_id: ownr_id});
      this.search(ownr_id);
    } else {
      // 新增
      form.setFieldsValue({dep_cr_limit_ratio: 2.5});
    }
  };

  hasFunctionEdit(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH) || x1.user.hasRole(x1.ROLE.GEN_MGR)){
      return true;
    }
    return false;
  }

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();

    if(fieldsValue.fix_load_inner_prc_flg == 'Y'){
      if(x1.isNull(fieldsValue.fix_load_inner_am_prc_amt)){
        x1.showError('请输入搬运工单价(上午)');
        return false;
      }
      if(x1.isNull(fieldsValue.fix_load_inner_pm_prc_amt)){
        x1.showError('请输入搬运工单价(下午)');
        return false;
      }
      if(x1.isNull(fieldsValue.fix_load_inner_ng_prc_amt)){
        x1.showError('请输入搬运工单价(晚上)');
        return false;
      }
    }

    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/owner/update",
        ...fieldsValue,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.ownr_id)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
            this.search(data.ownr_id);
          }
        },
      };
      x1.request(params);
    });
  };

  search(ownr_id) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/owner/list",
      ownr_id: ownr_id,
      get_dep_flg: 'Y',
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="货主编码">
                    {form.getFieldDecorator('ownr_id')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="货主名称">
                    {form.getFieldDecorator('ownr_nm', {
                      rules: [{
                        required: true,
                        message: '请输入货主名称',
                      }]
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="联系人">
                    {form.getFieldDecorator('ctac_psn_nm', {
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="手机号">
                    {form.getFieldDecorator('ctac_tel_num', {
                    })(
                      <Input style={{width: '300px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="地址">
                    {form.getFieldDecorator('ownr_addr', {
                      rules: [{
                        required: true,
                        message: '请输入地址',
                      }]
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="限额">
                    {form.getFieldDecorator('cr_limit_amt', {
                      // rules: [{
                      //   required: true,
                      //   message: '请输入限额',
                      // }]
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="押金限额比例">
                    {form.getFieldDecorator('dep_cr_limit_ratio', {
                      rules: [{
                        required: true,
                        message: '请输入押金限额比例',
                      }]
                    })(
                      <Input style={{width: '150px'}} />
                    )}
                    <div style={{lineHeight:'100%'}}>{'限额 = 押金 * 押金限额比例'}</div>
                  </FormItem>
                  {/*<FormItem {...formItemLayout} label="货主冷藏费单价">*/}
                    {/*{form.getFieldDecorator('ownr_store_unit_prc_amt', {*/}
                    {/*})(*/}
                      {/*<Input style={{width: '150px'}}/>*/}
                    {/*)}*/}
                  {/*</FormItem>*/}
                  <FormItem {...formItemLayout} label="状态">
                    {form.getFieldDecorator('ownr_sts_cd', {
                    })(
                      <Radio.Group buttonStyle="solid" disabled={true}>
                        <Radio.Button value="AC">可用</Radio.Button>
                        <Radio.Button value="IN">不可用</Radio.Button>
                      </Radio.Group>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="账单类型">
                    {form.getFieldDecorator('inv_tp_cd', {
                      rules: [{
                        required: true,
                        message: '请输入账单类型',
                      }]
                    })(
                      <Radio.Group buttonStyle="solid">
                        <Radio.Button value="M">按月</Radio.Button>
                        <Radio.Button value="S">按单</Radio.Button>
                      </Radio.Group>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="备注">
                    {form.getFieldDecorator('ownr_memo_txt', {
                    })(
                      <Input style={{width: '500px'}} />
                    )}
                  </FormItem>

                  <FormItem {...submitItemLayout}>
                    {this.hasFunctionEdit() &&
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                    }
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="是否固定搬运工单价">
                    {form.getFieldDecorator('fix_load_inner_prc_flg', {
                    })(
                      <Radio.Group buttonStyle="solid" >
                        <Radio.Button value="Y">固定</Radio.Button>
                        <Radio.Button value="N">不固定</Radio.Button>
                      </Radio.Group>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="搬运工单价(上午)">
                    {form.getFieldDecorator('fix_load_inner_am_prc_amt', {
                    })(
                      <Input style={{width: '150px'}} />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="搬运工单价(下午)">
                    {form.getFieldDecorator('fix_load_inner_pm_prc_amt', {
                    })(
                      <Input style={{width: '150px'}} />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="搬运工单价(晚上)">
                    {form.getFieldDecorator('fix_load_inner_ng_prc_amt', {
                    })(
                      <Input style={{width: '150px'}} />
                    )}
                  </FormItem>
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

OwnerDetail = Form.create({})(OwnerDetail);
export default OwnerDetail;
