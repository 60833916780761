import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class InventoryList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listInvty: [],
    listItem: [],
    listProd: [],
    listWh: [],
    listWhLoc: [],
    listOwnr: [],

    loadingDownload: false,
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {wh_cd, wh_loc_cd, ownr_id, item_cd, prod_cd, stock_in_dt} = state;
        form.setFieldsValue({wh_cd, wh_loc_cd, ownr_id, item_cd, prod_cd, stock_in_dt});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initWarehouseList();
      this.initWarehouseLocationList();
      this.initOwnerList();
      this.initItemList();
      this.initProductList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      invty_flg: 'Y',
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseLocationList() {
    const params = {
      apiUrl: "/api/warehouseLocation/list",
      invty_flg: 'Y',
      success: (data) => {
        this.setState({
          listWhLoc: data,
        });
      },
    };
    x1.request(params);
  };

  initItemList() {
    const params = {
      apiUrl: "/api/item/list",
      invty_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
        });
      },
    };
    x1.request(params);
  };

  initProductList() {
    const params = {
      apiUrl: "/api/product/list",
      invty_flg: 'Y',
      success: (data) => {
        this.setState({
          listProd: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      invty_flg: 'Y',
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/inventory/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      non_zero_flg: 'Y',
      success: (data) => {
        this.setState({
          listInvty: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/inventory/downloadCountSheet",
      ...values,
      non_zero_flg: 'Y',
      zero_loc_flg: 'Y',
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  columns = [
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '库房', dataIndex: 'wh_loc_nm'},
    {title: '商品', dataIndex: 'item_nm'},
    {title: '单位重量kg', dataIndex: 'unit_kg_amt'},
    {title: '品类', dataIndex: 'prod_nm'},
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '入库日期',
      render: (text, record) => (
        <div>{x1.date2Text(record.stock_in_dt)}</div>
      )
    },
    {title: '库存数量', dataIndex: 'invty_qty'},
    {title: '库存吨数', dataIndex: 'invty_tnng_amt'},
    {title: '入库数量', dataIndex: 'stock_in_qty'},
    {title: '入库吨数', dataIndex: 'stock_in_tnng_amt'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <div></div>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="仓库">
                {form.getFieldDecorator('wh_cd')(
                  <Select style={{width: '100px'}} allowClear={true}>
                    {this.state.listWh.map((item) => (
                      <Option key={item.wh_cd}>{item.wh_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="库房">
                {form.getFieldDecorator('wh_loc_cd')(
                  <Select style={{width: '150px'}} allowClear={true}>
                    {this.state.listWhLoc.map((item) => (
                      <Option key={item.wh_loc_cd}>{item.wh_loc_full_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="货主">
                {form.getFieldDecorator('ownr_id')(
                  <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {this.state.listOwnr.map((item) => (
                      <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="商品">
                {form.getFieldDecorator('item_cd')(
                  <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {this.state.listItem.map((item) => (
                      <Option key={item.item_cd}>{item.item_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="品类">
                {form.getFieldDecorator('prod_cd')(
                  <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {this.state.listProd.map((item) => (
                      <Option key={item.prod_cd}>{item.prod_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="入库日期">
                {form.getFieldDecorator('stock_in_dt', {
                })(
                  <DatePicker style={{width: '130px'}} allowClear={true}/>
                )}
              </FormItem>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              <span style={{paddingRight:'16px'}}></span>
              <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载盘点单</Button>
            </div>
          </Form>
          <Table
            rowKey={'invty_pk'}
            loading={this.state.loading}
            dataSource={this.state.listInvty}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

InventoryList = Form.create({})(InventoryList);
export default InventoryList;
