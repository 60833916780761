import React, {Fragment, PureComponent} from 'react';
import {Modal, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import OtherAmountEntryPopup from "./OtherAmountEntryPopup";

const FormItem = Form.Item;
const {Option} = Select;
const {MonthPicker} = DatePicker;

class SummaryMonthInvoiceByOwner extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listInv: [],
    listOwnr: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {inv_mth, ownr_id} = state;
        form.setFieldsValue({inv_mth, ownr_id});
        this.refreshPage();
      }
    }

    if(!isBack) {
      form.setFieldsValue({inv_mth: x1.getSystemDate()});
      this.initSearch();
      this.initOwnerList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      inv_tp_cd: 'M',
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/invoice/summaryMonthInvoiceByOwner",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listInv: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/invoice/downloadSummaryMonthInvoiceByOwner",
      ...values,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  columns = [
    {title: '货主', dataIndex: 'ownr_nm', width:150},
    {title: '电话', dataIndex: 'ctac_tel_num', width:150},
    {title: '账单金额', dataIndex: 'inv_amt', width:150},
    {title: '备注', dataIndex: 'ownr_memo_txt',},
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="账单月份">
              {form.getFieldDecorator('inv_mth', {
                rules: [{
                  required: true,
                  message: '请输入账单月份',
                }]
              })(
                <MonthPicker style={{width: '150px'}} />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="货主">
              {form.getFieldDecorator('ownr_id')(
                <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.state.listOwnr.map((item) => (
                    <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <span style={{paddingRight:'16px'}}></span>
            <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>
          </Form>
          <Table
            loading={this.state.loading}
            dataSource={this.state.listInv}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>

      </BasicLayout>

    );
  };
}

SummaryMonthInvoiceByOwner = Form.create({})(SummaryMonthInvoiceByOwner);
export default SummaryMonthInvoiceByOwner;
