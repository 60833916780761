import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class TemporaryEmployeeDetail extends PureComponent {
  state = {
    loading: false,
    listJobTtl: [],
    listWh: [],
  };

  componentDidMount() {
    const {form} = this.props;
    this.initJobTtl();
    this.initWarehouseList();

    var temp_emp_id = x1.router.getParamIn().temp_emp_id;
    if (temp_emp_id > '') {
      form.setFieldsValue({temp_emp_id: temp_emp_id});
      this.search(temp_emp_id);
    } else {
    }
  };

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      if(x1.isNull(fieldsValue.temp_emp_id)){
        const params = {
          apiUrl: "/api/tempEmp/insert",
          ...fieldsValue,
          success: (data) => {
            x1.showMessage('提交成功');
            // 新增时返回列表
            if(x1.isNull(fieldsValue.temp_emp_id)){
              x1.router.back();
            }
          },
        };
        x1.request(params);
      } else {
        const params = {
          apiUrl: "/api/tempEmp/update",
          ...fieldsValue,
          success: (data) => {
            x1.showMessage('提交成功');
            form.setFieldsValue(data);
          },
        };
        x1.request(params);
      };
    });
  };

  search(temp_emp_id) {
    const {form} = this.props;
    const params = {
      apiUrl: "/api/tempEmp/list",
      curPage: 0,
      pageSize: 1,
        temp_emp_id: temp_emp_id,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initJobTtl() {
    const params = {
      apiUrl: "/api/jobTtlTp/list",
      success: (data) => {
        this.setState({
            listJobTtl: data,
        });
      },
    };
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 3},
      wrapperCol: {span: 8},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 3},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>
              <FormItem {...formItemLayout} label="员工编号">
                {form.getFieldDecorator('temp_emp_id')(
                  <Input style={{width: '150px'}} disabled={true}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="姓名">
                {form.getFieldDecorator('temp_emp_nm', {
                  rules: [{
                    required: true,
                    message: '请输入姓名',
                  }]
                })(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="手机">
                {form.getFieldDecorator('temp_emp_phone', {
                  rules: [{
                    required: true,
                    message: '请输入手机',
                  }]
                })(<Input style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="职位">
                {form.getFieldDecorator('job_ttl_tp_cd', {
                  rules: [{
                    required: true,
                    message: '请选择职位',
                  }]
                })(
                  <Select style={{width: '150px'}}>
                    {this.state.listJobTtl.map((item) => (<Option key={item.job_ttl_tp_cd}>{item.job_ttl_tp_nm}</Option>))}
                  </Select>
                )}
              </FormItem>
              {/*<FormItem {...formItemLayout} label="单价">*/}
                {/*{form.getFieldDecorator('unit_prc_amt', {*/}
                  {/*rules: [{*/}
                    {/*required: true,*/}
                    {/*message: '请输入单价',*/}
                  {/*}]*/}
                {/*})(<Input style={{width: '150px'}}/>*/}
                {/*)}*/}
              {/*</FormItem>*/}
              {/*<FormItem {...formItemLayout} label="加班单价">*/}
                {/*{form.getFieldDecorator('unit_over_hr_amt', {*/}
                  {/*rules: [{*/}
                    {/*required: true,*/}
                    {/*message: '请输入单价',*/}
                  {/*}]*/}
                {/*})(<Input style={{width: '150px'}}/>*/}
                {/*)}*/}
              {/*</FormItem>*/}
              <FormItem {...formItemLayout} label="仓库">
                {form.getFieldDecorator('wh_cd', {
                  rules: [{
                    required: true,
                    message: '请输入仓库',
                  }]
                })(
                  <Select style={{width: '100px'}} allowClear={true}>
                    {this.state.listWh.map((item) => (
                      <Option key={item.wh_cd}>{item.wh_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="状态">
                {form.getFieldDecorator('temp_emp_sts_cd', {
                  rules: [{
                    required: true,
                    message: '请输入状态',
                  }]
                })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="AC">可用</Radio.Button>
                    <Radio.Button value="IN">不可用</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem {...submitItemLayout}>
                <Button type="primary" onClick={this.handleSubmit}>提交</Button>
              </FormItem>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

TemporaryEmployeeDetail = Form.create({})(TemporaryEmployeeDetail);
export default TemporaryEmployeeDetail;
