import React, {PureComponent} from 'react';
import {Button, Card, Form, Input, Radio, Select, Spin} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import {DatePicker} from "antd/lib/index";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;
const {MonthPicker} = DatePicker;

class XxInventoryTurnRateDetail extends PureComponent {
  state = {
    loading: false,
    listUsrRole: [],
      xx_invty_turn_over_pk: '',
  };

  componentDidMount() {
    const {form} = this.props;

    var xx_invty_turn_over_pk = x1.router.getParamIn().xx_invty_turn_over_pk;
    if (xx_invty_turn_over_pk > '') {
      this.setState({xx_invty_turn_over_pk: xx_invty_turn_over_pk});
      form.setFieldsValue({xx_invty_turn_over_pk: xx_invty_turn_over_pk});
      this.search(xx_invty_turn_over_pk);
    } else {
    }
  };

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      const params = {
        apiUrl: this.state.xx_invty_turn_over_pk > '' ? "/api/xxInvtyTurnOver/update" : "/api/xxInvtyTurnOver/insert" ,
        ...fieldsValue,
          xx_invty_turn_over_pk: this.state.xx_invty_turn_over_pk,
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(this.state.xx_car_pk == ''){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
      };
      x1.request(params);
    });
  };

  search(xx_invty_turn_over_pk) {
    const {form} = this.props;
    const params = {
      apiUrl: "/api/xxInvtyTurnOver/list",
      curPage: 0,
      pageSize: 1,
        xx_invty_turn_over_pk: xx_invty_turn_over_pk,
      success: (data) => {
        form.setFieldsValue(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };


  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 3},
      wrapperCol: {span: 8},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 3},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>
                <FormItem {...formItemLayout} label="月份">
                    {form.getFieldDecorator('rpt_mth', {
                        rules: [{
                            required: true,
                            message: '请输入月份',
                        }]
                    })(
                        <MonthPicker style={{width: '150px'}}/>
                    )}
                </FormItem>
              <FormItem {...formItemLayout} label="商品类型">
                {form.getFieldDecorator('prod_tp_nm', {
                  rules: [{
                    required: true,
                    message: '请输入商品类型',
                  }]
                })(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
                <FormItem {...formItemLayout} label="周转率">
                    {form.getFieldDecorator('turn_over_rate', {
                        rules: [{
                            required: true,
                            message: '周转率',
                        }]
                    })(<Input style={{width: '150px'}}/>
                    )}
                </FormItem>


              <FormItem {...submitItemLayout}>
                <Button type="primary" onClick={this.handleSubmit}>提交</Button>
              </FormItem>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

XxInventoryTurnRateDetail = Form.create({})(XxInventoryTurnRateDetail);
export default XxInventoryTurnRateDetail;
