import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class OwnerProductList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listProd: [],
    listOwnr: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {ownr_id, prod_cd} = state;
        form.setFieldsValue({ownr_id, prod_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      // this.initSearch();
      this.initProductList();
      this.initOwnerList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  initProductList() {
    const params = {
      apiUrl: "/api/product/list",
      success: (data) => {
        this.setState({
          listProd: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    // if(x1.isNull(values.ownr_id)){
    //   x1.showError('请先选择货主');
    //   return;
    // }

    const params = {
      apiUrl: "/api/ownerPrice/listOwnerProduct",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      get_prc_list_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  openProductPrice(record) {
    x1.router.push('/OwnerPriceList', record, x1.getStateSnapshort(this));
  };

  columns = [
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '品类名称', dataIndex: 'prod_nm'},
    {title: '最新单价',
      render: (text, record) => (
        <div>
          {record.unitPrc.ownr_prc_pk > '' &&
          <div>
            <div>{x1.date2Text(record.unitPrc.from_dt) + ' ~ ' + x1.date2Text(record.unitPrc.to_dt)}</div>
            <div>{'预冷费：' + record.unitPrc.pre_cool_unit_prc_amt}</div>
            <div>{'冷藏费：' + record.unitPrc.store_unit_prc_amt}</div>
            <div>{'装卸费：' + record.unitPrc.load_unit_prc_amt}</div>
            <div>{'工本费：' + record.unitPrc.card_unit_prc_amt}</div>
            <div>
              <div>{'时间带搬运费：'}</div>
              {record.unitPrc.listPrc.map((prc)=>
                <div>{x1.time2Text(prc.from_tm) + " - " + x1.time2Text(prc.to_tm) + '：' + prc.load_unit_prc_amt + '元'}</div>
              )}
            </div>
          </div>
          }
        </div>
      ),
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openProductPrice(record)} style={{marginRight: '10px'}}>期间价格</a>
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="货主">
              {form.getFieldDecorator('ownr_id')(
                <Select style={{width: '200px'}} allowClear={true}
                        showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {this.state.listOwnr.map((item) => (
                    <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="品类">
              {form.getFieldDecorator('prod_cd')(
                <Select style={{width: '200px'}} allowClear={true}
                        showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {this.state.listProd.map((item) => (
                    <Option key={item.prod_cd}>{item.prod_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="是否有价格">
              {form.getFieldDecorator('has_prc_flg')(
                <Select style={{width: '100px'}} allowClear={true}>
                  <Option key={'Y'}>{'有价格'}</Option>
                  <Option key={'N'}>{'无价格'}</Option>
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            {/*{this.state.listItem.length == 0 &&*/}
            {/*<div style={{marginLeft:'16px'}}>{'请先选择货主'}</div>*/}
            {/*}*/}
          </Form>
          <Table
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

OwnerProductList = Form.create({})(OwnerProductList);
export default OwnerProductList;
