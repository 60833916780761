import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Radio, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import RentMonthChargeList from "./RentMonthChargeList";

const FormItem = Form.Item;
const {Option} = Select;

class RentOrderList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],

    listWh: [],
    listWhLoc: [],
    listOwnr: [],

    loadingDownload: false,
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {rent_ownr_id, ownr_tel_num, item_nm, wh_cd, wh_loc_cd, actv_rent_ord_flg} = state;
        form.setFieldsValue({rent_ownr_id, ownr_tel_num, item_nm, wh_cd, wh_loc_cd, actv_rent_ord_flg});
        this.refreshPage();
      }
    }

    if(!isBack) {
      var paramIn = x1.router.getParamIn();
      form.setFieldsValue({
        actv_rent_ord_flg:'Y',
        rent_ownr_id: paramIn.rent_ownr_id,
      });
      this.state.rent_ownr_id = paramIn.rent_ownr_id;
      this.initSearch();
      this.initWarehouseList();
      this.initOwnerList();
    }
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/rentOwner/list",
      rent_ownr_sts_cd: 'AC',
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/rentOrder/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/rentOrder/downloadOrder",
      ...values,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  handleAdd = e => {
    var param = {};
    if(x1.hasValue(this.state.rent_ownr_id)){
      param.rent_ownr_id = this.state.rent_ownr_id;
    }
    x1.router.push("/RentOrderDetail", param, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/RentOrderDetail', record, x1.getStateSnapshort(this));
  };

  // openOtherCharge(record) {
  //   x1.router.push('/RentMonthChargeList', record, x1.getStateSnapshort(this));
  // };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/rentOrder/delete",
      rent_ord_num: record.rent_ord_num,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  onChanageWarehouse(wh_cd){
    const {form} = this.props;
    form.setFieldsValue({'wh_cd': wh_cd});
    this.initWarehouseLocationList();
  };

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initWarehouseLocationList() {
    const {form} = this.props;
    var values = form.getFieldsValue();
    if(x1.isNull(values.wh_cd)){
      this.setState({listWhLoc:[]});
      return;
    }
    const params = {
      apiUrl: "/api/warehouseLocation/list",
      wh_cd: values.wh_cd,
      success: (data) => {
        this.setState({
          listWhLoc: data,
        });
      },
    };
    x1.request(params);
  };

  columns = [
    {title: '单号,下单时间', render: (text, record) => (
        <div>
          <div>{record.rent_ord_num}</div>
          <div>{record.ord_ts}</div>
        </div>
      ),
    },
    {title: '货主', render: (text, record) => (
        <div>
          <div>{record.ownr_nm}</div>
          <div>{record.ctac_psn_nm}</div>
          <div>{record.ownr_tel_num}</div>
        </div>
      ),
    },
    // {title: '电话', dataIndex: 'ownr_tel_num'},
    {title: '商品', dataIndex: 'item_nm'},
    {title: '货主备注', dataIndex: 'ownr_memo_txt'},
    {title: '押金, 收取日', render: (text, record) => (
        <div>
          <div>{record.dep_rcv_amt}</div>
          <div>{x1.date2Text(record.dep_rcv_dt)}</div>
        </div>
      ),
    },
    {title: '仓库', dataIndex: 'wh_nm'},
    // {title: '库房', dataIndex: 'wh_loc_nm'},
    {title: '租用面积m2', dataIndex: 'rent_area_amt'},
    {title: '价格', render: (text, record) => (
        <div>
          <div>{'月总价：' + x1.nvl(record.rent_mth_tot_prc_amt)}</div>
          <div>{'平米天单价：' + record.rent_unit_prc_amt}</div>
          <div>{record.rent_unit_prc_tp_nm}</div>
        </div>
      ),
    },
    {title: '租用开始日', render: (text, record) => (
        <div>{x1.date2Text(record.rent_from_dt)}</div>
      ),
    },
    {title: '租用结束日', render: (text, record) => (
        <div>{x1.date2Text(record.rent_to_dt)}</div>
      ),
    },
    {title: '合同备注', dataIndex: 'memo_txt'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          {this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除订单【" + record.ownr_nm  + ', ' + record.item_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'} />
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
          {/*<a onClick={() => this.openOtherCharge(record)} style={{marginRight: '10px'}}>其它收费</a>*/}
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="货主">
                {form.getFieldDecorator('rent_ownr_id')(
                  <Select style={{width: '150px'}} allowClear={true} disabled={x1.hasValue(this.state.rent_ownr_id)}
                          showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {this.state.listOwnr.map((item) => (
                      <Option key={item.rent_ownr_id}>{item.rent_ownr_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="电话" allowClear={true}>
                {form.getFieldDecorator('ownr_tel_num')(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="商品" allowClear={true}>
                {form.getFieldDecorator('item_nm')(
                  <Input style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="仓库">
                {form.getFieldDecorator('wh_cd', {
                })(
                  <Select style={{width: '150px'}} allowClear={true} onChange={(value)=>this.onChanageWarehouse(value)}>
                    {this.state.listWh.map((item) => (
                      <Option key={item.wh_cd}>{item.wh_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="库房">
                {form.getFieldDecorator('wh_loc_cd', {
                })(
                  <Select style={{width: '150px'}} allowClear={true}>
                    {this.state.listWhLoc.map((item) => (
                      <Option key={item.wh_loc_cd}>{item.wh_loc_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="租赁日期">
                {form.getFieldDecorator('rent_from_dt')(
                  <DatePicker style={{width: '150px'}} />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="-" colon={false}>
                {form.getFieldDecorator('rent_to_dt')(
                  <DatePicker style={{width: '150px'}} />
                )}
              </FormItem>
            </div>
            <div className={'box_vc'} style={{marginBottom: '16px'}}>
              <div className={'box_bd'}>
                <FormItem {...formItemLayout} label="状态">
                  {form.getFieldDecorator('actv_rent_ord_flg', {
                  })(
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="N">全部</Radio.Button>
                      <Radio.Button value="Y">当前</Radio.Button>
                    </Radio.Group>
                  )}
                </FormItem>
              </div>
              <div className={'box_ft'}>
                <Button type="primary" onClick={this.handleSearch}>查询</Button>
                <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增</Button>
                <Button onClick={this.handleDownload} loading={this.state.loadingDownload} style={{marginLeft:'16px'}}>下载</Button>
              </div>
            </div>
          </Form>
          <Table
            rowKey={'rent_ord_num'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

RentOrderList = Form.create({})(RentOrderList);
export default RentOrderList;
