import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class OwnerList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {ownr_nm, ctac_psn_nm, ctac_tel_num} = state;
        form.setFieldsValue({ownr_nm, ctac_psn_nm, ctac_tel_num});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  hasFunctionAdd(){
    if(x1.user.hasRole(x1.ROLE.ADMIN) || x1.user.hasRole(x1.ROLE.FIN) || x1.user.hasRole(x1.ROLE.CASH) || x1.user.hasRole(x1.ROLE.GEN_MGR)){
      return true;
    }
    return false;
  }

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/owner/list",
      curPage: curPage,
      pageSize: pageSize,
      get_dep_flg: 'Y',
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/OwnerDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/OwnerDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/owner/delete",
      ownr_id: record.ownr_id,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  resetPassword(record) {
    const params = {
      apiUrl: "/api/owner/resetPassword",
      ownr_id: record.ownr_id,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  closeAccount(record) {
    const params = {
      apiUrl: "/api/owner/closeAccount",
      ownr_id: record.ownr_id,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  openAccount(record) {
    const params = {
      apiUrl: "/api/owner/openAccount",
      ownr_id: record.ownr_id,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  columns = [
    {title: '货主编号', dataIndex: 'ownr_id'},
    {title: '货主名称', dataIndex: 'ownr_nm'},
    {title: '联系人', dataIndex: 'ctac_psn_nm'},
    {title: '手机号', dataIndex: 'ctac_tel_num'},
    {title: '地址', dataIndex: 'ownr_addr'},
    {title: '限额', dataIndex: 'cr_limit_amt'},
    {title: '押金', dataIndex: 'dep_rcv_amt'},
    {title: '押金限额比例', dataIndex: 'dep_cr_limit_ratio'},
    // {title: '货主冷藏费', dataIndex: 'ownr_store_unit_prc_amt'},
    {title: '货主状态', dataIndex: 'ownr_sts_nm'},
    {title: '库存状态', dataIndex: 'has_invty_nm'},
    {title: '账单类型', dataIndex: 'inv_tp_nm'},
    {title: '备注', dataIndex: 'ownr_memo_txt'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          {this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除货主【" + record.ownr_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
          {record.ownr_sts_cd == 'AC' && this.hasFunctionAdd() &&
          <Popconfirm title={"确定要重置密码【" + record.ownr_nm + "】"} onConfirm={() => this.resetPassword(record)}>
            <a style={{marginRight: '10px'}}>重置密码</a>
          </Popconfirm>
          }
          {record.ownr_sts_cd == 'AC' && this.hasFunctionAdd() &&
          <Popconfirm title={"确定要禁用货主【" + record.ownr_nm + "】"} onConfirm={() => this.closeAccount(record)}>
            <a style={{marginRight: '10px'}}>禁用</a>
          </Popconfirm>
          }
          {record.ownr_sts_cd == 'IN' && this.hasFunctionAdd() &&
          <a onClick={() => this.openAccount(record)} style={{marginRight: '10px'}}>启用</a>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="货主名称" allowClear={true}>
              {form.getFieldDecorator('ownr_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="联系人" allowClear={true}>
              {form.getFieldDecorator('ctac_psn_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="电话" allowClear={true}>
              {form.getFieldDecorator('ctac_tel_num')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="是否有库存">
              {form.getFieldDecorator('invty_flg')(
                <Select style={{width: '100px'}} allowClear={true}>
                  <Option key={'Y'}>{'有库存'}</Option>
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            {this.hasFunctionAdd() &&
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增货主</Button>
            }
          </Form>
          <Table
            rowKey={'ownr_id'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

OwnerList = Form.create({})(OwnerList);
export default OwnerList;
