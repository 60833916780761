import React, {Fragment, PureComponent} from 'react';
import {Radio, DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";
import ManagerOvertimeDetail from "./ManagerOvertimeDetail";

const FormItem = Form.Item;
const {Option} = Select;
const {MonthPicker} = DatePicker;

class StockInOutOvertimeList extends PureComponent {
  state = {
    loading: false,
    loadingDownload: false,
    pagination: {},
    listItem: [],
    listWh: [],
    listOwnr: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {trx_mth, ownr_id, wh_cd} = state;
        form.setFieldsValue({trx_mth, ownr_id, wh_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      form.setFieldsValue({
        trx_mth: x1.getSystemDate(),
      });
      this.initSearch();
      this.initWarehouseList();
      this.initOwnerList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  initWarehouseList() {
    const params = {
      apiUrl: "/api/warehouse/list",
      success: (data) => {
        this.setState({
          listWh: data,
        });
      },
    };
    x1.request(params);
  };

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    const params = {
      apiUrl: "/api/stockInOutWorkOver/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleDownload = e => {
    var that = this;
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/stockInOutWorkOver/download",
      ...values,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  columns = [
    {title: '单号', dataIndex: 'trx_num'},
    {title: '类型', dataIndex: 'trx_tp_nm'},
    {title: '仓库', dataIndex: 'wh_nm'},
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '日期',
      render: (text, record) => (
          <div>{x1.date2Text(record.trx_dt)}</div>
      )
    },
    {title: '装车时间', dataIndex: 'trx_tm',
      render: (text, record) => (
          <div>{x1.time2Text(record.trx_tm)}</div>
      )
    },
    {title: '吨位', dataIndex: 'trx_tnng_amt'},
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="月份">
                {form.getFieldDecorator('trx_mth', {
                })(<MonthPicker style={{width: '150px'}}/>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="仓库">
                {form.getFieldDecorator('wh_cd', {})(
                    <Select style={{width: '150px'}} allowClear={true}>
                      {this.state.listWh.map((item) => (
                          <Option key={item.wh_cd}>{item.wh_nm}</Option>
                      ))}
                    </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="货主">
                {form.getFieldDecorator('ownr_id', {})(
                  <Select style={{width: '150px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {this.state.listOwnr.map((item) => (
                      <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </div>
            <div style={{marginBottom:'16px', display:'flex', justifyContent:'flex-end'}}>
              <Button type="primary" onClick={this.handleSearch}>查询</Button>
              <span style={{paddingRight:'16px'}}></span>
              <Button onClick={this.handleDownload} loading={this.state.loadingDownload}>下载</Button>
            </div>
          </Form>
          <Table
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

StockInOutOvertimeList = Form.create({})(StockInOutOvertimeList);
export default StockInOutOvertimeList;
