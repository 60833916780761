import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, Divider, Upload, Icon} from 'antd';
import {message} from "antd/lib/index";
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class InitDataUpload extends PureComponent {
  state = {
    loading: false,
    loadingInvoice: false,
    plfm_cd: '',
    listErr: [],
  };

  componentDidMount() {
  };

  uploadFile(file){
    var that = this;
    file = file.file;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      var fileData = e.target.result;
      var fileSize = file.size;
      var fileName = file.name;
      if(fileSize > 10000000){
        x1.showError("文件过大");
        return;
      }
      const params = {
        apiUrl: "/api/initData/upload",
        file_data: fileData,
        file_nm: fileName,
        success: (data) => {
          if(data.listErr != null && data.listErr.length > 0){
            that.setState({listErr:data.listErr});
            x1.showError("上传出错，请检查文件格式");
          } else {
            x1.showMessage("上传成功。处理【" + data.upld_cnt + "】");
          }
        },
        complete:()=>{
          that.setState({loading:false});
        }
      };
      that.setState({listErr:[]});
      that.setState({loading:true});
      x1.request(params);
    }
  };

  recreateInvoice(){
    var that = this;
    const params = {
      apiUrl: "/api/initData/recreateInvoice",
      success: (data) => {
        x1.showMessage("处理成功");
      },
      complete:()=>{
        that.setState({loadingInvoice:false});
      }
    };
    that.setState({loadingInvoice:true});
    x1.request(params);
  }

  columns = [
    {title: '行号', dataIndex: 'row_num'},
    {title: '货主', dataIndex: 'ownr_nm'},
    {title: '商品', dataIndex: 'item_nm'},
    {title: '入库日期', dataIndex: 'stock_in_dt_txt'},
    {title: '错误提示', dataIndex: 'err_msg'},
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <FormItem {...formItemLayout} label="初始数据">
                <Upload
                  showUploadList={false}
                  customRequest={(fileInfo)=>this.uploadFile(fileInfo)}
                >
                  <div>
                    <Button>
                      <Icon type={this.state.loading?'loading':'upload'} />上传订单文件
                    </Button>
                  </div>
                </Upload>
              </FormItem>
            </div>
            <div>
              <FormItem {...formItemLayout} label="重新生成账单">
                <Popconfirm title={"确认重新生成【全部的账单】吗？"} onConfirm={() => this.recreateInvoice()}>
                  <Button type="primary" loading={this.state.loadingInvoice} >重新生成账单</Button>
                </Popconfirm>
              </FormItem>
            </div>
          </Form>

          {this.state.listErr.length > '' &&
            <Fragment>
              <div style={{color:'red'}}>错误提示</div>
              <Table
                rowKey={'row_num'}
                dataSource={this.state.listErr}
                columns={this.columns}
                size="middle"
              />
            </Fragment>
          }

        </Card>
      </BasicLayout>

    );
  };
}

InitDataUpload = Form.create({})(InitDataUpload);
export default InitDataUpload;
