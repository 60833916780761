import React, {PureComponent} from 'react';
import {Popconfirm, DatePicker, Button, Card, Form, Input, Radio, Select, Spin, TimePicker, Row, Col} from 'antd';
import {x1} from '../x1'
import BasicLayout from "../layouts/BasicLayout";
import moment from 'moment';

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

class OwnerDepositDetail extends PureComponent {
  state = {
    loading: false,
    loadingSubmit: false,
    listOwnr: [],
  };

  componentDidMount() {
    const {form} = this.props;

    var ownr_dep_pk = x1.router.getParamIn().ownr_dep_pk;
    if (ownr_dep_pk > '') {
      // 更新
      form.setFieldsValue({ownr_dep_pk: ownr_dep_pk});
      this.search(ownr_dep_pk);
    } else {
      // 新增
      form.setFieldsValue({dep_rcv_dt:x1.getSystemDate()});
    }
    this.initOwnerList();
  };

  hasFunctionUpdate(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  initOwnerList() {
    const params = {
      apiUrl: "/api/owner/list",
      success: (data) => {
        this.setState({
          listOwnr: data,
        });
      },
    };
    x1.request(params);
  };

  checkSubmit(){
    const {form} = this.props;
    var fieldsValue = form.getFieldsValue();
    return true;
  }

  handleSubmit = e => {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;

      if(!this.checkSubmit()){
        return;
      }

      const params = {
        apiUrl: "/api/ownerDeposit/update",
        ...fieldsValue,
        usr_id: x1.user.getCurrentUserId(),
        success: (data) => {
          x1.showMessage('提交成功');
          // 新增时返回列表
          if(x1.isNull(fieldsValue.ownr_dep_pk)){
            x1.router.back();
          } else {
            form.setFieldsValue(data);
          }
        },
        complete: () => {
          this.setState({loadingSubmit: false});
        }
      };
      this.setState({loadingSubmit: true});
      x1.request(params);
    });
  };

  search(ownr_dep_pk) {
    var that = this;
    const {form} = this.props;
    const params = {
      apiUrl: "/api/ownerDeposit/list",
      ownr_dep_pk: ownr_dep_pk,
      success: (data) => {
        form.setFieldsValue(data[0]);
        this.setState(data[0]);
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  render() {
    const {form} = this.props;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };
    const submitItemLayout = {
      wrapperCol: {offset: 6},
    };

    return (
      <BasicLayout>
        <Card bordered={false}>
          <Spin spinning={this.state.loading}>
            <Form hideRequiredMark={true}>

              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="押金编码">
                    {form.getFieldDecorator('ownr_dep_pk')(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="货主">
                    {form.getFieldDecorator('ownr_id', {
                      rules: [{
                        required: true,
                        message: '请输入货主',
                      }]
                    })(
                      <Select style={{width: '100px'}} allowClear={true} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {this.state.listOwnr.map((item) => (
                          <Option key={item.ownr_id}>{item.ownr_nm}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="交易日期">
                    {form.getFieldDecorator('dep_rcv_dt', {
                      rules: [{
                        required: true,
                        message: '请输入交易日期',
                      }]
                    })(
                      <DatePicker style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="交易金额">
                    {form.getFieldDecorator('dep_rcv_amt', {
                      rules: [{
                        required: true,
                        message: '请输入交易金额',
                      }]
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="备注">
                    {form.getFieldDecorator('memo_txt', {
                    })(
                      <Input style={{width: '150px'}}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="更新人">
                    {form.getFieldDecorator('upd_usr_nm', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="更新时间">
                    {form.getFieldDecorator('upd_ts', {
                    })(
                      <Input style={{width: '150px'}} disabled={true}/>
                    )}
                  </FormItem>

                  <FormItem {...submitItemLayout}>
                    {(x1.isNull(this.state.ownr_dep_pk) || (!x1.isNull(this.state.ownr_dep_pk) && this.hasFunctionUpdate())) &&
                    <Popconfirm title={"确定提交押金吗?"} onConfirm={(e) => this.handleSubmit(e)}>
                      <Button type="primary" loading={this.state.loadingSubmit}>提交</Button>
                      </Popconfirm>
                    }
                    {/*<Button type="primary" onClick={this.handleSubmit} loading={this.state.loadingSubmit}>提交</Button>*/}
                  </FormItem>
                </Col>
              </Row>

            </Form>
          </Spin>
        </Card>
      </BasicLayout>
    );
  };
}

OwnerDepositDetail = Form.create({})(OwnerDepositDetail);
export default OwnerDepositDetail;
