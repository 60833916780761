import React, {Fragment, PureComponent} from 'react';
import {Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class ProductList extends PureComponent {
  state = {
    loading: false,
    pagination: {},
    listItem: [],
    listItemGrp: [],
  };

  componentDidMount() {
    const {form} = this.props;
    var isBack = false;
    if(x1.router.isPageBack()){
      var state = x1.router.getPageState();
      if(state != null){
        isBack = true;
        this.state = state;
        this.setState(state);
        var {prod_nm, item_grp_cd} = state;
        form.setFieldsValue({prod_nm, item_grp_cd});
        this.refreshPage();
      }
    }

    if(!isBack) {
      this.initSearch();
      this.initItemGroupList();
    }
  };

  refreshPage(){
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(this.state.pagination.current, this.state.pagination.pageSize);
  }

  hasFunctionDelete(){
    if(x1.user.hasRole(x1.ROLE.ADMIN)){
      return true;
    }
    return false;
  }

  initItemGroupList() {
    const params = {
      apiUrl: "/api/itemGroup/list",
      success: (data) => {
        this.setState({
          listItemGrp: data,
        });
      },
    };
    x1.request(params);
  };

  handleSearch = e => {
    e.preventDefault();
    this.initSearch();
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();

    const params = {
      apiUrl: "/api/product/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      get_prc_list_flg: 'Y',
      success: (data) => {
        this.setState({
          listItem: data,
          pagination: {current: curPage, pageSize: pageSize},
        });
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  handleAdd = e => {
    x1.router.push("/ProductDetail", {}, x1.getStateSnapshort(this));
  };

  openDetail(record) {
    x1.router.push('/ProductDetail', record, x1.getStateSnapshort(this));
  };

  deleteLine(record) {
    const params = {
      apiUrl: "/api/product/delete",
      prod_cd: record.prod_cd,
      success: (data) => {
        this.initSearch();
        x1.showMessage('提交成功');
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  disableLine(record) {
  };

  openProductPrice(record) {
    x1.router.push('/ProductPriceList', record, x1.getStateSnapshort(this));
  };

  columns = [
    {title: '品类编号', dataIndex: 'prod_cd'},
    {title: '品类名称', dataIndex: 'prod_nm'},
    {title: '分类', dataIndex: 'item_grp_nm'},
    // {title: '预冷费', dataIndex: 'pre_cool_unit_prc_amt'},
    // {title: '冷藏费', dataIndex: 'store_unit_prc_amt'},
    // {title: '装卸费', dataIndex: 'load_unit_prc_amt'},
    {title: '品类状态', dataIndex: 'prod_sts_nm'},
    {title: '最新单价',
      render: (text, record) => (
        <div>
          {record.unitPrc.prod_prc_pk > '' &&
          <div>
            <div>{x1.date2Text(record.unitPrc.from_dt) + ' ~ ' + x1.date2Text(record.unitPrc.to_dt)}</div>
            <div>{'预冷费：' + record.unitPrc.pre_cool_unit_prc_amt}</div>
            <div>{'冷藏费：' + record.unitPrc.store_unit_prc_amt}</div>
            <div>{'装卸费：' + record.unitPrc.load_unit_prc_amt}</div>
            <div>{'工本费：' + record.unitPrc.card_unit_prc_amt}</div>
            <div>
              <div>{'时间带搬运费：'}</div>
              {record.unitPrc.listPrc.map((prc)=>
                <div>{x1.time2Text(prc.from_tm) + " - " + x1.time2Text(prc.to_tm) + '：' + prc.load_unit_prc_amt + '元'}</div>
              )}
            </div>
          </div>
          }
        </div>
      ),
    },
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <a onClick={() => this.openDetail(record)} style={{marginRight: '10px'}}>详情</a>
          <Divider type={'vertical'} />
          <a onClick={() => this.openProductPrice(record)} style={{marginRight: '10px'}}>期间价格</a>
          {this.hasFunctionDelete() &&
          <Popconfirm title={"确定要删除品类【" + record.prod_nm + "】"} onConfirm={() => this.deleteLine(record)}>
            <Divider type={'vertical'} />
            <a style={{marginRight: '10px'}}>删除</a>
          </Popconfirm>
          }
        </Fragment>
      ),
    },
  ];

  render() {
    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
            <FormItem {...formItemLayout} label="品类名称" allowClear={true}>
              {form.getFieldDecorator('prod_nm')(
                <Input style={{width: '150px'}}/>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="商品分类">
              {form.getFieldDecorator('item_grp_cd')(
                <Select style={{width: '200px'}} allowClear={true}>
                  {this.state.listItemGrp.map((item) => (
                    <Option key={item.item_grp_cd}>{item.item_grp_nm}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="是否有价格">
              {form.getFieldDecorator('has_prc_flg')(
                <Select style={{width: '100px'}} allowClear={true}>
                  <Option key={'Y'}>{'有价格'}</Option>
                  <Option key={'N'}>{'无价格'}</Option>
                </Select>
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearch}>查询</Button>
            <Button type="primary" icon="plus" onClick={this.handleAdd} style={{marginLeft:'16px'}}>新增品类</Button>
          </Form>
          <Table
            rowKey={'prod_cd'}
            loading={this.state.loading}
            dataSource={this.state.listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

ProductList = Form.create({})(ProductList);
export default ProductList;
