import React, {Fragment, PureComponent} from 'react';
import {DatePicker, Button, Card, Form, Input, Popconfirm, Select, Table, Divider} from 'antd';
import {x1} from '../x1';
import BasicLayout from "../layouts/BasicLayout";

const FormItem = Form.Item;
const {Option} = Select;

class InvoiceDetail extends PureComponent {
  state = {
    loading: false,
    loadingPrint: false,
    loadingDownload: false,
    pagination: {},
  };

  componentDidMount() {
    var param = x1.router.getParamIn();
    this.state.inv_num = param.inv_num;
    this.initSearch();
  };

  downloadInvoice = e => {
    var that = this;
    const params = {
      apiUrl: "/api/invoice/download",
      inv_num: this.state.inv_num,
      success: (data)=>{
        x1.download(data.file_url);
      },
      complete: ()=>{
        that.setState({loadingDownload: false});
      }
    };
    that.setState({loadingDownload: true});
    x1.request(params);
  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {
    this.searchList(pagination.current, pagination.pageSize);
  };

  initSearch() {
    const {form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
    });
    this.searchList(0, 10);
  };

  searchList(curPage, pageSize) {
    const {form} = this.props;
    var values = form.getFieldsValue();
    var head = this.state.head;

    const params = {
      apiUrl: "/api/invoice/list",
      curPage: curPage,
      pageSize: pageSize,
      ...values,
      inv_num: this.state.inv_num,
      get_inv_line_flg: 'Y',
      success: (data) => {
        if(data.length > 0){
          this.setState({...data[0],
            pagination: {current: curPage, pageSize: pageSize},
          });
        }
      },
      complete: () => {
        this.setState({loading: false});
      }
    };
    this.setState({loading: true});
    x1.request(params);
  };

  openDetail(record) {
    x1.router.push('/InvoiceDetail', record);
  };

  columns = [
    {title: '出库日期', dataIndex: 'stock_out_dt_txt'},
    {title: '出库数量', dataIndex: 'stock_out_qty'},
    {title: '结存数量', dataIndex: 'rmn_qty'},
    {title: '出库吨数', dataIndex: 'stock_out_tnng_amt'},
    {title: '结存吨数', dataIndex: 'rmn_tnng_amt'},
    {title: '计费天数', dataIndex: 'inv_day_cnt'},
    {title: '冷藏金额', dataIndex: 'line_store_amt'},
    {title: '装卸费', dataIndex: 'line_load_amt'},
    {
      title: '操作',
      render: (text, record) => (
        <Fragment>
          <div>
          </div>
        </Fragment>
      ),
    },
  ];

  render() {

    var styleItem = {
      width:'200px',
      height: '40px',
      display: 'flex',
    };
    var styleItemTitle = {
      width:'100px',
    };
    var styleItemValue = {
      flex: 1,
    };

    var head = this.state;
    var invHead = this.state;
    var listItem = this.state.listLine;

    const {form} = this.props;
    const formItemLayout = {
    };
    return (
      <BasicLayout>
        <Card bordered={false}>
          <Form layout={'inline'} style={{display: 'flex', alignItems: 'flex-start', marginBottom: '16px'}}>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'单号：'}</div>
                <div style={styleItemValue}>{head.inv_num}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'仓库：'}</div>
                <div style={styleItemValue}>{head.wh_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'货主：'}</div>
                <div style={styleItemValue}>{head.ownr_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'商品：'}</div>
                <div style={styleItemValue}>{head.item_nm}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库日期：'}</div>
                <div style={styleItemValue}>{x1.date2Text(head.stock_in_dt)}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'单位重量(kg)：'}</div>
                <div style={styleItemValue}>{head.unit_kg_amt}</div>
              </div>
            </div>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库数量：'}</div>
                <div style={styleItemValue}>{head.stock_in_qty}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'入库吨位：'}</div>
                <div style={styleItemValue}>{invHead.tot_in_tnng_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'出库数量：'}</div>
                <div style={styleItemValue}>{invHead.tot_out_qty}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'出库吨位：'}</div>
                <div style={styleItemValue}>{invHead.tot_out_tnng_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'结存数量：'}</div>
                <div style={styleItemValue}>{x1.nvl(invHead.tot_rmn_qty,0)}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'结存吨位：'}</div>
                <div style={styleItemValue}>{x1.nvl(invHead.tot_rmn_tnng_amt,0)}</div>
              </div>
            </div>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'预冷费：'}</div>
                <div style={styleItemValue}>{invHead.pre_cool_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'冷藏费：'}</div>
                <div style={styleItemValue}>{invHead.store_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'装卸费：'}</div>
                <div style={styleItemValue}>{invHead.load_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'转场费：'}</div>
                <div style={styleItemValue}>{invHead.ship_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'工本费：'}</div>
                <div style={styleItemValue}>{invHead.card_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'合计：'}</div>
                <div style={styleItemValue}>{invHead.tot_inv_amt}</div>
              </div>
            </div>
            <div style={{width:'250px'}}>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'预冷费单价：'}</div>
                <div style={styleItemValue}>{invHead.pre_cool_unit_prc_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'冷藏费单价：'}</div>
                <div style={styleItemValue}>{invHead.store_unit_prc_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'装卸费单价：'}</div>
                <div style={styleItemValue}>{invHead.load_unit_prc_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'转场费单价：'}</div>
                <div style={styleItemValue}>{invHead.ship_unit_prc_amt}</div>
              </div>
              <div style={styleItem}>
                <div style={styleItemTitle}>{'工本费单价：'}</div>
                <div style={styleItemValue}>{invHead.card_unit_prc_amt}</div>
              </div>
            </div>
            <Button type="primary" onClick={this.downloadInvoice} loading={this.state.loadingDownload}>下载账单</Button>
          </Form>
          <Table
            rowKey={'inv_line_num'}
            loading={this.state.loading}
            dataSource={listItem}
            pagination={this.state.pagination}
            columns={this.columns}
            onChange={this.handleStandardTableChange}
            size="middle"
          />
        </Card>
      </BasicLayout>

    );
  };
}

InvoiceDetail = Form.create({})(InvoiceDetail);
export default InvoiceDetail;
